import PropTypes from "prop-types"

import SesIdentityShape from "./shapes/SesIdentityShape"
import { DomainStatusEnumKeys } from "./shapes/DomainStatusEnum"

const properties = {
  certificateRecordJson: PropTypes.string,
  controllerId: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  disableReason: PropTypes.string,
  disabledAt: PropTypes.string,
  docusignAccountId: PropTypes.string,
  docusignWebhookSecret: PropTypes.string,
  domain: PropTypes.string.isRequired,
  domainRecordJson: PropTypes.string,
  domainStatus: PropTypes.oneOf(DomainStatusEnumKeys).isRequired,
  email: PropTypes.string,
  emailFooterHtml: PropTypes.string,
  emailFooterSource: PropTypes.string,
  emailIdentity: SesIdentityShape,
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isEmailVerified: PropTypes.bool,
  legalName: PropTypes.string,
  logoUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  parentOrganizationId: PropTypes.string,
  phoneNumber: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
  userGroupIds: PropTypes.arrayOf(PropTypes.string)
}

const EnableOrganizationOutputShapeShape = PropTypes.exact(properties)

export default EnableOrganizationOutputShapeShape
export { properties }
