import React from "react"
import PropTypes from "prop-types"
import { Button, Result, Space, Typography } from "antd"
import { useNavigate } from "react-router-dom"

import { Markdown } from "@components/Text"
import { usePublicOrganizationContext } from "@components/AppContext"

import { signOut, getIdentityEmail } from "./authenticate"

const { Text } = Typography

const LABEL_TITLE = "Authorization Failed"
const LABEL_BACKSTAGE = `The email address **[IDENTITY_EMAIL]** was not
recognized as an admin member of the [ORGANIZATION_NAME].

If you are trying to log in to the investor portal, please [CLICK_HERE]. If you
need assistance with your account setup, please reach out to us via email at
[ORGANIZATION_EMAIL].`
const LABEL_CLICK_HERE = "click here"
const LABEL_INVESTOR_PORTAL = `The email address **[IDENTITY_EMAIL]** was not
recognized.

If you need assistance with your account setup, please reach out to us via
email at [ORGANIZATION_EMAIL].
`

const LABEL_SIGNOUT = "Sign Out"
const LABEL_LOADING = "Loading..."
const LABEL_NOT_READY = "Organization configuration is not ready yet."


const AuthorizationError = ({ isBackstage }) => {
  const navigate = useNavigate()
  const { publicOrganization } = usePublicOrganizationContext()

  const rootPath = isBackstage
    ? "/backstage"
    : "/"

  const onClick = async () => {
    await signOut()
    navigate(rootPath)
  }

  const {
    name: organizationName,
    email: organizationEmail,
    isReady: isOrganizationReady,
  } = publicOrganization

  const isLoaded = !!organizationName

  const style = {
    margin: "0 auto",
    maxWidth: 520,
  }

  let formattedNotice

  if (isOrganizationReady) {
    const identityEmail = getIdentityEmail(isBackstage)

    const mailTo = `[${organizationEmail}](mailto:${organizationEmail})`

    const notice = isBackstage
      ? LABEL_BACKSTAGE
      : LABEL_INVESTOR_PORTAL

    formattedNotice = notice
      .replace("[ORGANIZATION_EMAIL]", mailTo)
      .replace("[ORGANIZATION_NAME]", organizationName)
      .replace("[CLICK_HERE]", `[${LABEL_CLICK_HERE}](/)`)
      .replace("[IDENTITY_EMAIL]", identityEmail)

  } else {
    formattedNotice = LABEL_NOT_READY

  }

  if (!isLoaded) {
    formattedNotice = LABEL_LOADING
  }

  return (
    <Result
      icon={false}
      title={LABEL_TITLE}
      style={style}
      status="error"
      subTitle={[
        <Space
          key="extra"
          direction="vertical"
        >
          <Text type="secondary">
            <Markdown text={formattedNotice} />
          </Text>

          <Button onClick={onClick}>
            {LABEL_SIGNOUT}
          </Button>
        </Space>
      ]}
    />
  )
}

AuthorizationError.propTypes = {
  isBackstage: PropTypes.bool.isRequired,
}

export default AuthorizationError
