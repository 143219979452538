import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"
import { Col, Row, Space, Statistic } from "antd"

import {
  getTransactionTypeLabel,
  TRANSACTIONS_INVESTOR_INCOMING_TYPES,
} from "@components/Domain"
import { Value } from "@components/Text"
import getStatistics from "@components/Investor/helpers/getStatistics"
import { W1, W2, isMobile } from "@components"
import { getFormattedDecimalValue } from "@components/Amount"

export const INCOMING_TOTAL_KEY = "in"
export const OUTGOING_TOTAL_KEY = "out"
export const DASHBOARD_TOTALS = [
  ...TRANSACTIONS_INVESTOR_INCOMING_TYPES,
]

const LABEL_INCOMING = "Total Incoming"
const LABEL_OUTGOING = "Total Outgoing"
const LABEL_INCOMING_TOTAL = "Total Amount of Incoming Transactions"
const LABEL_OUTGOING_TOTAL = "Total Amount of Outgoing Transactions"


function TransactionsStatistics({ style = {} }) {
  const { transactions } = useOutletContext()

  const totalsMap = useMemo(() =>
    getStatistics(transactions)
  , [ transactions ])

  const incomingTotal = totalsMap[INCOMING_TOTAL_KEY] || 0
  const outgoingTotal = totalsMap[OUTGOING_TOTAL_KEY] || 0

  const getTotalForType = type => totalsMap[type] || 0

  if (isMobile) {
    const rowStyle = {
      borderBottom: "1px dashed #dedede",
    }

    return (
      <Space
        size={4}
        style={{ width: "100%" }}
        direction="vertical"
      >
        {
          DASHBOARD_TOTALS.map(type =>
            <Row key={type} style={rowStyle}>
              <Col flex="auto">{getTransactionTypeLabel(type)}</Col>
              <Col>
                <Value value={getFormattedDecimalValue(getTotalForType(type), 100, "times")} />
              </Col>
            </Row>
          )
        }
        <Row style={rowStyle}>
          <Col flex="auto">{LABEL_INCOMING}</Col>
          <Col>
            <Value value={getFormattedDecimalValue(incomingTotal, 100, "times")} />
          </Col>
        </Row>
        <Row>
          <Col flex="auto">{LABEL_OUTGOING}</Col>
          <Col>
            <Value value={getFormattedDecimalValue(outgoingTotal, 100, "times")} />
          </Col>
        </Row>
      </Space>
    )
  }

  return (
    <div style={style}>
      <Row gutter={W2}>
        <Col span={12}>
          <Statistic
            title={LABEL_INCOMING_TOTAL}
            value={incomingTotal}
            prefix="$"
            valueStyle={{ fontSize: 38 }}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title={LABEL_OUTGOING_TOTAL}
            value={outgoingTotal}
            prefix="$"
            valueStyle={{ fontSize: 38 }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: W1 }} gutter={W2}>
        {
          DASHBOARD_TOTALS.map(type =>
            <Col key={type}>
              <Statistic
                title={getTransactionTypeLabel(type)}
                value={getTotalForType(type)}
                prefix="$"
              />
            </Col>
          )
        }
      </Row>
    </div>
  )
}

TransactionsStatistics.propTypes = {
  style: PropTypes.shape(),
}

export default TransactionsStatistics
