
export const getMyInvestorName = (context, createdBy) => {
  const { accountsMap } = context
  const account = accountsMap[createdBy]

  if (!account) {
    return "Undefined"
  }

  if (!account.investor) {
    return "Undefined"
  }

  const { givenName, familyName } = account.investor
  const fullName = `${givenName} ${familyName}`

  return fullName
}
