import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"
import { WarningOutlined } from "@ant-design/icons"
import { Typography, Space, Avatar, Tooltip, theme } from "antd"

import { ROLES, ROLES_MAP } from "@components/Domain"

import getColors from "./helpers/getColors"

const { Text, Link: TextLink } = Typography

const SYSTEM = "SYSTEM"


const Identity = ({
  size = "small",
  strong = false,
  onClick = null,
  children = undefined,
  hasAvatar = true,
  id,
}) => {
  const { token: { colorBgLayout } } = theme.useToken()
  const { membersMap, brokersMap, accountsMap = {} } = useOutletContext()

  const isSystem = id === SYSTEM

  if (isSystem) {
    return "System"
  }

  const identity = accountsMap[id] || brokersMap[id] || membersMap[id]

  if (!identity) {
    return ""
  }

  const isInvestor = id.startsWith('ACC_')

  const { givenName, additionalName, familyName } = isInvestor
    ? identity.investor
    : identity

  const fullName = [
    givenName,
    additionalName,
    familyName
  ].join(' ')

  const { status, isDisabled, disableReason } = identity

  const roles = isInvestor
    ? [ ROLES.INVESTOR ]
    : identity.roles || []

  const [ mainRoleId ] = roles
  const role = ROLES_MAP[mainRoleId]

  if (!role) {
    return children || fullName
  }

  const getAvatarStyles = () =>
    getColors(mainRoleId, role, status, colorBgLayout)

  const isLink = !!onClick

  const getInitials = () =>
    fullName
      .split(" ")
      .map(word => word[0])
      .join("")

  return (
    <Space>
      {
        hasAvatar && (
          <Avatar
            size={size}
            style={getAvatarStyles()}
          >
            {getInitials()}
          </Avatar>
        )
      }

      {
        isLink && (
          <TextLink strong={strong} onClick={onClick} style={{ whiteSpace: "nowrap" }}>
            {children || fullName}
          </TextLink>
        )
      }

      {
        !isLink && (
          <Text strong={strong} style={{ whiteSpace: "nowrap" }}>
            {children || fullName}
          </Text>
        )
      }

      {
        isDisabled && (
          <Tooltip
            placement="top"
            title={
              disableReason
                ? ['Account Disabled', disableReason].join(': ')
                : 'Account Disabled'
            }
          >
            <WarningOutlined />
          </Tooltip>
        )
      }
    </Space>
  )
}

Identity.propTypes = {
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
  strong: PropTypes.bool,
  onClick: PropTypes.func,
  hasAvatar: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
}

export default Identity
