import { useEffect, useState } from "react"

import { operationRequest } from "@components/Authorization"

import setTitle from "./setTitle"

const operation = "organizations/ReadPublicOrganization"


const usePublicOrganization = () => {
  const [ organization, setOrganization ] = useState({})

  useEffect(() => {
    operationRequest({ operation })
      .then(({ data }) => setOrganization(data))
  }, [])

  useEffect(() => {
    const { name } = organization

    if (!name) {
      return
    }

    setTitle(name)
  }, [ organization ])

  return organization
}

export default usePublicOrganization
