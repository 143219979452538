import React from "react"
import PropTypes from "prop-types"

import Form from "@components/Form"
import { useAppContext } from "@components/AppContext"
import { updateOperation as operation } from "@components/Store/BackstageStore/useCampaignsStore"

import useInputs from "./helpers/useInputs"


const CampaignSettingsUpdateForm = ({
  item,
  form,
  tabId,
  onSuccess,
  uploadAssets,
  saveCampaign
}) => {
  const { request } = useAppContext()

  const inputs = useInputs(form, tabId, item, saveCampaign, uploadAssets)
  const schema = operation.getSchema(inputs)

  const formProps = {
    hasSubmitButton: false,
    form,
    item,
    schema,
    request,
    onSuccess,
    operation,
  }

  return (
    <Form {...formProps} />
  )
}

CampaignSettingsUpdateForm.propTypes = {
  item: PropTypes.shape().isRequired,
  form: PropTypes.shape().isRequired,
  tabId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  uploadAssets: PropTypes.func.isRequired,
  saveCampaign: PropTypes.func.isRequired,
}

export default CampaignSettingsUpdateForm
