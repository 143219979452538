import { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"

import { isBackstage } from "@components"
import { setApplicationTitle, setSentryUser, runRequest } from "@components/AppContext"
import { ACCESS_DENIED_ERROR, UNAUTHORIZED_ERROR, DOCUMENT_NOT_FOUND_ERROR } from "@api"

import { Storage } from "../authenticate"
import IdentityShape from "../shapes/IdentityShape"
import extendIdentityOrganization from "../helpers/extendIdentityOrganization"
import useIdentityStore, { identityStoreProperties } from "./useIdentityStore"
import useAuthenticationStore, { authenticationStoreProperties } from "./useAuthenticationStore"
import {
  authorize,
  authorizeUser,
  authorizeInvestor,
  getAuthorizedRequest,
} from "../authorize"

const LABEL_ACCESS_DENIED = "Access denied"

const BACKSTAGE_PATH = "/backstage"

const authorizationMethod = isBackstage
  ? authorizeUser
  : authorizeInvestor

const useAuthorizationStore = ({
  showErrorMessage,
  showLoadingMessage,
  messageContextHolder,
}) => {
  const navigate = useNavigate()

  const [ identity, setIdentity ] = useState()
  const [ authorizationError, setAuthorizationError ] = useState()

  const updateIdentity = useCallback(newIdentity =>
    setIdentity(prevIdentity =>
      extendIdentityOrganization({
        ...prevIdentity,
        ...newIdentity
      })
    )
  , [])

  const identityStore = useIdentityStore(identity, updateIdentity)

  const authenticationStore = useAuthenticationStore({
    isBackstage,
    showErrorMessage,
    showLoadingMessage,
    messageContextHolder,
  })

  const {
    isGuest,
    isAuthenticated,
    resetAuthentication,
    refreshAuthentication,
  } = authenticationStore

  const onAuthorizationError = useCallback(error => {
    const isAccessDenied =
      error.code === ACCESS_DENIED_ERROR ||
      error.code === DOCUMENT_NOT_FOUND_ERROR

    if (isAccessDenied) {
      return setAuthorizationError(LABEL_ACCESS_DENIED)
    }

    const isAuthenticationExpired = error.code === UNAUTHORIZED_ERROR

    if (isAuthenticationExpired) {
      // NOTE: Should signOut if impersonated session here.

      return refreshAuthentication()
        .then(newAuthentication => {
          if (!newAuthentication) {
            return
          }

          const organizationId = Storage.customOrganizationId

          return authorize({
            setIdentity: updateIdentity,
            authentication: newAuthentication,
            organizationId,
            authorizationMethod,
            onAuthorizationError,
          })
        })
    }

    const errorMessage = error.originalError?.message || error.message

    setAuthorizationError(errorMessage)
  }, [
    updateIdentity,
    refreshAuthentication,
  ])

  const authorizeOrganizationIdentity = useCallback(() => {
    const organizationId = Storage.customOrganizationId

    const authentication = isBackstage
      ? Storage.backstageAuthentication
      : Storage.investorAuthentication

    return authorize({
      setIdentity: updateIdentity,
      authentication,
      organizationId,
      authorizationMethod,
      onAuthorizationError,
    })
  }, [
    updateIdentity,
    onAuthorizationError
  ])

  const resetAuthorization = async () => {
    await resetAuthentication()
    await authorizeOrganizationIdentity()
  }

  const request = useCallback((...args) => {
    const onUnexpectedError = error =>
      showErrorMessage(error.message, error.originalError)

    const authentication = isBackstage
      ? Storage.backstageAuthentication
      : Storage.investorAuthentication

    const requestMethod = getAuthorizedRequest(
      updateIdentity,
      onUnexpectedError,
      onAuthorizationError,
      authorizationMethod,
      authentication
    )

    return requestMethod(...args)
  }, [
    updateIdentity,
    showErrorMessage,
    onAuthorizationError
  ])

  const switchToOrganization = async (targetOrganizationId, redirectPath = BACKSTAGE_PATH) => {
    Storage.setCustomOrganizationId(targetOrganizationId)
    await authorizeOrganizationIdentity()

    navigate(redirectPath)
  }

  const isLoading = !isAuthenticated
  const isAuthorized = !!identity

  const isAppReady = isGuest || isAuthorized

  useEffect(() => {
    if (isGuest) {
      return
    }

    if (isAuthorized) {
      return
    }

    if (isAuthenticated) {
      return
    }

    return runRequest(() => authorizeOrganizationIdentity())
  }, [
    isGuest,
    isAuthorized,
    isAuthenticated,
    authorizeOrganizationIdentity
  ])

  useEffect(() => {
    setSentryUser(identity)
    setApplicationTitle(identity)
  }, [ identity ])

  return {
    request,
    identity,
    isLoading,
    isAppReady,
    isAuthorized,
    updateIdentity,
    isAuthenticated,
    authorizationError,
    resetAuthorization,
    switchToOrganization,
    authorizeOrganizationIdentity,
    ...authenticationStore,
    ...identityStore
  }
}

const authorizationStoreProperties = {
  request: PropTypes.func.isRequired,
  identity: IdentityShape,
  isLoading: PropTypes.bool.isRequired,
  isAppReady: PropTypes.bool.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  updateIdentity: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  authorizationError: PropTypes.string,
  resetAuthorization: PropTypes.func.isRequired,
  switchToOrganization: PropTypes.func.isRequired,
  authorizeOrganizationIdentity: PropTypes.func.isRequired,
  ...authenticationStoreProperties,
  ...identityStoreProperties
}

export default useAuthorizationStore

export {
  authorizationStoreProperties
}
