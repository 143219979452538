import React from "react"
import { Alert } from "antd"

import { Disclaimer } from "@components/Text"
import LinksListInput from "@components/Form/Input/LinksListInput"
import TiersListInput from "@components/Project/TiersListInput"
import { ProjectDealTags } from "@components/Project/ProjectTags"
import { PROJECT_DATES_MAP } from "@components/Domain"
import { listTemplatesOperation } from "@api/services/documents"
import { W0, W1, VIMEO_REGEX, YOUTUBE_REGEX } from "@components"
import {
  TYPE_NONE,
  TYPE_TEXT,
  TYPE_HIDDEN,
  TYPE_UPLOAD,
  TYPE_AMOUNT,
  TYPE_NUMBER,
  TYPE_CHECKBOX,
  TYPE_RADIO,
  PublicDocumentsInput
} from "@components/Form"

import getBankAccountOptions from "./getBankAccountOptions"
import getProjectTagLabelOptions from "./getProjectTagLabelOptions"

const LABEL_WARNING = "Warning"
const LABEL_VIDEO_LINK = "Add video link"
const LABEL_VIDEO_MESSAGE = "YouTube and Vimeo links are supported"
const LABEL_URL_PLACEHOLDER = "Vimeo or YouTube video URL"

const LABEL_VERIFY_BANK_ACCOUNT = `Complete bank account verification in
"Settings / Bank Accounts" to enable funding via ACH.`

const VIDEO_LINK_REGEX = new RegExp(`(?:${VIMEO_REGEX.source})|(?:${YOUTUBE_REGEX.source})`)

const shouldResetSignedInvestmentsOptions = [
  {
    value: true,
    label: "Reset PPM status for all investments",
    description: "This will reset the PPM signing status for all existing investments within this project. Previously distributed PPM documents will be removed."
  },
  {
    value: false,
    label: "Apply updated PPM to ongoing subscriptions only",
    description: "Existing PPM signing statuses will remain unchanged. All ongoing subscriptions will use the updated PPM template."
  }
]


const getUpdateInputs = ({
  bankAccounts = [],
  onlyFieldNames = [],
  templateAlertMessage = false,
  hasTemplateChangedAlert = false,
  hasResetSignedInvestmentsInput = false,
  projects,
  updateSchema,
  hasFundingBankAccount,
  isAchFundingInputDisabled,
}) => {
  const bankAccountOptions = getBankAccountOptions(bankAccounts)
  const distributionBankAccountOptions = getBankAccountOptions(bankAccounts, true)

  const ppmTemplateIdExtra = !hasTemplateChangedAlert
    ? {}
    : {
      extra: (
        <Alert
          showIcon
          type="warning"
          message={LABEL_WARNING}
          description={templateAlertMessage}
          style={{ marginTop: W1 }}
        />
      )
    }

  const shouldResetSignedInvestmentsInput = hasResetSignedInvestmentsInput
    ? {
        name: "shouldResetSignedInvestments",
        type: TYPE_RADIO,
        label: "",
        options: shouldResetSignedInvestmentsOptions,
        initialValue: false,
      }
    : {
        name: "shouldResetSignedInvestments",
        type: TYPE_NONE,
      }

  const commonInputs = [
    {
      name: "isReceiverSourceVerified",
      type: TYPE_NONE,
    },
    {
      name: "senderSourceId",
      type: TYPE_NONE
    },
    {
      name: "tags",
      type: TYPE_HIDDEN
    },
    {
      name: "distributionTemplates",
      type: TYPE_NONE
    },
    {
      name: "name",
      label: "Project Full Name",
      placeholder: "",
    },
    {
      name: "shortName",
      label: "Short Name",
      inputProps: {
        style: { width: 320 },
        count: {
          show: true,
          max: 32,
        }
      }
    },
    {
      name: "description",
      label: "Description",
      type: TYPE_TEXT,
      placeholder: "",
      inputProps: {
        rows: 8
      }
    },
    {
      name: "coverImageUrl",
      type: TYPE_UPLOAD,
      label: "Cover Image",
      inputProps: {
        isPublic: true,
        actionTitle: "Upload Image",
        fileTypes: [".png", ".jpeg", ".jpg"]
      }
    },
    {
      name: "amount",
      type: TYPE_AMOUNT,
      label: "Raised Amount",
      placeholder: "10,000,000",
      inputProps: { style: { width: "33%" }, controls: false }
    },
    {
      name: "ppmTemplateId",
      label: "DocuSign Template",
      operation: listTemplatesOperation,
      placeholder: "Select a template",
      itemProps: {
        ...ppmTemplateIdExtra
      },
      inputProps: {
        style: { width: "75%" },
        onChange: () => {
          updateSchema(onlyFieldNames)
        },
        hasNoneOption: true,
      }
    },
    shouldResetSignedInvestmentsInput,
    {
      name: "bankAccountId",
      label: "Funding Account",
      options: bankAccountOptions,
      placeholder: "Select a bank account",
      initialValue: "",
      itemProps: {
        style: { width: "75%", marginBottom: W0 },
      },
      inputProps: {
        allowClear: false,
        onChange: (id, form) => {
          form.setFieldValue('isAchFundingEnabled', false)
          updateSchema(onlyFieldNames)
        }
      }
    },
    {
      name: "isAchFundingEnabled",
      type: TYPE_CHECKBOX,
      label: "",
      inputProps: {
        label: "Enable funding via ACH",
        disabled: isAchFundingInputDisabled
      },
      itemProps: {
        extra: hasFundingBankAccount && isAchFundingInputDisabled
          ? (
              <Disclaimer
                text={LABEL_VERIFY_BANK_ACCOUNT}
                style={{ marginTop: W0 }}
              />
            )
          : null
      },
      required: false,
    },
    {
      name: "targetAmount",
      type: TYPE_AMOUNT,
      required: true,
      label: "Funding Amount",
      inputProps: { style: { width: 146 } },
    },
    {
      name: "nonAccreditedInvestorsMax",
      type: TYPE_NUMBER,
      required: true,
      label: "Non-Accredited Investors",
      inputProps: { style: { width: 146 } }
    },
    {
      name: "openDate",
      label: "Go Live Date",
      required: true,
    },
    {
      name: "closeDate",
      label: "Funding Date",
    },
    {
      name: "documents",
      component: PublicDocumentsInput,
    },
    {
      name: "videos",
      component: LinksListInput,
      componentProps: {
        title: "Videos",
        addTitle: LABEL_VIDEO_LINK,
        urlPlaceholder: LABEL_URL_PLACEHOLDER,
        linkValidationRules: [{
          pattern: VIDEO_LINK_REGEX,
          message: LABEL_VIDEO_MESSAGE
        }]
      }
    },
    {
      name: ProjectDealTags.fieldName,
      component: ProjectDealTags,
      componentProps: {
        options: getProjectTagLabelOptions(projects)
      },
    },
    {
      name: "signDeadlineDate",
      label: PROJECT_DATES_MAP.signDeadlineDate,
    },
    {
      name: "fundDeadlineDate",
      label: PROJECT_DATES_MAP.fundDeadlineDate,
    },
    {
      name: "targetCloseDate",
      label: PROJECT_DATES_MAP.targetCloseDate,
    },
    {
      name: "fundOfferingStructure",
      label: "Offering Structure"
    },
    {
      name: "tiers",
      component: TiersListInput,
      componentProps: {
        title: "Investment Classes",
        addTitle: "Add Class"
      }
    },
    {
      name: "distributionBankAccountId",
      label: "Distribution Account",
      options: distributionBankAccountOptions,
      placeholder: "Select a bank account",
      initialValue: "",
      inputProps: {
        allowClear: false
      }
    }
  ]

  const inputs = commonInputs.map(input => {
    const { name, type } = input

    const isNone = !onlyFieldNames.includes(name)

    if (isNone) {
      return {
        name,
        type: type === TYPE_HIDDEN
          ? TYPE_HIDDEN
          : TYPE_NONE
      }
    }

    return input
  })

  return inputs
}

export default getUpdateInputs
