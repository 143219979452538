import { useEffect, useMemo } from "react"
import PropTypes from "prop-types"

import { useStore } from "@components/Store"
import { useAppContext } from "@components/AppContext"
import { INVESTMENT_STATUS } from "@components/Domain"
import {
  readInvestmentOperation as readOperation,
  deleteInvestmentOperation as deleteOperation,
  backupInvestmentOperation as backupOperation,
  acceptInvestmentOperation as acceptOperation,
  rejectInvestmentOperation as rejectOperation,
  indexProjectInvestmentsOperation as indexOperation,
  IndexProjectInvestmentsOutputShape
} from "@api/services/investments"


const useProjectInvestmentsStore = projectId => {
  const { request } = useAppContext()

  const shouldAutoLoad = false
  const operationParameters = useMemo(() => ({ projectId }), [ projectId ])

  const {
    items: investments,
    addItem: addInvestment,
    getItem: getInvestment,
    removeItem: removeInvestment,
    indexItems: indexInvestments,
    updateItem: updateInvestment,
  } = useStore(indexOperation, request, shouldAutoLoad, operationParameters)

  const activeInvestments = useMemo(() =>
    (investments || [])
      .filter(({ status }) => status === INVESTMENT_STATUS.ACTIVE)
      .sort((a, b) => a.name < b.name ? -1 : 1)
  , [ investments ])

  const readInvestment = id =>
    request(readOperation, { id })
      .then(({ data }) => updateInvestment(data))

  const acceptInvestment = id =>
    request(acceptOperation, { id })
      .then(({ data }) => updateInvestment(data))

  const backupInvestment = id =>
    request(backupOperation, { id })
      .then(({ data }) => updateInvestment(data))

  const rejectInvestment = (id, rejectionReason) =>
    request(rejectOperation, { id, mutation: { rejectionReason } })
      .then(({ data }) => updateInvestment(data))

  const deleteInvestment = id =>
    request(deleteOperation, { id })
      .then(() => removeInvestment(id))

  const useInvestment = id => {
    useEffect(() => {
      if (!id) { return }

      readInvestment(id)
    }, [ id ])

    const investment = id
      ? getInvestment(id)
      : undefined

    return investment
  }

  return {
    investments,
    addInvestment,
    useInvestment,
    readInvestment,
    deleteInvestment,
    acceptInvestment,
    backupInvestment,
    rejectInvestment,
    indexInvestments,
    updateInvestment,
    activeInvestments,
  }
}

const projectInvestmentsStoreProperties = {
  investments: PropTypes.arrayOf(IndexProjectInvestmentsOutputShape).isRequired,
  addInvestment: PropTypes.func.isRequired,
  useInvestment: PropTypes.func.isRequired,
  readInvestment: PropTypes.func.isRequired,
  deleteInvestment: PropTypes.func.isRequired,
  acceptInvestment: PropTypes.func.isRequired,
  backupInvestment: PropTypes.func.isRequired,
  rejectInvestment: PropTypes.func.isRequired,
  indexInvestments: PropTypes.func.isRequired,
  updateInvestment: PropTypes.func.isRequired,
  activeInvestments: PropTypes.arrayOf(IndexProjectInvestmentsOutputShape).isRequired,
}

export default useProjectInvestmentsStore

export {
  indexOperation,
  projectInvestmentsStoreProperties
}
