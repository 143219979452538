import React from "react"
import { App, Input } from "antd"

import { confirmAction } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import {
  rejectTransactionOperation,
  approveTransactionOperation,
  deleteExternalDistributionTransactionOperation,
} from "@api/services/transactions"

const { TextArea } = Input

const REJECT = "REJECT"
const APPROVE = "APPROVE"
const DELETE = "DELETE"

let rejectedReason = ""

const onChange = ({ currentTarget }) => {
  rejectedReason = currentTarget.value
}

const ACTIONS_MAP = {
  [APPROVE]: {
    type: "primary",
    title:  "Approve Distribution",
    action: "Approve",
    content: `By clicking "Approve" below, distributions will be sent to Dwolla
for processing. Funds will be debited from the distribution account. In
addition, investors will be notified via email that distributions are on their
way to their linked bank accounts.`,
    success: 'Distribution is approved',
    operation: approveTransactionOperation,
  },
  [REJECT]: {
    type: "danger",
    title: "Reject Distribution",
    action: "Reject",
    success: 'Distribution is rejected',
    content: <TextArea rows={4} placeholder="Rejected Reason" onChange={onChange}/>,
    operation: rejectTransactionOperation,
  },
  [DELETE]: {
    type: "danger",
    title: "Delete Backfill Distribution",
    action: "Delete",
    success: 'Backfill distribution is deleted',
    content: `You are about to delete the selected backfilled distributions.
This action cannot be undone. A total of [X] backfilled distribution(s) will be permanently removed. Note that investors will not be informed of this deletion.
Are you sure you want to proceed with the deletion?`,
    operation: deleteExternalDistributionTransactionOperation.id,
  },
}


const useDistributionActions = (getSelectedDistributions, afterAction) => {
  const { request, showSuccessMessage } = useAppContext()
  const { modal } = App.useApp()

  const action = actionName => {
    const isDelete = actionName === DELETE
    let selectedDistributions = getSelectedDistributions()

    let { content } = ACTIONS_MAP[actionName]

    if (isDelete) {
      selectedDistributions = selectedDistributions.filter(({ isExternal }) => isExternal)

      const selectedDistributionCount = selectedDistributions.length
      content = content.replace("[X]", selectedDistributionCount)
    }

    return confirmAction(
      modal,
      ACTIONS_MAP[actionName].action,
      ACTIONS_MAP[actionName].title,
      content,
      async () => {
        const message = ACTIONS_MAP[actionName].success
        const { operation } = ACTIONS_MAP[actionName]

        const parameters = {}

        const isReject = actionName === REJECT
        const isApprove = actionName === APPROVE
        const isApproveAndRejectEnable = isApprove || isReject

        if (isReject) {
          parameters.mutation = { statusReason: rejectedReason }
        }

        if (isApproveAndRejectEnable) {
          selectedDistributions = selectedDistributions.filter(({ isExternal }) => !isExternal)
        }

        for (const distribution of selectedDistributions) {
          const { transactionIds } = distribution

          for (const id of transactionIds) {
            await request(operation, { id, ...parameters })
          }
        }

        showSuccessMessage(message)
        rejectedReason = ""

        return afterAction()
      }
    )
  }

  const approveDistribution = () => action(APPROVE)

  const rejectDistribution = () => action(REJECT)

  const deleteBackfillDistributions = () => action(DELETE)

  return {
    approveDistribution,
    rejectDistribution,
    deleteBackfillDistributions
  }
}

export default useDistributionActions
