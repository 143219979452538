
export const getMemberName = (context, createdBy, parameters) => {
  const { membersMap } = context
  const { id } = parameters

  const member = membersMap[id]

  if (!member) {
    return 'Undefined'
  }

  const { givenName, familyName } = member
  const fullName = `${givenName} ${familyName}`

  return fullName
}
