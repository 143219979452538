import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"
import { Bullet } from "@ant-design/plots"

import PartnerShape from "@api/services/backstage/shapes/PartnerShape"
import { useAppContext } from "@components/AppContext"
import { getCurrencyValue, getFormattedDecimalValue } from "@components/Amount"

import getSponsorsData from "./helpers/getSponsorsData"
import getPartnersData from "./helpers/getFundsData"
import getAmountByInvestmentParams from "./helpers/getAmountByInvestmentParams"

const LABEL_TOTAL = "Total"

const ITEM_HEIGHT = 40
const LEGEND_HEIGHT = 30


const SponsorGoalsBarsChart = ({ partners = [], investmentsMapBySponsorId }) => {
  const { identity } = useAppContext()
  const { organization } = identity
  const { getSponsorName } = useOutletContext()
  const { name: organizationName } = organization

  const fundsMap = {}

  const fundsData = getPartnersData(partners, fundsMap)

  const fundsTarget = fundsData
    .map(({ target }) => target)
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

  const sponsorsData = getSponsorsData(partners, getSponsorName, investmentsMapBySponsorId, organizationName, fundsTarget)

  const sponsorTarget = sponsorsData
    .map(({ target }) => target)
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

  const sponsorsInvestments = sponsorsData
    .map(({ investments }) => investments)
    .flat(1)

  const { amounts, percentages, counts } = getAmountByInvestmentParams(sponsorsInvestments, sponsorTarget)

  const total = {
    title: LABEL_TOTAL,
    target: sponsorTarget,
    amounts,
    percentages,
    counts,
    rangePercentage: 150,
    targetPercentage: 100,
  }

  const data = [...sponsorsData, total].reverse()

  const rowsCount = data.length
  const height = (rowsCount * ITEM_HEIGHT) + LEGEND_HEIGHT

  const labels = [
    'Funded',
    'Not Signed',
    'Not Funded',
    'Partially Funded',
    'Overfunded',
  ]
  const [funded, notSigned, notFunded, partiallyFunded, overfunded] = labels

  const config = {
    data,
    height,
    color: {
      range: '#f0efff',
      measure: ['#ffe0b0', '#5b8ff9', '#ffbcb8', '#da94f5', '#bfeec8'],
    },
    rangeField: 'rangePercentage',
    targetField: 'targetPercentage',
    measureField: 'percentages',
    xField: 'title',
    xAxis: { line: null },
    yAxis: {
      line: null,
      min: 0,
      max: 150,
      label: false,
      grid: {
        line: { style: { opacity: 0 } },
      },
    },
    legend: {
      custom: true,
      position: 'bottom',
      items: [
        {
          value: funded,
          name: funded,
          marker: {
            style: {
              fill: '#ffe0b0',
            },
          },
        },
        {
          value: notSigned,
          name: notSigned,
          marker: {
            style: {
              fill: '#3d76dd',
            },
          },
        },
        {
          value: notFunded,
          name: notFunded,
          marker: {
            style: {
              fill: '#ffbcb8',
            },
          },
        },
        {
          value: partiallyFunded,
          name: partiallyFunded,
          marker: {
            style: {
              fill: '#da94f5',
            },
          },
        },
        {
          value: overfunded,
          name: overfunded,
          marker: {
            style: {
              fill: '#bfeec8',
            },
          },
        },
      ]
    },
    label: {
      target: {
        content: ({ title }) => {
          const item = data.find(d => d.title === title)
          const { target } = item
          return getCurrencyValue(target, 0)
        },
      },
      measure: false
    },
    tooltip: {
      showMarkers: false,
      shared: true,
      customItems: (originalItems) => {
        const formattedOriginalItems = originalItems.filter(({name}) => name !== 'targetPercentage')

        return formattedOriginalItems.map((item, index) => {
          const dataItem = data.find(d => d.title === item.title)
          const amount = getCurrencyValue(dataItem.amounts[index], 0)
          const count = dataItem.counts[index]
          item.name = labels[index]
          item.value = `${amount} (${count})`

          return item
        })
      }
    },
  }

  return <Bullet {...config} />
}

SponsorGoalsBarsChart.propTypes = {
  partners: PropTypes.arrayOf(PartnerShape),
  investmentsMapBySponsorId: PropTypes.shape().isRequired,
}

export default SponsorGoalsBarsChart
