
export const getInvestor = (context, createdBy, parameters, result) => {
  const { id, mutation = {} } = parameters
  const { investorAccountId: mutationInvestorAccountId, email } = mutation
  const { id: accountId, investorAccountId: resultInvestorAccountId } = result

  const { accountsMap } = context

  const account =
    accountsMap[id] ||
    accountsMap[accountId] ||
    accountsMap[mutationInvestorAccountId] ||
    accountsMap[resultInvestorAccountId]

  if (account) {
    return account.investor
  }

  if (!email) {
    return
  }

  const accountForEmail = Object
    .values(accountsMap)
    .find(a => a.investor.email === email)

  if (accountForEmail) {
    return accountForEmail.investor
  }
}
