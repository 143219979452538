import React from "react"
import PropTypes from "prop-types"

import TableRowActions from "@components/Table/TableRowActions"
import { ReadMyOrganizationOutputShape } from "@api/services/organizations"

import useRowMenu from "./helpers/useRowMenu"
import useActions from "./helpers/useActions"


const RowActions = ({
  table,
  organization,
  openUpdateModal
}) => {
  const actions = useActions(organization, table)

  const items = useRowMenu(organization, { ...actions, openUpdateModal })

  return (
    <TableRowActions items={items} />
  )
}

RowActions.propTypes = {
  table: PropTypes.shape().isRequired,
  organization: ReadMyOrganizationOutputShape.isRequired,
  openUpdateModal: PropTypes.func.isRequired,
}

export default RowActions
