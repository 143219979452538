import React, { useEffect, useRef } from "react"
import get from "lodash.get"
import { useOutletContext, useParams } from "react-router-dom"
import { Spin, Space } from "antd"

import { W0 } from "@components"
import { InvestmentDrawer } from "@components/Investment"
import {
  InvestmentsTable,
  InvestmentsSummary,
  InvestmentCreateModal
} from "@modules/backstage/investments"

import useInvestments from "./helpers/useInvestments"

const LABEL_FUNDS = 'Fund Managers'
const LABEL_BACKUP = 'Backup List'
const LABEL_DROPOUT = 'Dropped Out'
const LABEL_COMMITMENTS = 'Soft Commitments'


const ProjectInvestmentsPage = () => {
  const investmentDrawer = useRef({})
  const createInvestmentModal = useRef({})

  const fundInvestmentsTable = useRef({})
  const backupInvestmentsTable = useRef({})
  const dropoutInvestmentsTable = useRef({})
  const commitmentInvestmentsTable = useRef({})

  const { id: projectId } = useParams()
  const { investmentsId } = useParams()
  const { table, investments } = useOutletContext()

  useEffect(() => {
    if (!investmentsId) {
      return
    }

    investmentDrawer.current.open(investmentsId)
  }, [ investmentsId ])

  const [
    commitmentInvestments,
    fundInvestments,
    backupInvestments,
    dropoutInvestments
  ] = useInvestments()

  const openInvestmentDrawer = ({id}) => {
    window.history.pushState({}, null, `/backstage/project/${projectId}/investments/${id}`)
    investmentDrawer.current.open(id)
  }

  const openCreateInvestmentModal = () =>
    createInvestmentModal.current.open()

  const sharedTableProps = {
    openInvestmentDrawer,
    openCreateInvestmentModal,
  }

  table.current.search = value => {
    const tables = [
      fundInvestmentsTable,
      backupInvestmentsTable,
      dropoutInvestmentsTable,
      commitmentInvestmentsTable
    ]

    for (const investmentsTable of tables) {
      const search = get(investmentsTable, 'current.search')

      if (search) {
        search(value)
      }
    }
  }

  const isLoading = !investments

  const onCloseInvestment = () =>
    window.history.pushState({}, null, `/backstage/project/${projectId}/investments`)

  return (
    <Spin
      key={projectId}
      spinning={isLoading}
    >
      <div style={{ padding: W0 }}>
        <Space
          style={{ width: "100%" }}
          direction="vertical"
        >
          <InvestmentsSummary />

          <InvestmentsTable
            table={commitmentInvestmentsTable}
            items={commitmentInvestments}
            title={LABEL_COMMITMENTS}
            isOpenByDefault={true}
            {...sharedTableProps}
          />

          <InvestmentsTable
            table={fundInvestmentsTable}
            items={fundInvestments}
            title={LABEL_FUNDS}
            {...sharedTableProps}
          />

          <InvestmentsTable
            table={backupInvestmentsTable}
            items={backupInvestments}
            title={LABEL_BACKUP}
            {...sharedTableProps}
          />

          <InvestmentsTable
            table={dropoutInvestmentsTable}
            items={dropoutInvestments}
            title={LABEL_DROPOUT}
            {...sharedTableProps}
          />
        </Space>

        <InvestmentDrawer
          drawer={investmentDrawer}
          onClose={onCloseInvestment}
        />

        <InvestmentCreateModal
          modal={createInvestmentModal}
        />
      </div>
    </Spin>
  )
}

export default ProjectInvestmentsPage
