
export const getFundName = (context, createdBy, parameters) => {
  const { fundsMap } = context

  const { mutation } = parameters
  const { fundId } = mutation

  const fund = fundsMap[fundId]

  return fund?.name || 'Undefined'
}
