import { wait } from "@api/helpers/wait"
import { UNAUTHORIZED_ERROR } from "@api"

import authorize from "./authorize"
import { Storage } from "../authenticate"
import { operationRequest } from "../request"


const authorizedRequest = (onUnexpectedError, operation, parameters, expectedErrors) =>
  operationRequest({
    headers: { authorization: Storage.authorization },
    operation,
    parameters,
    expectedErrors,
    onUnexpectedError,
  })


const getAuthorizedRequest = (setIdentity, onUnexpectedError, onAuthorizationError, authorizationMethod, authentication) => {
  if (!authentication) {
    return (operation, parameters, expectedErrors = []) =>
      operationRequest({
        operation,
        parameters,
        expectedErrors,
        onUnexpectedError,
      })
  }

  return (operation, parameters, expectedErrors = []) =>
    authorizedRequest(onUnexpectedError, operation, parameters, [ ...expectedErrors, UNAUTHORIZED_ERROR ])
      .catch(async (error) => {
        const isAuthorizationExpired = error.code === UNAUTHORIZED_ERROR

        if (!isAuthorizationExpired) {
          throw error
        }

        const retryRequest = () => authorizedRequest(onUnexpectedError, operation, parameters, expectedErrors)

        // NOTE: Wait for 10ms to allow one of the threads to start.
        await wait(10)

        const { isPendingAuthorization } = Storage

        if (isPendingAuthorization) {
          // NOTE: If authentication errors, we do not stop these thread.
          do {
            await wait(250)

          } while (Storage.isPendingAuthorization)

          return retryRequest()
        }

        Storage.startAuthorization()

        const { customOrganizationId: organizationId } = Storage

        await authorize({
          setIdentity,
          authentication,
          organizationId,
          authorizationMethod,
          onAuthorizationError,
        })

        Storage.stopAuthorization()

        return retryRequest()
      })
}

export default getAuthorizedRequest
