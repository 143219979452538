import React from "react"
import PropTypes from "prop-types"

import { DESCEND, VirtualTable } from "@components/Table"
import {
  indexInvestorTransactionsOperation as indexOperation,
  IndexInvestorTransactionsOutputShape,
} from "@api/services/transactions"

import useColumnConfig from "./helpers/useColumnConfig"
import useTableSummary from "./helpers/useTableSummary"
import useExportColumns from "../TransactionsTable/helpers/useExportColumns"


const AccountTransactionsTable = ({
  items,
  indexItems,
  openTransactionDrawer,
}) => {
  const size = "small"
  const sticky = true
  const summary = useTableSummary()
  const bordered = true
  const columnsConfig = useColumnConfig(openTransactionDrawer)
  const exportColumns = useExportColumns()
  const exportFileName = "transactions"
  const hasHeaderFilters = true
  const defaultSortColumn = "date"
  const defaultSortColumnDirection = DESCEND

  const tableProps = {
    size,
    items,
    sticky,
    summary,
    bordered,
    indexItems,
    columnsConfig,
    exportColumns,
    exportFileName,
    indexOperation,
    hasHeaderFilters,
    defaultSortColumn,
    defaultSortColumnDirection,
  }

  return (
    <VirtualTable {...tableProps} />
  )
}

AccountTransactionsTable.propTypes = {
  items: PropTypes.arrayOf(IndexInvestorTransactionsOutputShape).isRequired,
  indexItems: PropTypes.func.isRequired,
  openTransactionDrawer: PropTypes.func.isRequired,
}

export default AccountTransactionsTable
