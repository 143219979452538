import PropTypes from "prop-types"

import AssetShape from "./shapes/AssetShape"
import PropertyShape from "./shapes/PropertyShape"
import PublicBrokerShape from "./shapes/PublicBrokerShape"
import TagShape from "./shapes/TagShape"
import TierShape from "./shapes/TierShape"
import { ProjectStateEnumKeys } from "./shapes/ProjectStateEnum"
import { ProjectTypeEnumKeys } from "./shapes/ProjectTypeEnum"

const properties = {
  closeDate: PropTypes.string,
  coverImageUrl: PropTypes.string,
  description: PropTypes.string,
  documents: PropTypes.arrayOf(AssetShape),
  fundDeadlineDate: PropTypes.string,
  fundOfferingStructure: PropTypes.string,
  hasBankAccount: PropTypes.bool,
  id: PropTypes.string.isRequired,
  investmentSubjects: PropTypes.arrayOf(PropertyShape).isRequired,
  isAchFundingEnabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  openDate: PropTypes.string,
  ppmTemplateId: PropTypes.string,
  signDeadlineDate: PropTypes.string,
  sponsorBrokers: PropTypes.arrayOf(PublicBrokerShape),
  state: PropTypes.oneOf(ProjectStateEnumKeys).isRequired,
  tags: PropTypes.arrayOf(TagShape),
  targetAmount: PropTypes.number,
  targetCloseDate: PropTypes.string,
  tiers: PropTypes.arrayOf(TierShape).isRequired,
  type: PropTypes.oneOf(ProjectTypeEnumKeys).isRequired,
  videos: PropTypes.arrayOf(AssetShape)
}

const ReadPublicPublishedProjectOutputShapeShape = PropTypes.exact(properties)

export default ReadPublicPublishedProjectOutputShapeShape
export { properties }
