import getOperation from "../../helpers/getOperation"

import IndexActivitiesOutputShape from "./IndexActivitiesOutputShape"
import IndexInvestorActivitiesOutputShape from "./IndexInvestorActivitiesOutputShape"

export {
  IndexActivitiesOutputShape,
  IndexInvestorActivitiesOutputShape
}

export const indexActivitiesOperation = getOperation("activities/IndexActivities")
export const indexInvestorActivitiesOperation = getOperation("activities/IndexInvestorActivities")
