import { TYPE_AMOUNT, TYPE_RADIO, TYPE_NONE } from "@components/Form"
import {
  TRANSACTION_TYPES,
  TRANSACTION_AMOUNT_MAX,
  TRANSACTION_TYPE_OPTIONS,
} from "@components/Domain"


const getCreateInputs = investmentsOptions => {
  const inputs = [
    {
      name: "details",
      type: TYPE_NONE,
    },
    {
      name: "type",
      type: TYPE_RADIO,
      label: "Type",
      options: TRANSACTION_TYPE_OPTIONS,
      initialValue: TRANSACTION_TYPES.SALE_PROFIT,
    },
    {
      name: "investmentId",
      label: "Investment",
      options: investmentsOptions,
      placeholder: "Select investor"
    },
    ...[
      {
        name: "date",
        label: "Date",
        type: "date"
      },
      {
        name: "amount",
        type: TYPE_AMOUNT,
        label: "Amount",
        placeholder: "1",
        minimum: 1,
        maximum: TRANSACTION_AMOUNT_MAX,
        inputProps: {
          style: {
            width: 130
          },
          controls: false
        }
      },
    ]
  ]

  return inputs
}

export default getCreateInputs
