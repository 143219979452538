import { LOCAL_DOMAIN, isLocal } from "@components"

import setTitle from "./setTitle"


const getApplicationTitle = organizations => {
  if (!organizations) {
    return 'Undefined'
  }

  let { host } = window.location

  if (isLocal) {
    host = LOCAL_DOMAIN
  }

  const organization = organizations.find(({ domain }) => domain === host)

  if (!organization) {
    return 'Undefined'
  }

  return organization.name
}

const setApplicationTitle = (identity = { organizations: [] } ) => {
  const { organizations, organization } = identity

  if (organization) {
    setTitle(organization.name)
    return
  }

  const title = getApplicationTitle(organizations)

  setTitle(title)
}

export default setApplicationTitle
