import React from "react"
import { Tag, Typography, Badge } from "antd"

import { W0 } from "@components"
import { STATE_LABELS_MAP, PROJECT_STATES, PROJECT_TYPES } from "@components/Domain"

const { Text } = Typography

const STATE_COLORS_MAP = {
  [PROJECT_STATES.HOLD]: "orange",
  [PROJECT_STATES.OPEN]: "blue",
  [PROJECT_STATES.CLOSED]: "green",
  [PROJECT_STATES.ARCHIVED]: "violet"
}


const renderProjectLabel = (name, shortName, state, type) => {
  const isFund = type === PROJECT_TYPES.FUND
  const text = shortName ? shortName : name

  const title = isFund
    ? <Badge color="blue" text={text} style={{ marginLeft: -14 }} />
    : <Text>{text}</Text>

  return (
    <>
      <Tag
        style={{ float: "right", position: "relative", top: W0 + 1, marginRight: -10 }}
        color={STATE_COLORS_MAP[state]}
        bordered={false}
      >
        {STATE_LABELS_MAP[state]}
      </Tag>

      {title}
    </>
  )
}

export default renderProjectLabel
