import React, { useState } from "react"
import PropTypes from "prop-types"
import { Typography, Tooltip, Space, Button, Descriptions, Row, Col, App } from "antd"

import { CustomerShape } from "@components/Dwolla"
import { confirmAction } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import { InvestmentShape } from "@components/Investment/shapes"
import { deleteMySourceOperation } from "@api/services/transactions"
import { DeleteOutlined, WarningOutlined } from "@ant-design/icons"

const { Item } = Descriptions
const { Text } = Typography

const LABEL_INVALID = "Bank account is suspended."

const ACTION = {
  type: "danger",
  title: "Delete Source",
  action: "Delete",
  content: `The bank account "[BANK_ACCOUNT_NAME]" will be deleted.`,
}

const UnusedBankAccountsCard = ({
  customer,
  investments
}) => {
  const { modal } = App.useApp()
  const [ isLoading, setIsLoading ] = useState(false)

  const { authenticatedRequest, authorizeOrganizationIdentity } = useAppContext()

  const { name: customerName, sources = [] } = customer

  const unusedSources = []

  for (const source of sources) {
    const { id: sourceId } = source

    const used = investments.filter(i => i.receiverSourceId === sourceId)

    const isUnsusedSource = used.length === 0

    if (isUnsusedSource) {
      unusedSources.push(source)
    }
  }

  const deleteSource = (id, bankName) => {
    const content = ACTION.content.replace('[BANK_ACCOUNT_NAME]', bankName)

    confirmAction(
      modal,
      ACTION.action,
      ACTION.title,
      content,
      async () => {
        setIsLoading(true)
        return authenticatedRequest(deleteMySourceOperation, { id })
            .then(() => authorizeOrganizationIdentity())
            .then(() => setIsLoading(false))
      },
      ACTION.type
    )
  }
  const descriptionsProps = {
    size: "small",
    layout: "vertical",
    column: 1,
    bordered: false,
    className: "summary",
  }

  const style = { margin: 0 }

  const haveUnusedSourcces = unusedSources.length > 0

  if (!haveUnusedSourcces) {
    return
  }

  return (
    <Space direction="vertical" style={{ width: "100%", marginBottom: 10 }} size={0}>
      <Space direction="vertical" style={{ width: "100%" }} size={0}>
          <Typography.Title
            level={5}
            style={style}
          >
            {customerName}
          </Typography.Title>
      </Space>

      <Descriptions {...descriptionsProps}>
        <Item>
          <Space direction="vertical" style={{ width: "100%" }} size={2}>
            {
              unusedSources.map((source, key) => (
                <Row key={key}>
                  <Col flex="auto">
                    <Space>
                    <Text>
                      {source.name}
                    </Text>
                    {
                      source.isInvalid && (
                        <Tooltip
                          placement="top"
                          title={LABEL_INVALID}
                        >
                          <WarningOutlined style={{ color: "red" }} />
                        </Tooltip>
                      )
                    }
                    </Space>
                  </Col>
                  <Col>
                    <Button
                      danger
                      type="text"
                      size="small"
                      icon={<DeleteOutlined />}
                      style={{ float: "right" }}
                      onClick={() => deleteSource(source.id, source.name)}
                      loading={isLoading}
                    >
                      Delete
                    </Button>
                  </Col>
                </Row>
              ))
            }
          </Space>
        </Item>
      </Descriptions>
    </Space>
  )
}

UnusedBankAccountsCard.propTypes = {
  customer: CustomerShape.isRequired,
  investments: PropTypes.arrayOf(InvestmentShape).isRequired,
}

export default UnusedBankAccountsCard
