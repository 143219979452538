import React, { useState } from "react"
import { useOutletContext } from "react-router-dom"
import PropTypes from "prop-types"

import { MODAL_WIDTH } from "@components"
import { CreateModal } from "@components/Page"
import { createPropertyOperation as operation } from "@api/services/backstage"

import getInputs from "./helpers/getInputs"

const LABEL_TITLE = "Create Property"
const LABEL_ENTITY = "Property"


const ProjectPropertyCreateModal = ({ modal }) => {
  const [ schema, setSchema ] = useState([])

  const {
    project,
    properties,
    addProperty,
  } = useOutletContext()

  modal.current.openWithProperties = async () => {
    const getCreateInputs = getInputs({ properties, project })
    const inputs = getCreateInputs()

    const newSchema = operation.getSchema(inputs)
    setSchema(newSchema)

    modal.current.open()
  }

  const onSuccess = createdProperty =>
    addProperty(createdProperty)

  const createModalProps = {
    title: LABEL_TITLE,
    width: MODAL_WIDTH * 1.25,
    entity: LABEL_ENTITY,
    modal,
    schema,
    onSuccess,
    operation,
  }

  return (
    <CreateModal {...createModalProps} />
  )
}

ProjectPropertyCreateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default ProjectPropertyCreateModal
