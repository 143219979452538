
export const FUND_NAME = '%FUND_NAME'
export const SOURCE_TYPE = '%SOURCE_TYPE'
export const MEMBER_NAME = '%MEMBER_NAME'
export const PROJECT_TYPE = '%PROJECT_TYPE'
export const PROJECT_NAME = '%PROJECT_NAME'
export const INVESTOR_NAME = '%INVESTOR_NAME'
export const INVESTOR_EMAIL = '%INVESTOR_EMAIL'
export const MY_MEMBER_NAME = '%MY_MEMBER_NAME'
export const COMMITMENT_NAME = '%COMMITMENT_NAME'
export const MY_INVESTOR_NAME = '%MY_INVESTOR_NAME'
export const COMMITMENT_AMOUNT = '%COMMITMENT_AMOUNT'
export const INVESTMENT_AMOUNT = '%INVESTMENT_AMOUNT'
export const PROJECT_OPEN_DATE = '%PROJECT_OPEN_DATE'
export const MY_INVESTOR_MOBILE = '%MY_INVESTOR_MOBILE'
export const TRANSACTIONS_AMOUNT = '%TRANSACTIONS_AMOUNT'
export const PARTNER_TARGET_AMOUNT = '%PARTNER_TARGET_AMOUNT'
export const INVESTMENT_PROFILE_TYPE = '%INVESTMENT_PROFILE_TYPE'
export const TRANSACTION_PROJECT_NAME = '%TRANSACTION_PROJECT_NAME'
export const PARTNER_ORGANIZATION_NAME = '%PARTNER_ORGANIZATION_NAME'
