import TEMPLATES from "./templates"
import MAP, { KEYS } from "./helpers"


const renderTemplate = (context, operationId, createdBy, parametersJson = "{}", resultJson = "{}") => {
  const hasTemplate = TEMPLATES[operationId] ? true : false

  if (!hasTemplate) {
    return ""
  }

  const result = JSON.parse(resultJson)
  const parameters = JSON.parse(parametersJson)

  const template = TEMPLATES[operationId]

  const templateKeys = KEYS.filter(key => template.includes(key))

  let output = `${template}`

  for (const key of templateKeys) {
    const helper = MAP[key]
    const value = helper(context, createdBy, parameters, result)

    output = output.replace(key, value)
  }

  return output
}

export default renderTemplate
