import React from "react"
import { Tag, Tooltip } from "antd"
import PropTypes from "prop-types"
import { ExclamationCircleOutlined } from "@ant-design/icons"


const OrganizationStatusTag = ({
  disableReason = undefined,
  isDisabled,
}) => {
  const params = {
    color: "green",
    title: "Active"
  }

  if (isDisabled) {
    params.color = "gray"
    params.title = "Disabled"
  }

  const hasDisableReason =
    isDisabled &&
    !!disableReason &&
    disableReason !== ""

  return (
    <>
      <Tag
        color={params.color}
        bordered={false}
      >
        {params.title}
      </Tag>
      {
        hasDisableReason && (
          <Tooltip title={disableReason} placement="top">
            <ExclamationCircleOutlined />
          </Tooltip>
        )
      }
    </>
  )
}

OrganizationStatusTag.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  disableReason: PropTypes.string,
}

export default OrganizationStatusTag
