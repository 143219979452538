import React from "react"
import { Typography } from "antd"
import { useOutletContext } from "react-router-dom"

import { Value } from "@components/Text"
import TransactionStatusTag from "@modules/investor/InvestorTransactionsPage/TransactionStatusTag"
import {
  TRANSACTION_TYPE_LABEL_MAP,
  TRANSACTION_STATUS_LABEL_MAP,
  TRANSACTION_TYPE_ABBREVIATIONS_MAP
} from "@components/Domain"

import getAmountColumnProps from "../../TransactionsTable/helpers/getAmountColumnProps"

const { Text } = Typography


const useColumnConfig = (openTransactionDrawer) => {
  const { getProjectName, organization, isInvalidBankAccountTransaction } = useOutletContext()

  const { name: organizationName } = organization

  const dateColumnProps = {
    key: "date",
    title: "Date",
    render: value =>
      <Value value={value} />,
  }

  const projectColumnProps = {
    key: "projectId",
    title: "Project",
    compute: ({ projectId }) => getProjectName(projectId),
    isFilterEnabled: true,
  }

  const profileColumnProps = {
    key: "investmentName",
    title: "Profile",
    isFilterEnabled: true,
    render: value => <Text>{value}</Text>
  }

  const typeColumnProps = {
    key: "type",
    title: "Type",
    compute: ({ type }) => TRANSACTION_TYPE_LABEL_MAP[type],
    render: (value, { type }) => TRANSACTION_TYPE_ABBREVIATIONS_MAP[type]?.title || type,
    isFilterEnabled: true,
  }

  const statusColumnProps = {
    key: "status",
    title: "Status",
    compute: ({ status }) => TRANSACTION_STATUS_LABEL_MAP[status] || status,
    render: (label, { status }) =>
      <TransactionStatusTag status={status} />,
    isFilterEnabled: true,
  }

  const amountInColumnProps = getAmountColumnProps(organizationName, openTransactionDrawer, isInvalidBankAccountTransaction)
  const amountOutColumnProps = getAmountColumnProps(organizationName, openTransactionDrawer, isInvalidBankAccountTransaction, false)

  return [
    { ...dateColumnProps, fixed: "left", width: 100 },
    { ...profileColumnProps, fixed: "left", width: 260, hasTruncation: true },
    { ...projectColumnProps, width: "auto" },
    { ...typeColumnProps, align: "center", width: 80 },
    { ...amountInColumnProps, align: "right" },
    { ...amountOutColumnProps, align: "right" },
    { ...statusColumnProps, align: "right", width: 100 },
  ]
}

export default useColumnConfig
