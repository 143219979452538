import { getFormattedDecimalValue } from "@components/Amount"


const getAmountByInvestmentParams = (investments, targetAmount) => {
  const notSignedInvestments = investments.filter(({isSigned}) => !isSigned)

  const notFundedInvestments = investments.filter(({ isOverfunded, isFunded, isPartiallyFunded, isSigned }) => !isFunded && !isOverfunded && !isPartiallyFunded && isSigned)

  const fundedInvestments = investments.filter(({isFunded}) => isFunded)

  const partiallyFundedInvestments = investments.filter(({isPartiallyFunded}) => isPartiallyFunded)

  const overfundedInvestments = investments.filter(({isOverfunded}) => isOverfunded)

  const getTargetAmount = items => items.map(({ totalAmount }) => totalAmount)
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

  const getReceivedAmount = items => items.map(({ receivedAmount }) => receivedAmount)
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)

  const notSigned = getTargetAmount(notSignedInvestments)
  const notFunded = getTargetAmount(notFundedInvestments)
  const funded = getReceivedAmount(fundedInvestments)
  const partiallyFunded = getReceivedAmount(partiallyFundedInvestments)
  const overfunded = getReceivedAmount(overfundedInvestments)

  return {
    amounts: [
      funded,
      notSigned,
      notFunded,
      partiallyFunded,
      overfunded,
    ],
    counts: [
      fundedInvestments.length,
      notSignedInvestments.length,
      notFundedInvestments.length,
      partiallyFundedInvestments.length,
      overfundedInvestments.length,
    ],
    percentages: [
      Math.round(funded / targetAmount * 100),
      Math.round(notSigned / targetAmount * 100),
      Math.round(notFunded / targetAmount * 100),
      Math.round(partiallyFunded / targetAmount * 100),
      Math.round(overfunded / targetAmount * 100),
    ],
  }
}

export default getAmountByInvestmentParams
