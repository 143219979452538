import React from "react"
import PropTypes from "prop-types"
import { Form } from "antd"

import Redactor from "@components/Redactor"
import { convertToImgixUrls } from "@components/Storage"

import unwrapEmail from "./helpers/unwrapEmail"
import FormShape from "../../shapes/FormShape"

const { Item } = Form

const DEFAULT_OPTIONS = {
  theme: "light",
  plugins: [],
  translations: {
    en: {
      "mergetag": {
        "title": "Dynamic Fields"
      }
    }
  }
}

const COMPACT_OPTIONS = {
  padding: "4px 11px",
  control: false,
  toolbar: {
    hide: [
      "add",
      "html",
      "image",
      "table",
      "format",
      "preview",
      "deleted",
      "moreinline",
      "style-panel",
    ],
  },
  buttons: {
    toolbar: [
      "bold",
      "italic",
      "link",
      "list",
    ],
    extrabar: [],
  },
}


const RedactorInput = ({
  form,
  path,
  itemProps,
  inputProps
}) => {
  const {
    compact = true,
    options: extraOptions = {},
    buttons: extraButtons,
    shouldUnwrapEmail = true,
    emailTargetPath,
    ...restInputProps
  } = inputProps

  const content = Form.useWatch(path, form)

  const { plugins = [], ...restExtraOptions } = extraOptions

  let options = {
    ...DEFAULT_OPTIONS
  }

  if (compact) {
    options = {
      ...options,
      ...COMPACT_OPTIONS
    }
  }

  options.plugins = [
    ...options.plugins,
    ...plugins
  ]

  if (emailTargetPath) {
    options.plugins.push("email")
    options.email = {
      spacing: "16px",
      body: { padding: "4px 11px" },
    }
  }

  if (extraButtons) {
    options.buttons = extraButtons
  }

  options = {
    ...options,
    ...restExtraOptions
  }

  const onChange = (html, email) => {
    form.setFieldValue(path, html)

    let emailContent = shouldUnwrapEmail
      ? unwrapEmail(email)
      : email

    emailContent = convertToImgixUrls(emailContent)

    form.setFieldValue(emailTargetPath, emailContent)
  }

  return (
    <Item {...itemProps}>
      <Redactor
        options={options}
        content={content}
        onChange={onChange}
        {...restInputProps}
      />
    </Item>
  )
}

RedactorInput.propTypes = {
  form: FormShape.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  itemProps: PropTypes.shape().isRequired,
  inputProps: PropTypes.shape().isRequired,
}

export default RedactorInput
