import React from "react"
import { useOutletContext } from "react-router-dom"

import Table from "@components/Table"
import { VIRTUAL_TABLE_HEIGHT } from "@components"

const SUMMARY_HEIGHT = 40
const PAGINATION_HEIGHT = 56
const HEADER_FILTERS_HEIGHT = 32


const VirtualTable = ({
  ...tableProps
}) => {
  const { layoutTopOffset: extraTopOffset = 0 } = useOutletContext()

  const hasSummary = !!tableProps.summary
  const hasPagination = !!tableProps.pagination
  const hasHeaderFilters = tableProps.hasHeaderFilters || tableProps.hasHeaderSegment

  const yScroll = VIRTUAL_TABLE_HEIGHT
    - (hasSummary ? SUMMARY_HEIGHT : 0)
    - (hasPagination ? PAGINATION_HEIGHT : 0)
    - (hasHeaderFilters ? HEADER_FILTERS_HEIGHT : 0)
    - extraTopOffset

  const scroll = {
    y: yScroll,
    x: "max-content"
  }

  return (
    <Table
      virtual
      scroll={scroll}
      {...tableProps}
    />
  )
}

export default VirtualTable
