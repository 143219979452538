import { Auth as Cognito } from "aws-amplify"

import Storage from "./Storage"


class Auth {
  static signOut() {
    Storage.reset()

    return Cognito.signOut()
  }

  static getIdToken = async () => {
    let user = {}

    try {
      user = await Cognito.currentAuthenticatedUser()

    } catch (error) {
      const isExpiredTokenError = error.message === 'jwt expired'

      if (isExpiredTokenError) {
        Storage.reset()

        return window.location.reload()
      }

      throw error
    }

    const { signInUserSession } = user
    const { jwtToken } = signInUserSession.idToken

    return jwtToken
  }

  static async refreshAuthentication(authenticate, isBackstage) {
    Storage.removeBackstageAuthentication()

    await Auth.currentAuthenticatedUser({ bypassCache: true })

    const authenticationJwt = await authenticate(() => {}, isBackstage, true)

    return authenticationJwt
  }
}

export default Auth
