import React from "react"
import { Link, useParams, useOutletContext } from "react-router-dom"
import { Typography } from "antd"

import { Value } from "@components/Text"
import TransactionStatusTag from "@modules/investor/InvestorTransactionsPage/TransactionStatusTag"
import {
  TRANSACTION_STATUS,
  TRANSACTION_TYPE_LABEL_MAP,
  TRANSACTION_STATUS_LABEL_MAP,
} from "@components/Domain"


const useTableColumns = () => {
  const { projectId } = useParams()
  const { investmentsMap } = useOutletContext()

  const getProjectName = investmentId =>
    investmentsMap[investmentId]?.projectName || investmentId

  const getInvestmentName = investmentId =>
    investmentsMap[investmentId]?.name || investmentId

  const investmentColumnProps = {
    key: "investmentId",
    title: "Investment",
    isFilterEnabled: true,
    compute: ({ investmentId }) =>
      getProjectName(investmentId),
    render: (name, transaction) =>
      <Typography.Text strong>
        <Link to={`/projects/${transaction.projectId}/about`}>
          {name}
        </Link>
      </Typography.Text>,
  }

  const columns = []

  if (!projectId) {
    columns.push({ ...investmentColumnProps })
  }

  const profileColumnProps = {
    key: "investorAccountId",
    title: "Profile",
    isFilterEnabled: true,
    compute: ({ investmentId }) =>
      getInvestmentName(investmentId),
  }

  const typeColumnProps = {
    key: "type",
    compute: ({ type }) => TRANSACTION_TYPE_LABEL_MAP[type] || type,
    isFilterEnabled: true,
  }

  const dateProps = {
    key: "date",
    title: "Date",
    render: value =>
      <Value value={value} />,
  }

  const amountProps = {
    key: "amount",
    render: value =>
      <Value value={value} />,
  }

  const statusColumnProps = {
    key: "status",
    compute: ({ status }) => TRANSACTION_STATUS_LABEL_MAP[status] || status,
    isFilterEnabled: true,
    filterValues: [
      TRANSACTION_STATUS.ERROR,
      TRANSACTION_STATUS.FAILED,
      TRANSACTION_STATUS.PENDING,
      TRANSACTION_STATUS.PROCESSED
    ],
    render: (label, { status }) =>
      <TransactionStatusTag status={status} />,
  }

  columns.push(
    profileColumnProps,
    typeColumnProps,
    dateProps,
    amountProps,
    statusColumnProps
  )

  return columns
}

export default useTableColumns
