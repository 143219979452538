import React from "react"
import { Alert } from "antd"

import { W1 } from "@components"
import {
  TOOLTIP_PROFILE_DRE,
  ENTITY_TYPE_TITLES_MAP,
  TOOLTIP_BENEFICIAL_OWNER_NAME,
  ENTITY_TYPE_EXEMPT_ORGANIZATION,
  ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_IRA,
  ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_LLC,
  BENEFICIAL_OWNER_TAX_FILING_STATUS_TITLES_MAP,
  ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_INDIVIDUAL,
  ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_TITLES_MAP,
} from "@components/Domain"
import { Disclaimer } from "@components/Text"
import { TYPE_EIN, TYPE_RADIO, TYPE_STRING } from "@components/Form"
import { getAddressSchema, getUsAddressSchema } from "@components/Address"

import RULES from "./rules"
import computeResidencyInputs from "./computeResidencyInputs"
import getProfileEntityInputsMap from "./getProfileEntityInputsMap"

const TITLE_WARNING = "Warning"
const LABEL_BENEFICIAL_OWNER_NAME = "Beneficial Owner Name"
const TOOLTIP_DRE_BENEFICIAL_OWNER_NAME = `The beneficial owner of a disregarded
entity is the person (or entity), as shown on its income tax return, where the
disregarded entity's income or loss is reported. For example, it is the single
member of a single member LLC, or the grantor of a grantor trust. If the direct
owner of the entity is also a disregarded entity, please list the first owner
that is not disregarded for federal tax purposes. Please consult your tax
advisor if you are unsure.`
const DESCRIPTION_ENTITY_NO_EIN = `
  The IRS requires a tax ID to be inputted on all schedule K-1s for disregarded entities.
  By selecting "No", you are hereby confirming that your entity does not have an EIN.
`


const getProfileEntityOtherSchema = (organization, updateSchema, profile = {}) => {
  const { email: organizationEmail } = organization

  const {
    entityType,
    hasEinNumber,
    beneficialOwnerAddress,
    beneficialOwnerEntityType,
    isDisregardedForTaxPurposes,
    hasSsn,
    isUsPerson,
    isUsCitizen
  } = profile

  const inputPropsMap = getProfileEntityInputsMap()

  const isIndividual = beneficialOwnerEntityType === ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_INDIVIDUAL
  const isIra = beneficialOwnerEntityType === ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_IRA
  const isSetBeneficialType = beneficialOwnerEntityType !== undefined
  const isNotDisregarded = isDisregardedForTaxPurposes === false
  const isNotUsPerson = isUsPerson === false

  if (isNotDisregarded) {
    profile.beneficialOwnerEntityType = undefined
  }
  if (isDisregardedForTaxPurposes) {
    profile.entityType = undefined
  }

  const isLLC = (isDisregardedForTaxPurposes && profile.beneficialOwnerEntityType === ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_LLC) ||
    (isNotDisregarded && profile.entityType === ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_LLC)

  const commonInputs = [
    {
      name: "name",
      type: TYPE_STRING,
      label: "Entity’s Full Legal Name",
      required: true,
      maxLength: 255,
      itemProps: {
        rules: [
          RULES.ENTITY_NAME
        ]
      }
    },
    {
      name: "title",
      type: TYPE_STRING,
      label: "Your Title",
      required: true
    },
    {
      name: "isDisregardedForTaxPurposes",
      label: "Is this entity disregarded for federal income tax purposes?",
      required: true,
      itemProps: {
        extra: <Disclaimer text={TOOLTIP_PROFILE_DRE} />
      },
      inputProps: {
        onChange: () => updateSchema()
      },
    }
  ]

  const defaultHiddenInputs = [
    inputPropsMap.beneficialOwnerEntityType,
    inputPropsMap.beneficialOwnerName,
    inputPropsMap.beneficialOwnerAddress,
    inputPropsMap.isUsCitizen,
    inputPropsMap.beneficialOwnerSsnNumber,
    inputPropsMap.beneficialOwnerEinNumber,
  ]

  const beneficialOwnerTooltip = isDisregardedForTaxPurposes === true
    ? TOOLTIP_DRE_BENEFICIAL_OWNER_NAME
    : TOOLTIP_BENEFICIAL_OWNER_NAME

  let inputs = [
    ...commonInputs,
    ...defaultHiddenInputs,
    inputPropsMap.entityType,
    inputPropsMap.address,
    inputPropsMap.einNumber,
    inputPropsMap.residenceState,
    inputPropsMap.hasOtherUsResidenceState,
    inputPropsMap.isUsPerson,
    inputPropsMap.hasSsn,
    inputPropsMap.hasEinNumber,
    inputPropsMap.isRetirementPlan,
    inputPropsMap.beneficialOwnerCustodianEinNumber,
    inputPropsMap.beneficialOwnerTaxFilingStatus,
    inputPropsMap.taxFilingStatus,
  ]

  const isNotUsCitizen = isUsCitizen === false
  const isTin = isNotUsPerson && isNotUsCitizen

  const getHasSsnLabel = () => {
    if (isIndividual) {
      if (isTin) {
        return "Does this beneficial owner have a US TIN?"
      }

      return "Does this beneficial owner have a SSN?"
    }

    if (isIra) {
      return "Does this beneficial owner custodian have an EIN?"
    }

    return "Does this beneficial owner have an EIN?"
  }

  let labels = {
    hasOtherUsResidenceState: "Is the state listed under the address also the beneficial owner's state of residence for tax purposes?",
    residenceState: "Please list the beneficial owner's state of residence",
    isUsPerson: "Is this beneficial owner considered a US person for federal tax purposes, as defined by the IRS?",
    hasSsn: getHasSsnLabel()
  }

  const shouldShowNotEinAlert = hasEinNumber === false
    ?  <Alert
      showIcon
      type="warning"
      message={TITLE_WARNING}
      description={DESCRIPTION_ENTITY_NO_EIN}
      style={{marginTop: W1}}
    />
    : undefined


  if (isDisregardedForTaxPurposes) {
    const einNumberInput = hasEinNumber
      ? {
        name: "einNumber",
        type: TYPE_EIN,
        label: "Entity EIN",
        required: true,
      }
      : inputPropsMap.einNumber

    if (isSetBeneficialType && !isIndividual) {
      profile.isUsCitizen = false
    }

    const llcInputs = isLLC
      ? [
        {
          name: "beneficialOwnerTaxFilingStatus",
          type: TYPE_RADIO,
          label: "Tax Filing Status",
          required: true,
          options: Object.keys(BENEFICIAL_OWNER_TAX_FILING_STATUS_TITLES_MAP)
            .map(value => ({ value, label: BENEFICIAL_OWNER_TAX_FILING_STATUS_TITLES_MAP[value] })),
        }
      ]
      : [
        inputPropsMap.beneficialOwnerTaxFilingStatus,
      ]

    const iraInputs = isIra && hasSsn
      ? {
        name: "beneficialOwnerCustodianEinNumber",
        type: TYPE_EIN,
        label: "Beneficial Owner Custodian EIN",
      }
      : inputPropsMap.beneficialOwnerCustodianEinNumber

    const addressInputs = isSetBeneficialType
      ? {
        name: "beneficialOwnerAddress",
        label: "",
        schema: getAddressSchema(updateSchema, ["profileEntity", "beneficialOwnerAddress"], beneficialOwnerAddress, {}, {
          addressCountry: "Beneficial Owner Country",
          streetAddress: "Beneficial Owner Address",
          addressLocality: "Beneficial Owner City",
          usAddressRegion: "Beneficial Owner State",
          addressRegion: "Beneficial Owner Region",
          usPostalCode: "Beneficial Owner ZIP Code",
          postalCode: "Beneficial Owner Postal Code",
        }),
      }
      : inputPropsMap.beneficialOwnerAddress

    const isUsCitizenInput = isIndividual
      ? {
        name: "isUsCitizen",
        label: "Is this beneficial owner a US citizen?",
        required: true,
        inputProps: {
          onChange: () => updateSchema()
        }
      }
      : inputPropsMap.isUsCitizen

    const residencyInputs = computeResidencyInputs(profile, updateSchema, labels, organizationEmail)

    const stateInputs = isSetBeneficialType
      ? [
        ...residencyInputs,
      ]
      : [
        inputPropsMap.residenceState,
        inputPropsMap.hasOtherUsResidenceState,
        inputPropsMap.isUsPerson,
        inputPropsMap.hasSsn
      ]

    const shouldShowSnnInput = isIndividual && hasSsn

    const shouldShowEinInput = isSetBeneficialType && !isIndividual && hasSsn && !isIra

    const einInput = shouldShowEinInput
      ? {
        name: "beneficialOwnerEinNumber",
        type: TYPE_EIN,
        required: true,
        label: "Beneficial Owner EIN",
      }
      : inputPropsMap.beneficialOwnerEinNumber

    const ssnInput = shouldShowSnnInput
      ? {
        name: "beneficialOwnerSsnNumber",
        required: true,
        label: isTin ? "Beneficial Owner ITIN" : "Beneficial Owner SSN",
      }
      : inputPropsMap.beneficialOwnerSsnNumber

    inputs = [
      ...commonInputs,
      ...[
        inputPropsMap.address,
        inputPropsMap.entityType,
        inputPropsMap.isRetirementPlan,
        inputPropsMap.taxFilingStatus,
        {
          name: "hasEinNumber",
          label: "Does this entity have an EIN?",
          required: true,
          itemProps: {
            extra: shouldShowNotEinAlert
          },
          inputProps: {
            onChange: () => updateSchema()
          },
        },
        einNumberInput,
        {
          name: "beneficialOwnerEntityType",
          type: TYPE_RADIO,
          label: "Beneficial Owner Entity Type",
          required: true,
          options: Object.keys(ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_TITLES_MAP)
            .map(value => ({ value, label: ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_TITLES_MAP[value] })),
          inputProps: {
            onChange: () => updateSchema()
          },
        },
        {
          name: "beneficialOwnerName",
          label: LABEL_BENEFICIAL_OWNER_NAME,
          type: TYPE_STRING,
          itemProps: {
            extra: <Disclaimer text={beneficialOwnerTooltip} />
          },
          required: true,
        },
        ...llcInputs,
        addressInputs,
        isUsCitizenInput,
      ],
      ...stateInputs,
      ...[
        einInput,
        ssnInput,
        iraInputs,
      ],
      inputPropsMap.taxFilingStatus
    ]
  }

  if (isNotDisregarded) {
    profile.isUsCitizen = false
    const llcInputs = isLLC
      ? [
        {
          name: "taxFilingStatus",
          type: TYPE_RADIO,
          label: "Tax Filing Status",
          required: true,
          options: Object.keys(BENEFICIAL_OWNER_TAX_FILING_STATUS_TITLES_MAP)
            .map(value => ({ value, label: BENEFICIAL_OWNER_TAX_FILING_STATUS_TITLES_MAP[value] })),
        }
      ]
      : [
        inputPropsMap.taxFilingStatus,
      ]

    labels = {
      hasOtherUsResidenceState: "Is the state listed under the address also the entity's state of residence for tax purposes?",
      residenceState: "Please list the entity's state of residence",
      isUsPerson: "Is this entity considered a US person for federal tax purposes, as defined by the IRS?",
    }

    const isSetEntityType = entityType !== undefined
    const isExemptOrganization = entityType === ENTITY_TYPE_EXEMPT_ORGANIZATION

    const residencyInputs = computeResidencyInputs(profile, updateSchema, labels, organizationEmail, true)

    const einLabel = "Entity EIN"

    const shouldShowEinInput = profile.hasEinNumber === true
    const einNumberInput = shouldShowEinInput
      ? {
        name: "einNumber",
        type: TYPE_EIN,
        label: einLabel,
        required: true,
      }
      : inputPropsMap.einNumber

    const stateInputs = isSetEntityType
      ? [
        ...residencyInputs,
        inputPropsMap.hasSsn
      ]
      : [
        inputPropsMap.residenceState,
        inputPropsMap.hasOtherUsResidenceState,
        inputPropsMap.isUsPerson,
        inputPropsMap.hasSsn,
      ]

    const addressInputs = isSetEntityType
      ? {
        name: "address",
        label: "",
        schema: getUsAddressSchema(beneficialOwnerAddress),
        required: true,
      }
      : inputPropsMap.address

    const isRetirementPlanInput = isExemptOrganization
      ? {
        name: "isRetirementPlan",
        label: "Is this partner a retirement plan (IRA/SEP/Keogh/etc.)?",
        required: true,
      }
      : inputPropsMap.isRetirementPlan

    inputs = [
      ...commonInputs,
      ...defaultHiddenInputs,
      inputPropsMap.beneficialOwnerTaxFilingStatus,
      ...[{
        name: "entityType",
        type: TYPE_RADIO,
        label: "Entity Type",
        required: true,
        options: Object.keys(ENTITY_TYPE_TITLES_MAP)
          .map(value => ({ value, label: ENTITY_TYPE_TITLES_MAP[value] })),
        inputProps: {
          onChange: () => updateSchema()
        },
      }],
      ...llcInputs,
      ...[
        isRetirementPlanInput,
        addressInputs
      ],
      ...stateInputs,
      {
        name: "hasEinNumber",
        label: "Does this entity have an EIN?",
        required: true,
        itemProps: {
          extra: shouldShowNotEinAlert
        },
        inputProps: {
          onChange: () => updateSchema()
        },
      },
      ...[einNumberInput],
      inputPropsMap.beneficialOwnerCustodianEinNumber,
    ]
  }

  return inputs
}

export default getProfileEntityOtherSchema
