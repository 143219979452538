import PropTypes from "prop-types"

import { TransactionStatusEnumKeys } from "./shapes/TransactionStatusEnum"
import { TransactionTypeEnumKeys } from "./shapes/TransactionTypeEnum"

const properties = {
  amount: PropTypes.number.isRequired,
  brokerUserId: PropTypes.string.isRequired,
  correlationId: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  date: PropTypes.string.isRequired,
  description: PropTypes.string,
  details: PropTypes.string,
  id: PropTypes.string.isRequired,
  idempotencyCount: PropTypes.number,
  investmentId: PropTypes.string.isRequired,
  investmentName: PropTypes.string.isRequired,
  investorAccountId: PropTypes.string.isRequired,
  isApproved: PropTypes.bool.isRequired,
  isExternal: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  receiverSourceId: PropTypes.string,
  receiverSourceLastFour: PropTypes.string,
  receiverSourceName: PropTypes.string,
  referenceId: PropTypes.string,
  retryTransactionId: PropTypes.string,
  senderSourceId: PropTypes.string,
  senderSourceLastFour: PropTypes.string,
  senderSourceName: PropTypes.string,
  sponsorId: PropTypes.string.isRequired,
  status: PropTypes.oneOf(TransactionStatusEnumKeys).isRequired,
  statusReason: PropTypes.string,
  transferUrl: PropTypes.string,
  type: PropTypes.oneOf(TransactionTypeEnumKeys).isRequired,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const IndexTransactionsOutputShapeShape = PropTypes.exact(properties)

export default IndexTransactionsOutputShapeShape
export { properties }
