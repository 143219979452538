import { getMyMemberName } from "./getMyMemberName"
import { getProjectName } from "./getProjectName"
import { getProjectType } from "./getProjectType"
import { getProjectOpenDate } from "./getProjectOpenDate"
import { getPartnerOrganizationName } from "./getPartnerOrganizationName"
import { getMyInvestorName } from "./getMyInvestorName"
import { getInvestmentProfileType } from "./getInvestmentProfileType"
import { getInvestorName } from "./getInvestorName"
import { getCommitmentName } from "./getCommitmentName"
import { getCommitmentAmount } from "./getCommitmentAmount"
import { getMemberName } from "./getMemberName"
import { getTransactionsAmount } from "./getTransactionsAmount"
import { getTransactionProjectName } from "./getTransactionProjectName"
import { getInvestorEmail } from "./getInvestorEmail"
import { getInvestmentAmount } from "./getInvestmentAmount"
import { getSourceType } from "./getSourceType"
import { getFundName } from "./getFundName"
import { getPartnerTargetAmount } from "./getPartnerTargetAmount"
import { getMyInvestorMobileNumber } from "./getMyInvestorMobileNumber"

import {
  FUND_NAME,
  SOURCE_TYPE,
  MEMBER_NAME,
  PROJECT_TYPE,
  PROJECT_NAME,
  INVESTOR_NAME,
  INVESTOR_EMAIL,
  MY_MEMBER_NAME,
  COMMITMENT_NAME,
  MY_INVESTOR_NAME,
  COMMITMENT_AMOUNT,
  INVESTMENT_AMOUNT,
  PROJECT_OPEN_DATE,
  MY_INVESTOR_MOBILE,
  TRANSACTIONS_AMOUNT,
  PARTNER_TARGET_AMOUNT,
  INVESTMENT_PROFILE_TYPE,
  TRANSACTION_PROJECT_NAME,
  PARTNER_ORGANIZATION_NAME,
} from "./variables"

const MAP = {
  [MY_MEMBER_NAME]: getMyMemberName,
  [PROJECT_NAME]: getProjectName,
  [PROJECT_TYPE]: getProjectType,
  [PROJECT_OPEN_DATE]: getProjectOpenDate,
  [PARTNER_ORGANIZATION_NAME]: getPartnerOrganizationName,
  [MY_INVESTOR_NAME]: getMyInvestorName,
  [INVESTMENT_PROFILE_TYPE]: getInvestmentProfileType,
  [INVESTOR_NAME]: getInvestorName,
  [COMMITMENT_NAME]: getCommitmentName,
  [COMMITMENT_AMOUNT]: getCommitmentAmount,
  [MEMBER_NAME]: getMemberName,
  [TRANSACTIONS_AMOUNT]: getTransactionsAmount,
  [TRANSACTION_PROJECT_NAME]: getTransactionProjectName,
  [INVESTOR_EMAIL]: getInvestorEmail,
  [INVESTMENT_AMOUNT]: getInvestmentAmount,
  [SOURCE_TYPE]: getSourceType,
  [FUND_NAME]: getFundName,
  [PARTNER_TARGET_AMOUNT]: getPartnerTargetAmount,
  [MY_INVESTOR_MOBILE]: getMyInvestorMobileNumber,
}

export const KEYS = Object.keys(MAP)

export default MAP
