import React from "react"
import { NavLink } from "react-router-dom"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"

import { PROJECT_TYPES } from "@components/Domain"

const LABEL_EDIT = "Edit Details"
const LABEL_DELETE = "Delete"


const getRowMenu = ({
  projects,
  property,
  onDelete,
  getProject,
  openUpdateModal,
}) => {
  const { id, projectId } = property

  const items = [
    {
      key: "update",
      icon: <EditOutlined />,
      label: (
        <NavLink onClick={() => openUpdateModal(id)}>
          {LABEL_EDIT}
        </NavLink>
      )
    }
  ]

  const hasConnectedProject =
    !!projectId &&
    !!getProject(projectId)

  const fundProjects = projects
    .filter(({ type }) => type === PROJECT_TYPES.FUND)

  const connectedPropertyIds = fundProjects
    .reduce((acc, { investmentSubjectIds = [] }) => [...acc, ...investmentSubjectIds], [])

  const isConnectedToFundProject = connectedPropertyIds.includes(id)

  const isRemovable =
    !isConnectedToFundProject &&
    !hasConnectedProject

  if (!isRemovable) {
    return items
  }

  items.push(
    {
      type: "divider"
    },
    {
      key: "delete",
      icon: <DeleteOutlined />,
      danger: true,
      label: (
        <NavLink onClick={() => onDelete(id)}>
          {LABEL_DELETE}
        </NavLink>
      )
    }
  )

  return items
}

export default getRowMenu
