import { renderTemplate } from "@modules/backstage/activities/templates"


const getExportColumns = context => {
  const columns = [
    {
      title: "operationId",
      value: "operationId",
    },
    {
      title: "parametersJson",
      value: "parametersJson",
    },
    {
      title: "resultJson",
      value: "resultJson",
    },
    {
      title: "",
      value: ({ operationId, userId, parametersJson, resultJson }) =>
        renderTemplate(context, operationId, userId, parametersJson, resultJson)
    },
    {
      title: "userId",
      value: "userId",
    }, {
      title: "createdAt",
      value: "createdAt",
    }
  ]

  return columns
}

export default getExportColumns
