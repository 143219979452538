import keyBy from "lodash.keyby"

import {
  INVESTMENT_PROFILE_TYPE_JOINT,
  INVESTMENT_PROFILE_TYPE_SDIRA,
  INVESTMENT_PROFILE_TYPE_ENTITY,
  INVESTMENT_PROFILE_TYPE_INDIVIDUAL
} from "@components/Domain"

const PROFILE_TYPES = [
  INVESTMENT_PROFILE_TYPE_JOINT,
  INVESTMENT_PROFILE_TYPE_SDIRA,
  INVESTMENT_PROFILE_TYPE_ENTITY,
  INVESTMENT_PROFILE_TYPE_INDIVIDUAL
]


const getProfilesMap = (profiles = [], investments = []) => {
  const profileMap = {}

  const investmentsMapByName = keyBy(investments, "name")

  for (const profileType of PROFILE_TYPES) {
    profileMap[profileType] = profiles
      .filter(profile => profile.profileType === profileType)
      .map(profile => {
        const hasInvestment = investmentsMapByName[profile.name] !== undefined

        return {
          ...profile,
          disabled: hasInvestment,
        }
      })
  }

  return profileMap
}

export default getProfilesMap
