import React, { useCallback, useState } from "react"
import PropTypes from "prop-types"
import { Spin } from "antd"

import { wait } from "@api"
import { Drawer } from "@components/Page"
import { Markdown } from "@components/Text"
import { W0, HEADER_HEIGHT, DRAWER_WIDTH3 } from "@components"

import Title from "./Title"
import Investment from "./Investment"
import useInvestmentStore from "./stores/useInvestmentStore"

const LABEL_ERROR_NOT_FOUND = `Investment **{ID}** is not found. URL is not valid
or investment was deleted.`


const InvestmentDrawer = ({
  onClose = undefined,
  drawer,
}) => {
  const [ error, setError ] = useState()

  const onInvestmentNotFound = useCallback(id =>
    setError(LABEL_ERROR_NOT_FOUND.replace('{ID}', id))
  , [])

  const {
    account,
    isLoading,
    investment,
    setInvestment,
    readInvestment,
    updateInvestment,
    projectTemplatesStore
  } = useInvestmentStore(onInvestmentNotFound)

  const onOpen = id => readInvestment(id)

  const onDrawerClose = () => {
    setError()
    wait(300).then(() => setInvestment())

    if (onClose) {
      onClose()
    }
  }

  const isReady =
    investment !== undefined &&
    !isLoading

  const styles = error
    ? {}
    : {
        body: { paddingTop: W0 },
        header: { minHeight: HEADER_HEIGHT + 1 }
      }

  const title = <Title investment={investment} />

  const drawerProps = {
    width: DRAWER_WIDTH3,
    onClose: onDrawerClose,
    title,
    drawer,
    onOpen,
    styles,
  }

  const renderChildren = () => {
    if (error) {
      return (
        <center>
          <Markdown text={error} />
        </center>
      )
    }

    if (!isReady) {
      return <center><Spin /></center>
    }

    return (
      <Investment
        account={account}
        investment={investment}
        readInvestment={readInvestment}
        updateInvestment={updateInvestment}
        projectTemplatesStore={projectTemplatesStore}
      />
    )
  }

  return (
    <Drawer {...drawerProps}>
      {renderChildren()}
    </Drawer>
  )
}

InvestmentDrawer.propTypes = {
  drawer: PropTypes.shape().isRequired,
  onClose: PropTypes.func,
}

export default InvestmentDrawer
