import { getCurrencyValue } from "@components/Amount"


export const getCommitmentAmount = (context, createdBy, parameters) => {
  const { mutation } = parameters
  const { profiles = [] } = mutation

  let total = 0
  for (const profile of profiles) {
    const { investments } = profile
    total += investments.map(({ amount }) => amount)
  }

  return getCurrencyValue(total)
}
