
export const getTransactionProjectName = (context, createdBy, parameters, result) => {
  const { getProjectName } = context

  const { data: transactions } = result
  const [ transaction ] = transactions
  const { projectId } = transaction

  const projectName = getProjectName(projectId)

  return projectName || 'Undefined'
}
