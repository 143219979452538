import keyBy from "lodash.keyby"

import { getUsDateFromIsoDate } from "@components/Date"
import { getFormattedAmountValue } from "@components/Amount"
import { TRANSACTION_TYPES, getTransactionTypeLabel } from "@components/Domain"


const getAmountByType = (distribution, type) =>
  distribution[type]
    ? getFormattedAmountValue(distribution[type].amount)
    : 0


const getExportColumns = (project, accounts) => {
  const accountsMap = keyBy(accounts, "id")

  return [
    {
      title: "Project",
      value: () => project.name,
    },
    {
      title: "Date",
      value: ({ date }) => getUsDateFromIsoDate(date),
    },
    {
      title: "Beneficiary",
      value: ({ investmentName }) => investmentName,
    },
    {
      title: "Email",
      value: ({ investorAccountId }) => accountsMap[investorAccountId].investor.email
    },
    {
      title: getTransactionTypeLabel(TRANSACTION_TYPES.INVESTMENT_RETURN),
      value: distribution => getAmountByType(distribution, TRANSACTION_TYPES.INVESTMENT_RETURN),
    },
    {
      title: getTransactionTypeLabel(TRANSACTION_TYPES.SALE_PROFIT),
      value: distribution => getAmountByType(distribution, TRANSACTION_TYPES.SALE_PROFIT),
    },
    {
      title: getTransactionTypeLabel(TRANSACTION_TYPES.CAPITAL_RETURN),
      value: distribution => getAmountByType(distribution, TRANSACTION_TYPES.CAPITAL_RETURN),
    },
    {
      title: getTransactionTypeLabel(TRANSACTION_TYPES.SPONSOR_PROMOTE),
      value: distribution => getAmountByType(distribution, TRANSACTION_TYPES.SPONSOR_PROMOTE),
    },
    {
      title: "Status",
      value: "status",
    },
  ]
}

export default getExportColumns
