import {
  TYPE_NONE,
  TYPE_DATE,
  TYPE_PHONE,
  TYPE_STRING,
  TYPE_TIMEZONE,
  TYPE_CHECKBOX,
  STRING_LIST_TYPE
} from "@components/Form"
import { validateDob } from "@components/Date"
import { getAddressSchema } from "@components/Address"

const LABEL_TIMEZONE = "Time Zone"
const LABEL_REFERRED = "Referred From"
const LABEL_SUBSCRIBE = "Investor is subscribed to receive campaings"
const LABEL_ADD_EMAIL = "Add an email"

const ERROR_INVALID_EMAIL = "Invalid email address"


const getInputs = ({
  address,
  brokersMap,
  updateSchema,
  isEmailDisabled,
  hasAccreditation,
}) => {
  const brokerOptions = Object
    .values(brokersMap)
    .map(({ id: value, givenName, familyName }) =>
      ({ label: `${givenName} ${familyName}`, value })
    )

  const addressSchema = getAddressSchema(updateSchema, ["investor", "address"], address, {}, {}, false)

  const inputs = [
    {
      name: "status",
      type: TYPE_NONE,
    },
    {
      name: "statusReason",
      type: TYPE_NONE,
    },
    {
      name: "lastAuthenticatedAt",
      type: TYPE_NONE
    },
    {
      name: "referralSource",
      label: LABEL_REFERRED,
    },
    {
      name: "brokerUserId",
      type: TYPE_STRING,
      label: "Liaison",
      placeholder: "Select liaison",
      options: brokerOptions,
      required: false,
    },
    {
      name: "investor",
      label: "",
      schema: [
        {
          name: "givenName",
          label: "Legal First Name",
          required: true,
        },
        {
          name: "additionalName",
          label: "Legal Middle Name",
        },
        {
          name: "familyName",
          label: "Legal Last Name",
          required: true,
        },
        {
          name: "alternativeName",
          label: "Preferred Name",
        },
        {
          name: "email",
          label: "Email",
          required: false,
          inputProps: {
            disabled: isEmailDisabled
          },
        },
        {
          name: "alternativeEmail",
          required: false,
        },
        {
          name: "address",
          label: "",
          schema: addressSchema,
        },
        {
          label: LABEL_TIMEZONE,
          name: "timeZoneCode",
          type: TYPE_TIMEZONE,
          required: false,
        },
        {
          name: "mobileNumber",
          type: TYPE_PHONE,
          label: 'Contact Phone Number',
          required: false,
        },
        {
          name: "landNumber",
          type: TYPE_NONE,
        },
        {
          name: "dateOfBirth",
          label: "Date of Birth",
          required: false,
          itemProps: {
            rules: [validateDob()]
          }
        },
        {
          name: "hasAccreditation",
          label: "Is an accredited investor?",
          required: false,
          inputProps: {
            onChange: ({ target: { value } }, form) => {
              if (!value) {
                form.setFieldValue(['investor', 'accreditationExpiresAt'], null)
              }

              updateSchema()
            }
          }
        },
        {
          name: "accreditationExpiresAt",
          label: "Accreditation Expiration Date",
          required: false,
          type: hasAccreditation
            ? TYPE_DATE
            : TYPE_NONE,
        },
        {
          name: "campaignEmails",
          type: STRING_LIST_TYPE,
          placeholder: LABEL_ADD_EMAIL,
          itemProps: {
            rules: [{
              type: 'email',
              message: ERROR_INVALID_EMAIL,
            }]
          }
        },
      ]
    },
    {
      name: "isSubscribedForCampaigns",
      type: TYPE_CHECKBOX,
      label: "",
      required: false,
      inputProps: {
        label: LABEL_SUBSCRIBE
      }
    },
  ]

  return inputs
}

export default getInputs
