import React from "react"
import PropTypes from "prop-types"
import { Tag, Tooltip } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"

import { getConfig } from "@components/Config"

const PLATFORM_EMAIL = getConfig('PLATFORM_EMAIL')

const LABEL_FAILED = "Verification Failed"
const LABEL_TOOLTIP = `The number of invalid amount retries has been exceeded.
The bank account is blocked. Please contact ${PLATFORM_EMAIL} to resolve the issue.`


const VerificationFailedStatus = ({ bordered = false }) => {
  return (
    <Tooltip
      title={LABEL_TOOLTIP}
      placement="top"
    >
      <Tag
        icon={<ExclamationCircleOutlined />}
        color="red"
        style={{ cursor: "pointer" }}
        bordered={bordered}
      >
        {LABEL_FAILED}
      </Tag>
    </Tooltip>
  )
}

VerificationFailedStatus.propTypes = {
  bordered: PropTypes.bool,
}

export default VerificationFailedStatus
