import { useOutletContext } from "react-router-dom"

import { getUsDateFromIsoDate } from "@components/Date"
import { getFormattedAmountValue } from "@components/Amount"
import { TRANSACTION_TYPES, TRANSACTION_TYPE_LABEL_MAP } from "@components/Domain"

const LABEL_DATE = "Date"
const LABEL_TYPE = "Type"
const LABEL_STATUS = "Status"
const LABEL_PROJECT = "Project"
const LABEL_PROFILE = "Profile"
const LABEL_LIAISON = "Liaison"
const LABEL_SPONSOR = "Sponsor"
const LABEL_INVESTMENTS = "Investments"
const LABEL_DISTRIBUTIONS = "Distributions"


const getAmountByType = (type, amount, isIncomingColumn = true) => {
  const isFunding = type === TRANSACTION_TYPES.FUNDING

  const isIncomingAmount = !isFunding && isIncomingColumn
  const isOutgoingAmount = isFunding && !isIncomingColumn

  if (isIncomingAmount || isOutgoingAmount) {
   return 0
  }

  return getFormattedAmountValue(amount)
}


const useExportColumns = () => {
  const {
    getSponsorName,
    getProjectName,
    computeUserFullname
  } = useOutletContext()

  return [
    {
      title: LABEL_DATE,
      value: ({ date }) => getUsDateFromIsoDate(date),
    },
    {
      title: LABEL_PROJECT,
      value: ({ projectId }) => getProjectName(projectId),
    },
    {
      title: LABEL_PROFILE,
      value: ({ investmentName }) => investmentName,
    },
    {
      title: LABEL_LIAISON,
      value: ({ brokerUserId }) => computeUserFullname(brokerUserId),
    },
    {
      title: LABEL_SPONSOR,
      value: ({ sponsorId }) => getSponsorName(sponsorId),
    },
    {
      title: LABEL_INVESTMENTS,
      value: ({ type, amount }) => getAmountByType(type, amount),
    },
    {
      title: LABEL_DISTRIBUTIONS,
      value: ({ type, amount }) => getAmountByType(type, amount, false),
    },
    {
      title: LABEL_TYPE,
      value: ({ type }) => TRANSACTION_TYPE_LABEL_MAP[type],
    },
    {
      title: LABEL_STATUS,
      value: "status",
    },
  ]
}

export default useExportColumns
