import { useEffect } from "react"
import * as Sentry from "@sentry/react"
import { createRoutesFromChildren, useNavigationType, matchRoutes, useLocation } from "react-router-dom"

import { getConfig } from "@components/Config"
import { isBackstage, isLocal } from "@components"

const PLATFORM_VERSION = getConfig("PLATFORM_VERSION")
const SENTRY_ENVIRONMENT = getConfig("SENTRY_ENVIRONMENT")
const SENTRY_INVESTOR_DNS = getConfig("SENTRY_INVESTOR_DNS")
const SENTRY_BACKSTAGE_DNS = getConfig("SENTRY_BACKSTAGE_DNS")

const SENTRY_DNS = isBackstage
  ? SENTRY_BACKSTAGE_DNS
  : SENTRY_INVESTOR_DNS


const initializeSentry = () => {
  if (isLocal) {
    return
  }

  Sentry.init({
    dsn: SENTRY_DNS,
    environment: SENTRY_ENVIRONMENT,
    release: PLATFORM_VERSION,
    ignoreErrors: [
      'Failed to fetch',
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
      'Non-Error promise rejection captured with value: Object Not Found',
      'Non-Error promise rejection captured with value: The user is not authenticated',
      'Non-Error promise rejection captured with value: undefined',
    ],
    beforeSend(event) {
      if (event.message) {
        event.fingerprint = [event.message, event.message];
      }
      return event;
    },
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

export default initializeSentry
