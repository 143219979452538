import React from "react"
import { Typography } from "antd"
// import { useOutletContext } from "react-router-dom"

// import Identity from "@components/Identity"
import { Value } from "@components/Text"
import { UserGroupsColumn } from "@components/Column"

import TableRowActions from "../TableRowActions"
// import OrganizationEmail from "../OrganizationEmail"
// import OrganizationDomain from "../OrganizationDomain"
import OrganizationStatusTag from "../OrganizationStatusTag"


const useTableColumns = (table, openUpdateModal, openOrganizationDrawer) => {
  // const { users, computeUserFullname } = useOutletContext()

  // const getUserId = userCorrelationId => {
  //   const user = users
  //     .find(({ correlationId }) => correlationId === userCorrelationId)

  //   if (!user) {
  //     return null
  //   }

  //   return user.id
  // }

  // const computeFullname = userCorrelationId => {
  //   const userId = getUserId(userCorrelationId)

  //   return computeUserFullname(userId)
  // }

  const idColumnProps = {
    key: "id",
    title: "ID",
    isSearchable: true
  }

  const nameColumnProps = {
    key: "name",
    title: "Name",
    render: (value, organization) => (
      <Typography.Link
        strong
        onClick={() => openOrganizationDrawer(organization)}
      >
        {value}
      </Typography.Link>
    ),
    isSearchable: true
  }

  // const domainColumnProps = {
  //   key: "domain",
  //   render: (value, organization) => <OrganizationDomain
  //     organization={organization}
  //   />,
  //   isSearchable: true,
  // }

  // const emailColumnProps = {
  //   key: "email",
  //   title: "Email",
  //   render: (value, organization) => <OrganizationEmail
  //     organization={organization}
  //   />,
  // }

  const userGroupsColumnProps = {
    key: "userGroupIds",
    title: "Groups",
    render: (_, { userGroupIds = [] }) =>
      <UserGroupsColumn userGroupIds={userGroupIds}/>
  }

  // const createdByColumnProps = {
  //   key: "createdBy",
  //   compute: ({ createdBy }) => computeFullname(createdBy),
  //   render: (fullname, { createdBy }) => (
  //     getUserId(createdBy)
  //       ? <Identity id={getUserId(createdBy)}>{fullname}</Identity>
  //       : "N/A"
  //   ),
  //   isSearchable: true,
  // }

  const statusColumnProps = {
    key: "isDisabled",
    title: "Status",
    render: (_, { isDisabled, disableReason }) => <OrganizationStatusTag
      isDisabled={isDisabled}
      disableReason={disableReason}
    />,
  }

  const createdAtColumnProps = {
    key: "createdAt",
    render: value =>
      <Value value={value} />
  }

  const actionsColumnProps = {
    key: "actions",
    title: false,
    render: (_, organization) =>
      <TableRowActions
        table={table}
        organization={organization}
        openUpdateModal={openUpdateModal}
      />
  }

  return [
    { ...idColumnProps, width: 110 },
    { ...nameColumnProps },
    // { ...domainColumnProps },
    // { ...emailColumnProps },
    { ...userGroupsColumnProps },
    { ...statusColumnProps, width: 110 },
    // { ...createdByColumnProps, width: "auto", widthAutoPadding: 60 },
    { ...createdAtColumnProps, width: 170 },
    { ...actionsColumnProps, width: 48 }
  ]
}

export default useTableColumns
