import * as Sentry from "@sentry/react"

import { isBackstage } from "@components"


const setSentryUser = identity => {
  if (!identity) {
    Sentry.setUser()
    return
  }

  const {
    id,
    correlationId,
    organization: {
      id: organizationId,
      name: organizationName
    },
    roles
  } = identity

  let fullName
  let email

  if (isBackstage) {
    fullName = `${identity.givenName} ${identity.familyName}`
    email = identity.email

  } else {
    fullName = `${identity.investor.givenName} ${identity.investor.familyName}`
    email = identity.investor.email

  }

  const sentryUser = {
    id,
    correlationId,
    fullName,
    email,
    organizationId,
    organizationName,
    roles
  }

  // eslint-disable-next-line no-console
  console.info('setSentryUser', sentryUser)

  Sentry.setUser(sentryUser)
}

export default setSentryUser
