import { createContext, useContext } from "react"

import setTitle from "./setTitle"
import getThemeToken from "./getThemeToken"
import useAppMessage from "./useAppMessage"
import setApplicationTitle from "./setApplicationTitle"
import usePublicOrganization from "./usePublicOrganization"
import useRequest, { runRequest } from "./useRequest"
import AppContext, { useAppContext } from "./AppContext"

const PublicOrganizationContext = createContext({})
const usePublicOrganizationContext = () => useContext(PublicOrganizationContext)

export {
  setTitle,
  AppContext,
  useRequest,
  runRequest,
  useAppContext,
  useAppMessage,
  getThemeToken,
  setApplicationTitle,
  usePublicOrganization,
  PublicOrganizationContext,
  usePublicOrganizationContext
}

export * from "./Sentry"
