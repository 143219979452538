import { green, red, blue, orange } from "@ant-design/colors"

export const isMobile = window.innerWidth <= 820

export const BASE_SIZE = isMobile ? 8 : 16
export const CARD_PADDING = isMobile ? 12 :24

export const MODAL_WIDTH = 540
export const MODAL_WIDTH2 = 420
export const ALERT_HEIGHT = 38
export const DRAWER_WIDTH = 600
export const DRAWER_WIDTH2 = 720
export const DRAWER_WIDTH3 = 1024
export const HEADER_HEIGHT = 50
export const SIDEBAR_WIDTH = 280
export const EMAIL_DRAWER_WIDTH = DRAWER_WIDTH + 70
export const VIRTUAL_TABLE_HEIGHT = window.innerHeight - (HEADER_HEIGHT + 100)

export const W0 = BASE_SIZE / 2
export const W1 = BASE_SIZE
export const W2 = BASE_SIZE * 2
export const W3 = BASE_SIZE * 3
export const W4 = BASE_SIZE * 4
export const W5 = BASE_SIZE * 5

export const LOGO_HEIGHT = 48
export const WIDTH_MAX = 1600

export const INVESTOR_CARD_MIN_HEIGHT = "calc(100vh - 195px)"

export const WIDTHS = {
  WIDTH_1: `${BASE_SIZE}px`,
  WIDTH_2: `${BASE_SIZE * 2}px`,
  WIDTH_3: `${BASE_SIZE * 3}px`,
  WIDTH_4: `${BASE_SIZE * 4}px`,
  WIDTH_5: `${BASE_SIZE * 5}px`,
  W0,
  W1,
  W2,
  W3,
  W4,
  W5,
  MODAL_WIDTH,
  CARD_PADDING,
  SIDEBAR_WIDTH,
  HEADER_HEIGHT,
  CARD_MIN_HEIGHT: `calc(100vh - ${CARD_PADDING * 2}px)`,
  INVESTOR_CARD_MIN_HEIGHT,
}

export const MODAL_LARGE_WIDTH = `calc(100vw - ${WIDTHS.W2 * 2}px)`
export const MODAL_LARGE_HEIGHT = `calc(100vh - ${WIDTHS.W2 * 4.5}px)`

export const SMALL_FONT_SIZE = 12

export const GREEN_COLOR = green[0]
export const RED_COLOR = red[0]
export const BLUE_COLOR = blue[0]
export const ORANGE_COLOR = orange[0]

export const COLOR_MAP = {
  "red": RED_COLOR,
  "blue": BLUE_COLOR,
  "green": GREEN_COLOR,
  "orange": ORANGE_COLOR,
}

export const PATH_BACKSTAGE = "/backstage"

export const isBackstage = window.location.pathname.includes(PATH_BACKSTAGE)

export const LOCAL_DOMAIN = "qa.fundflow.slatestudio.com"
export const isLocal = window.location.host.startsWith('localhost')
export const DOMAIN = isLocal ? LOCAL_DOMAIN : window.location.host
export const HOST = `https://${DOMAIN}`

export const VIMEO_REGEX = /vimeo.*(?:\/|clip_id=)([0-9a-z]*)/
export const YOUTUBE_REGEX = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/
