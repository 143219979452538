import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import { Space, Button, Form, Input, Select } from "antd"

import { W0 } from "@components"
import { useOrganizations } from "@components/Organization"
import { useAppContext } from "@components/AppContext"

const LABEL_CODE = "Wire Reference Code"
const LABEL_CONTINUE = "Continue"


const GoToInvestmentForm = ({ onSuccess }) => {
  const navigate = useNavigate()
  const organizations = useOrganizations()
  const { getOrganizationId, switchToOrganization } = useAppContext()

  const currentOrganizationId = getOrganizationId()

  const [ isLoading, setIsLoading ] = useState(false)
  const [ isDisabled, setIsDisabled ] = useState(true)

  const [ projectId, setProjectId ] = useState("")
  const [ investmentId, setInvestmentId ] = useState("")
  const [ organizationId, setOrganizationId ] = useState("")

  const isCodeValid = useCallback(() => {
    if (!projectId) {
      return false
    }

    if (!investmentId) {
      return false
    }

    if (!organizationId) {
      return false
    }

    return true
  }, [
    projectId,
    investmentId,
    organizationId,
  ])

  useEffect(() => {
    const isValid = isCodeValid()

    if (!isValid) {
      setIsDisabled(true)
      return
    }

    setIsDisabled(false)
  }, [ isCodeValid ])

  const onSubmit = async () => {
    const investmentPath = `/backstage/project/${projectId}/investments/${investmentId}`
    const shouldSwitchOrganization = currentOrganizationId !== organizationId

    if (!shouldSwitchOrganization) {
      navigate('/backstage')
      navigate(investmentPath)
      onSuccess()
      return
    }

    setIsLoading(true)
    await switchToOrganization(organizationId, investmentPath)
    setIsLoading(false)
    onSuccess()
  }

  const formProps = {
    layout: "vertical"
  }

  const buttonProps = {
    size: "large",
    type: "primary",
    shape: "round",
    style: { width: "100%" },
    onClick: onSubmit,
    loading: isLoading,
    disabled: isDisabled
  }

  const organizationIds = organizations
    .map(organization => ({ value: organization.id }))

  return (
    <Form {...formProps}>
      <Form.Item label={LABEL_CODE} required={true} name="code">
        <Space>
          <Select
            size="large"
            style={{ width: 110 }}
            options={organizationIds}
            onChange={setOrganizationId}
            showSearch={true}
          />
          -
          <Input size="large" onChange={(event) => setProjectId(event.target.value)} />
          -
          <Input size="large" onChange={(event) => setInvestmentId(event.target.value)} />
        </Space>
      </Form.Item>

      <Form.Item style={{ marginBottom: W0 }}>
        <Button {...buttonProps}>
          {LABEL_CONTINUE}
        </Button>
      </Form.Item>
    </Form>
  )
}

GoToInvestmentForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
}

export default GoToInvestmentForm
