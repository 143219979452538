import { getInvestor } from "./getInvestor"


export const getInvestorName = (context, createdBy, parameters, result) => {
  const investor = getInvestor(context, createdBy, parameters, result)

  if (!investor) {
    return `Undefined`
  }

  const { givenName, familyName } = investor
  const fullName = `${givenName} ${familyName}`

  return fullName
}
