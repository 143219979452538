import { isJointInvestment, isEntityInvestment, isIndividualInvestment } from "@components/Investment"
import {
  ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_INDIVIDUAL,
  ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_IRA,
} from "@components/Domain"


const hasTin = investment => {
  const isJoint = isJointInvestment(investment)
  const isEntity = isEntityInvestment(investment)
  const isIndividual = isIndividualInvestment(investment)

  const {
    profileJoint = {},
    profileEntity = {},
    profileIndividual = {},
  } = investment

  if (isEntity) {
    const {
      einNumber,
      beneficialOwnerSsnNumber,
      beneficialOwnerEntityType,
      beneficialOwnerCustodianEinNumber,
      beneficialOwnerEinNumber,
      isDisregardedForTaxPurposes
    } = profileEntity

    const isIndividualType = beneficialOwnerEntityType === ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_INDIVIDUAL

    const isIra = beneficialOwnerEntityType === ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_IRA

    if (isDisregardedForTaxPurposes) {
      if (isIndividualType) {
        return !!einNumber && !!beneficialOwnerSsnNumber
      }

      if (isIra) {
        return !!einNumber && !!beneficialOwnerCustodianEinNumber
      }

      return !!einNumber && !!beneficialOwnerEinNumber
    }

    return !!einNumber
  }

  if (isIndividual) {
    const {
      ssnNumber: individualSsnNumber,
    } = profileIndividual

    return !!individualSsnNumber
  }

  if (isJoint) {
    const {
      ssnNumber: jointSsnNumber,
    } = profileJoint

    return !!jointSsnNumber
  }

  return false
}

export default hasTin
