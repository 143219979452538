import React from "react"
import { Tag } from "antd"
import PropTypes from "prop-types"
import capitalize from "lodash.capitalize"

import { TRANSACTION_STATUS } from "@components/Domain"


const getStatusColor = status => {
  return {
    [TRANSACTION_STATUS.ERROR]: "red",
    [TRANSACTION_STATUS.FAILED]: "red",
    [TRANSACTION_STATUS.PENDING]: "blue",
    [TRANSACTION_STATUS.REJECTED]: "red",
    [TRANSACTION_STATUS.PROCESSED]: "green",
    [TRANSACTION_STATUS.PENDING_APPROVAL]: "orange",
  }[status]
}


const TransactionStatusTag = ({ status }) => {
  const color = getStatusColor(status)
  const title = capitalize(status)

  return (
    <Tag
      color={color}
      bordered={false}
    >
      {title}
    </Tag>
  )
}

TransactionStatusTag.propTypes = {
  status: PropTypes.string.isRequired,
}

export default TransactionStatusTag
