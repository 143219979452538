import React, { useRef } from "react"
import { useOutletContext } from "react-router-dom"

import { W1 } from "@components"
import { Page } from "@components/Page"
import { useAppContext } from "@components/AppContext"

import OrganizationTable from "./OrganizationsTable"
import OrganizationDrawer from "./OrganizationDrawer"
import OrganizationCreateModal from "./OrganizationCreateModal"
import OrganizationUpdateModal from "./OrganizationUpdateModal"

const LABEL_TITLE = "Organizations"


const OrganizationsPage = () => {
  const { authorizeOrganizationIdentity } = useAppContext()
  const { addOrganization, updateOrganization } = useOutletContext()

  const table = useRef({})
  const drawer = useRef({})
  const createModal = useRef({})
  const updateModal = useRef({})

  const openOrganizationDrawer = organization =>
    drawer.current.open(organization)

  const onSearch = (value) =>
    table.current.search(value)

  const style = {
    padding: W1,
    paddingBottom: 0,
  }

  const openCreateModal = () =>
    createModal.current.open()

  const openUpdateModal = organization =>
    updateModal.current.openItem(organization)

  const onCreateSuccess = newOrganization => {
    addOrganization(newOrganization)
    return authorizeOrganizationIdentity()
  }

  const onUpdateSuccess = updatedOrganization => {
    updateOrganization(updatedOrganization)
    return authorizeOrganizationIdentity()
  }

  return (
    <Page
      style={style}
      title={LABEL_TITLE}
      onSearch={onSearch}
    >
      <OrganizationTable
        table={table}
        openCreateModal={openCreateModal}
        openUpdateModal={openUpdateModal}
        openOrganizationDrawer={openOrganizationDrawer}
      />

      <OrganizationCreateModal
        modal={createModal}
        table={table}
        onSuccess={onCreateSuccess}
      />

      <OrganizationUpdateModal
        modal={updateModal}
        table={table}
        onSuccess={onUpdateSuccess}
      />

      <OrganizationDrawer
        drawer={drawer}
      />
    </Page>
  )
}

export default OrganizationsPage
