import { isLocal } from "@components"

import getMethod from "./getMethod"
import getOperationId from "./getOperationId"
import getUrlQueryString from "./getUrlQueryString"
import RequestFailedError from "./RequestFailedError"
import UnexpectedReponseError from "./UnexpectedReponseError"


const jsonRequest = async (operationUrl, parameters = {}, headers = {}) => {
  const method = getMethod(operationUrl)
  const operationId = getOperationId(operationUrl)

  if (isLocal) {
    // eslint-disable-next-line no-console
    console.info(operationId, parameters)
  }

  const { mutation, ...query } = parameters

  const options = {
    headers: { ...headers, 'Content-Type': 'application/json' },
    method,
  }

  if (mutation) {
    options.body = JSON.stringify(mutation)
  }

  const hasQuery = Object.keys(query).length > 0

  if (hasQuery) {
    operationUrl += `?${getUrlQueryString(query)}`
  }

  const getSentryExtra = error => {
    const errorJson = JSON.stringify(error, null, 2)
    const mutationJson = options.body

    return {
      method,
      errorJson,
      operationId,
      mutationJson,
      operationUrl,
    }
  }

  let response

  try {
    response = await fetch(operationUrl, options)

  } catch (error) {
    const sentryExtra = getSentryExtra(error)
    throw new RequestFailedError(operationId, sentryExtra)

  }

  let data
  let error
  let pageInfo

  const hasBody = response.status !== 204

  if (hasBody) {
    const result = await response.json()

    data     = result.data
    error    = result.error
    pageInfo = result.pageInfo
  }

  if (!response.ok) {
    const sentryExtra = getSentryExtra(error || response)
    const err = new UnexpectedReponseError(operationId, sentryExtra)

    if (error) {
      err.code = error.code
      err.originalError = error
    }

    throw err
  }

  return { data, pageInfo, headers: response.headers }
}

export default jsonRequest
