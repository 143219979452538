import React, { useState } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"
import { List, Space, Typography, Switch } from "antd"

import { ListTemplatesOutputShape } from "@api/services/notifications"

import renderDescription from "./helpers/renderDescription"

const LABEL_ON = "On"
const LABEL_OFF = "Off"


const TemplateListItem = ({
  onClick,
  template,
  toggleTemplate,
}) => {
  const [ isLoading, setIsLoading ] = useState(false)

  const { indexProjectTemplates } = useOutletContext()

  const itemStyle = {
    paddingLeft: 4,
    paddingRight: 4
  }

  const style = {
    width: '100%',
    display: "flex",
    justifyContent: "space-between",
  }

  const { id, name, isEnabled } = template
  const description = renderDescription(template)

  const onChange = isEnabledValue => {
    setIsLoading(true)
    toggleTemplate(id, isEnabledValue)
      .then(() => indexProjectTemplates && indexProjectTemplates())
      .then(() => setIsLoading(false))
  }

  return (
    <List.Item style={itemStyle}>
      <Space direction="vertical" size={0} style={{ width: "100%" }}>
        <Space style={style}>
          <Typography.Link strong={true} onClick={onClick}>
            {name}
          </Typography.Link>

          <Switch
            loading={isLoading}
            onChange={onChange}
            checked={isEnabled}
            checkedChildren={LABEL_ON}
            unCheckedChildren={LABEL_OFF}
          />
        </Space>

        <div style={{ paddingRight: 70 }}>
          <Typography.Text type="secondary">
            {description}
          </Typography.Text>
        </div>
      </Space>
    </List.Item>
  )
}

TemplateListItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  template: ListTemplatesOutputShape.isRequired,
  toggleTemplate: PropTypes.func.isRequired,
}

export default TemplateListItem
