
const UNSUPPORTED_ERROR_CODE = `Transaction failed with the unknown error code.
Reach out to Fundamental support for instructions on how to proceed.`

const DISTRIBUTION_INSUFFICIENT_FUNDS_MESSAGE = `Due to the error, funds have
not been debited from the distribution account. If the issue has been resolved,
click "Retry" to resend the funds to the investor's bank account. Additionally,
investors will be notified via email that distributions are en route to their
linked bank accounts.`

const DISTRIBUTION_INVALID_INVESTOR_ACCOUNT_MESSAGE = `Funds have already been
debited from the sender's account and, due to the error on the investor's side,
settled in the Dwolla balance. Once the investor updates their account, the
transfer will be automatically retried.`

const DISTRIBUTION_INVALID_ORGANIZATION_ACCOUNT_MESSAGE = `Due to the error,
funds have not been debited from the distribution account. Please connect a
new valid distributions bank account. If the issue has been resolved, click
"Retry" to resend the funds to the investor's bank account. Additionally,
investors will be notified via email that distributions are en route to their
linked bank accounts.`

const FUNDING_INSUFFICIENT_FUNDS_MESSAGE = `Due to insufficient funds, the
amount has not been debited from the investor's account. Once the investor has
resolved the issue, they should click "Send Funds" to transfer the funds to the
[ORGANIZATION_NAME] bank account.`

const FUNDING_INVALID_INVESTOR_ACCOUNT_MESSAGE = `Due to the error, funds have
not been debited from the investor account. The issue lies on the investor's
side. Investor should connect a new valid funding bank account. Once the
investor has connected a new valid funding bank account, they should click
"Send Funds" for the funds to be transferred to the [ORGANIZATION_NAME] bank
account.`

const FUNDING_INVALID_ORGANIZATION_ACCOUNT_MESSAGE = `Funds have already been
debited from the investor's account and, due to the error on [ORGANIZATION_NAME]
side, funds were settled in the Dwolla balance. Please connect a new valid
funding bank account and click "Retry" for the funds to be transferred from the
Dwolla balance to the [ORGANIZATION_NAME] bank account.`

const INVALID_ACCOUNT_CODES = [ "R03" ]
const INSUFFICIENT_FUNDS_CODES = [ "R01", "R09" ]


const getMessage = (code, isFundingTransaction, isInvalidBankAccount) => {
  const isInsufficientFunds = INSUFFICIENT_FUNDS_CODES.includes(code)

  if (isInsufficientFunds) {
    if (isFundingTransaction) {
      return FUNDING_INSUFFICIENT_FUNDS_MESSAGE
    }

    return DISTRIBUTION_INSUFFICIENT_FUNDS_MESSAGE
  }

  const isInvalidAccount = INVALID_ACCOUNT_CODES.includes(code)

  if (!isInvalidAccount) {
    return UNSUPPORTED_ERROR_CODE
  }

  const isInvalidInvestorBankAccount = !isInvalidBankAccount

  if (isFundingTransaction) {
    if (isInvalidInvestorBankAccount) {
      return FUNDING_INVALID_INVESTOR_ACCOUNT_MESSAGE
    }

    return FUNDING_INVALID_ORGANIZATION_ACCOUNT_MESSAGE
  }

  if (isInvalidInvestorBankAccount) {
    return DISTRIBUTION_INVALID_INVESTOR_ACCOUNT_MESSAGE
  }

  return DISTRIBUTION_INVALID_ORGANIZATION_ACCOUNT_MESSAGE
}

const parseStatusReason = ({
  isTooltip = false,
  statusReason,
  organizationName,
  isFundingTransaction,
  isInvalidBankAccount,
}) => {
  if (!statusReason) {
    return ''
  }

  let parsedStatusReason = statusReason

  const statusIsJson = statusReason.startsWith("{\"")

  if (statusIsJson) {
    const { body, explanation, code } = JSON.parse(statusReason)

    if (explanation) {
      parsedStatusReason = `* ${code} - ${explanation}\n\n`
    }

    if (code) {
      const tooltipMessage =
        getMessage(code, isFundingTransaction, isInvalidBankAccount)
          .replaceAll('[ORGANIZATION_NAME]', organizationName)

      if (tooltipMessage) {
        parsedStatusReason += `* ${tooltipMessage}\n`

        return isTooltip ? tooltipMessage : parsedStatusReason
      }
    }

    const { message, _embedded } = body

    parsedStatusReason = `* ${message}\n`

    if (_embedded) {
      const { errors } = _embedded

      for (const { message: embeededError } of errors) {
        parsedStatusReason += `* ${embeededError}\n`
      }
    }
  }

  return parsedStatusReason
}

export default parseStatusReason
