import React from "react"
import { PlusOutlined } from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"

const LABEL_CREATE = "Add Organization"


const getHeaderActions = (openCreateModal) => {
  const canCreateFund = hasAccess(["partners-write"])

  if (!canCreateFund) {
    return []
  }

  return [
    {
      icon: <PlusOutlined />,
      action: openCreateModal,
      title: LABEL_CREATE,
    },
  ]
}

export default getHeaderActions
