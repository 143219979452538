import { getCurrencyValue } from "@components/Amount"


export const getTransactionsAmount = (context, createdBy, parameters, result) => {
  let total = 0
  const { data: transactions } = result
  for (const transaction of transactions) {
    total += transaction.amount
  }
  return getCurrencyValue(total)
}
