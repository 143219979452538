

export const getProjectName = (context, createdBy, parameters, result) => {
  const { getProjectName: getName } = context

  const { projectId, name } = result
  const { id } = parameters

  const projectName = getName(id) || getName(projectId)

  return projectName || name || 'Undefined'
}
