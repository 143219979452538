import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useParams, useOutletContext } from "react-router-dom"
import { Spin } from "antd"

import { Modal } from "@components/Page"
import { useForm } from "@components/Form"
import { useAppContext } from "@components/AppContext"
import { InvestmentForm } from "@components/Investment"
import { INVESTMENT_STATUS } from "@components/Domain"
import {
  createAccountInvestmentOperation as createOperation,
  indexAccountInvestmentsOperation as indexAccountInvestments,
} from "@api/services/investments"
import useAccountStore
  from "@components/Investor/InvestorDrawer/stores/useAccountStore"

import SelectInvestor from "./SelectInvestor"

const LABEL_ENTITY = "Investment"
const LABEL_TITLE = `Create ${LABEL_ENTITY}`
const LABEL_CREATE = "Create"
const LABEL_SUCCESS = `${LABEL_ENTITY} "$" is created`


const InvestmentCreateModal = ({ modal }) => {
  const form = useForm()

  const { id: projectId } = useParams()
  const { request, getOrganization, showSuccessMessage } = useAppContext()
  const { project, getAccount, addInvestment } = useOutletContext()

  const [ block, setBlock ] = useState()
  const [ isOpen, setIsOpen ] = useState(false)
  const [ accountId, setAccountId ] = useState()
  const [ isLoading, setIsLoading ] = useState(false)
  const [ investments, setInvestments ] = useState([])

  const account = getAccount(accountId)
  const { tiers = [] } = project
  const organization = getOrganization()

  const { readAccount } = useAccountStore(accountId)

  modal.current.open = () =>
    setIsOpen(true)

  const indexInvestments = (investorAccountId) =>
    request(indexAccountInvestments, { investorAccountId, status: INVESTMENT_STATUS.ACTIVE })
      .then(({ data }) => setInvestments(data))

  const onSelectInvestor = async (investorAccountId) => {
    form.resetFields()

    setIsLoading(true)

    await readAccount(investorAccountId)
    await indexInvestments(investorAccountId)

    setAccountId(investorAccountId)
    setIsLoading(false)
  }

  const onClose = () => {
    setIsOpen(false)

    setAccountId()
    setIsLoading(false)
    setInvestments([])

    form.resetFields()
  }

  const onAfterSubmit = createdItem => {
    addInvestment(createdItem)

    const { name: investmentName } = createdItem
    showSuccessMessage(LABEL_SUCCESS.replace("$", investmentName))

    return onClose()
  }

  const isTypeSelectDisabled = !accountId

  const submitButtonProps = useMemo(() => ({
    disabled: !accountId
  }), [ accountId ])

  const existingProfiles = useMemo(() =>
    account?.profiles || []
  , [ account ])

  const otherInvestments = useMemo(() =>
    investments.filter(investment => investment.projectId === projectId)
  , [ investments, projectId ])

  const investmentFormProps = {
    identity: account,
    submitLabel: LABEL_CREATE,
    form,
    tiers,
    block,
    setBlock,
    projectId,
    organization,
    onAfterSubmit,
    createOperation,
    otherInvestments,
    existingProfiles,
    submitButtonProps,
    isTypeSelectDisabled,
  }

  return (
    <Modal
      title={LABEL_TITLE}
      isOpen={isOpen}
      onClose={onClose}
      bodyStyle={{ minHeight: 326 }}
      className="investment-form"
    >
      <SelectInvestor
        value={accountId}
        onChange={onSelectInvestor}
      />

      <Spin spinning={isLoading}>
        <InvestmentForm
          key={accountId}
          {...investmentFormProps}
        />
      </Spin>
    </Modal>
  )
}

InvestmentCreateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
}

export default InvestmentCreateModal
