import React from "react"
import { Tag } from "antd"

import { TRANSACTION_STATUS } from "@components/Domain"

const TAG_COLORS_MAP = {
  [TRANSACTION_STATUS.ERROR]: "red",
  [TRANSACTION_STATUS.FAILED]: "red",
  [TRANSACTION_STATUS.RETRIED]: null,
  [TRANSACTION_STATUS.PENDING]: "blue",
  [TRANSACTION_STATUS.REJECTED]: "red",
  [TRANSACTION_STATUS.PROCESSED]: "green",
  [TRANSACTION_STATUS.PENDING_APPROVAL]: "orange",
}


const renderStatus = (label, { status, retryTransactionId }) => {
  let color = TAG_COLORS_MAP[status]

  const isRetried = !!retryTransactionId

  if (isRetried) {
    color = TAG_COLORS_MAP[TRANSACTION_STATUS.RETRIED]
  }

  return (
    <Tag
      color={color}
      bordered={false}
    >
      {label}
    </Tag>
  )
}

export default renderStatus
