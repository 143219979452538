import getOperation from "../../helpers/getOperation"

import CreateMyOrganizationEmailIdentityOutputShape from "./CreateMyOrganizationEmailIdentityOutputShape"
import CreateOrganizationOutputShape from "./CreateOrganizationOutputShape"
import CreatePartnerOrganizationOutputShape from "./CreatePartnerOrganizationOutputShape"
import DisableOrganizationOutputShape from "./DisableOrganizationOutputShape"
import EnableOrganizationOutputShape from "./EnableOrganizationOutputShape"
import IndexOrganizationsOutputShape from "./IndexOrganizationsOutputShape"
import IndexPartnerOrganizationsOutputShape from "./IndexPartnerOrganizationsOutputShape"
import ReadMyOrganizationOutputShape from "./ReadMyOrganizationOutputShape"
import ReadPublicOrganizationOutputShape from "./ReadPublicOrganizationOutputShape"
import ResetMyOrganizationEmailIdentityOutputShape from "./ResetMyOrganizationEmailIdentityOutputShape"
import SetMyOrganizationControllerOutputShape from "./SetMyOrganizationControllerOutputShape"
import UpdateMyOrganizationDomainStatusOutputShape from "./UpdateMyOrganizationDomainStatusOutputShape"
import UpdateMyOrganizationEmailVerificationStatusOutputShape from "./UpdateMyOrganizationEmailVerificationStatusOutputShape"
import UpdateMyOrganizationOutputShape from "./UpdateMyOrganizationOutputShape"
import UpdateOrganizationOutputShape from "./UpdateOrganizationOutputShape"
import VerifyMyOrganizationEmailIdentityOutputShape from "./VerifyMyOrganizationEmailIdentityOutputShape"

export {
  CreateMyOrganizationEmailIdentityOutputShape,
  CreateOrganizationOutputShape,
  CreatePartnerOrganizationOutputShape,
  DisableOrganizationOutputShape,
  EnableOrganizationOutputShape,
  IndexOrganizationsOutputShape,
  IndexPartnerOrganizationsOutputShape,
  ReadMyOrganizationOutputShape,
  ReadPublicOrganizationOutputShape,
  ResetMyOrganizationEmailIdentityOutputShape,
  SetMyOrganizationControllerOutputShape,
  UpdateMyOrganizationDomainStatusOutputShape,
  UpdateMyOrganizationEmailVerificationStatusOutputShape,
  UpdateMyOrganizationOutputShape,
  UpdateOrganizationOutputShape,
  VerifyMyOrganizationEmailIdentityOutputShape
}

export const createMyOrganizationEmailIdentityOperation = getOperation("organizations/CreateMyOrganizationEmailIdentity")
export const createOrganizationOperation = getOperation("organizations/CreateOrganization")
export const createPartnerOrganizationOperation = getOperation("organizations/CreatePartnerOrganization")
export const disableOrganizationOperation = getOperation("organizations/DisableOrganization")
export const enableOrganizationOperation = getOperation("organizations/EnableOrganization")
export const indexOrganizationsOperation = getOperation("organizations/IndexOrganizations")
export const indexPartnerOrganizationsOperation = getOperation("organizations/IndexPartnerOrganizations")
export const readMyOrganizationOperation = getOperation("organizations/ReadMyOrganization")
export const readPublicOrganizationOperation = getOperation("organizations/ReadPublicOrganization")
export const resetMyOrganizationEmailIdentityOperation = getOperation("organizations/ResetMyOrganizationEmailIdentity")
export const setMyOrganizationControllerOperation = getOperation("organizations/SetMyOrganizationController")
export const updateMyOrganizationOperation = getOperation("organizations/UpdateMyOrganization")
export const updateMyOrganizationDomainStatusOperation = getOperation("organizations/UpdateMyOrganizationDomainStatus")
export const updateMyOrganizationEmailVerificationStatusOperation = getOperation("organizations/UpdateMyOrganizationEmailVerificationStatus")
export const updateOrganizationOperation = getOperation("organizations/UpdateOrganization")
export const verifyMyOrganizationEmailIdentityOperation = getOperation("organizations/VerifyMyOrganizationEmailIdentity")
