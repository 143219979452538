import React from "react"
import PropTypes from "prop-types"
import { Menu } from "antd"
import { useNavigate } from "react-router-dom"

import { W0 } from "@components"
import { hasAccess } from "@components/Authorization"
import { useConfig } from "@components/Config"
import { ListProjectsOutputShape } from "@api/services/backstage"

import getMainMenu from "./helpers/getMainMenu"


const SidebarMenu = ({
  projects,
  openCreateProjectModal,
  failedTransactionsCount
}) => {
  const canReadCampaigns = hasAccess(["campaigns-read"])

  const navigate = useNavigate()
  const hasCampaigns = useConfig("hasCampaigns")

  const shouldRenderCampaigns =
    hasCampaigns &&
    canReadCampaigns

  const [ sidebarMenuItems, selectedKeys ] =
    getMainMenu(projects, openCreateProjectModal, failedTransactionsCount, shouldRenderCampaigns)

  return (
    <Menu
      style={{ border: "none" }}
      items={sidebarMenuItems}
      onClick={({ key }) => navigate(key)}
      selectedKeys={selectedKeys}
      inlineIndent={W0 + 4}
    />
  )
}

SidebarMenu.propTypes = {
  projects: PropTypes.arrayOf(ListProjectsOutputShape).isRequired,
  openCreateProjectModal: PropTypes.func.isRequired,
  failedTransactionsCount: PropTypes.number.isRequired,
}

export default SidebarMenu
