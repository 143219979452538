import getOperation from "../../helpers/getOperation"

import AcceptInvestmentOutputShape from "./AcceptInvestmentOutputShape"
import AcceptTermsOfServiceOutputShape from "./AcceptTermsOfServiceOutputShape"
import AuthorizeOutputShape from "./AuthorizeOutputShape"
import BackupInvestmentOutputShape from "./BackupInvestmentOutputShape"
import ConnectAccountIdentityOutputShape from "./ConnectAccountIdentityOutputShape"
import CreateAccountInvestmentOutputShape from "./CreateAccountInvestmentOutputShape"
import CreateAccountOutputShape from "./CreateAccountOutputShape"
import CreateCommitmentOutputShape from "./CreateCommitmentOutputShape"
import CreateCustodianOutputShape from "./CreateCustodianOutputShape"
import CreateInvestmentOutputShape from "./CreateInvestmentOutputShape"
import DisableAccountOutputShape from "./DisableAccountOutputShape"
import EnableAccountOutputShape from "./EnableAccountOutputShape"
import EnableMyAccount2FAOutputShape from "./EnableMyAccount2FAOutputShape"
import ImportAccountsOutputShape from "./ImportAccountsOutputShape"
import ImportInvestmentsOutputShape from "./ImportInvestmentsOutputShape"
import IndexAccountInvestmentsOutputShape from "./IndexAccountInvestmentsOutputShape"
import IndexAccountsOutputShape from "./IndexAccountsOutputShape"
import IndexCustodiansOutputShape from "./IndexCustodiansOutputShape"
import IndexInvestmentsOutputShape from "./IndexInvestmentsOutputShape"
import IndexMyInvestmentsOutputShape from "./IndexMyInvestmentsOutputShape"
import IndexProjectInvestmentsOutputShape from "./IndexProjectInvestmentsOutputShape"
import ListCustodiansOutputShape from "./ListCustodiansOutputShape"
import PostponeCommitmentOutputShape from "./PostponeCommitmentOutputShape"
import ReadAccountOutputShape from "./ReadAccountOutputShape"
import ReadCustodianOutputShape from "./ReadCustodianOutputShape"
import ReadInvestmentOutputShape from "./ReadInvestmentOutputShape"
import ReadMyAccountOutputShape from "./ReadMyAccountOutputShape"
import ReadMyInvestmentOutputShape from "./ReadMyInvestmentOutputShape"
import RejectInvestmentOutputShape from "./RejectInvestmentOutputShape"
import ResetAccountIdentityOutputShape from "./ResetAccountIdentityOutputShape"
import ResetInvestmentSubscriptionOutputShape from "./ResetInvestmentSubscriptionOutputShape"
import UpdateAccountNoteOutputShape from "./UpdateAccountNoteOutputShape"
import UpdateAccountOutputShape from "./UpdateAccountOutputShape"
import UpdateAccountTagsOutputShape from "./UpdateAccountTagsOutputShape"
import UpdateCustodianOutputShape from "./UpdateCustodianOutputShape"
import UpdateCustodianSourcesOutputShape from "./UpdateCustodianSourcesOutputShape"
import UpdateInvestmentNoteOutputShape from "./UpdateInvestmentNoteOutputShape"
import UpdateInvestmentOutputShape from "./UpdateInvestmentOutputShape"
import UpdateInvestmentSignedOutputShape from "./UpdateInvestmentSignedOutputShape"
import UpdateInvestmentSourcesOutputShape from "./UpdateInvestmentSourcesOutputShape"
import UpdateMyAccountOutputShape from "./UpdateMyAccountOutputShape"
import UpdateMyImmutableAccountInvestorOutputShape from "./UpdateMyImmutableAccountInvestorOutputShape"
import UpdateMyInvestmentFundingStatusOutputShape from "./UpdateMyInvestmentFundingStatusOutputShape"
import UpdateMyInvestmentOutputShape from "./UpdateMyInvestmentOutputShape"
import UpdateMyInvestmentSignUrlOutputShape from "./UpdateMyInvestmentSignUrlOutputShape"
import UpdateMyInvestmentSourcesOutputShape from "./UpdateMyInvestmentSourcesOutputShape"
import UpdateMyInvestmentSubscriptionOutputShape from "./UpdateMyInvestmentSubscriptionOutputShape"

export {
  AcceptInvestmentOutputShape,
  AcceptTermsOfServiceOutputShape,
  AuthorizeOutputShape,
  BackupInvestmentOutputShape,
  ConnectAccountIdentityOutputShape,
  CreateAccountInvestmentOutputShape,
  CreateAccountOutputShape,
  CreateCommitmentOutputShape,
  CreateCustodianOutputShape,
  CreateInvestmentOutputShape,
  DisableAccountOutputShape,
  EnableAccountOutputShape,
  EnableMyAccount2FAOutputShape,
  ImportAccountsOutputShape,
  ImportInvestmentsOutputShape,
  IndexAccountInvestmentsOutputShape,
  IndexAccountsOutputShape,
  IndexCustodiansOutputShape,
  IndexInvestmentsOutputShape,
  IndexMyInvestmentsOutputShape,
  IndexProjectInvestmentsOutputShape,
  ListCustodiansOutputShape,
  PostponeCommitmentOutputShape,
  ReadAccountOutputShape,
  ReadCustodianOutputShape,
  ReadInvestmentOutputShape,
  ReadMyAccountOutputShape,
  ReadMyInvestmentOutputShape,
  RejectInvestmentOutputShape,
  ResetAccountIdentityOutputShape,
  ResetInvestmentSubscriptionOutputShape,
  UpdateAccountNoteOutputShape,
  UpdateAccountOutputShape,
  UpdateAccountTagsOutputShape,
  UpdateCustodianOutputShape,
  UpdateCustodianSourcesOutputShape,
  UpdateInvestmentNoteOutputShape,
  UpdateInvestmentOutputShape,
  UpdateInvestmentSignedOutputShape,
  UpdateInvestmentSourcesOutputShape,
  UpdateMyAccountOutputShape,
  UpdateMyImmutableAccountInvestorOutputShape,
  UpdateMyInvestmentFundingStatusOutputShape,
  UpdateMyInvestmentOutputShape,
  UpdateMyInvestmentSignUrlOutputShape,
  UpdateMyInvestmentSourcesOutputShape,
  UpdateMyInvestmentSubscriptionOutputShape
}

export const acceptInvestmentOperation = getOperation("investments/AcceptInvestment")
export const acceptTermsOfServiceOperation = getOperation("investments/AcceptTermsOfService")
export const authorizeOperation = getOperation("investments/Authorize")
export const backupInvestmentOperation = getOperation("investments/BackupInvestment")
export const connectAccountIdentityOperation = getOperation("investments/ConnectAccountIdentity")
export const createAccountOperation = getOperation("investments/CreateAccount")
export const createAccountInvestmentOperation = getOperation("investments/CreateAccountInvestment")
export const createCommitmentOperation = getOperation("investments/CreateCommitment")
export const createCustodianOperation = getOperation("investments/CreateCustodian")
export const createInvestmentOperation = getOperation("investments/CreateInvestment")
export const deleteCustodianOperation = getOperation("investments/DeleteCustodian")
export const deleteInvestmentOperation = getOperation("investments/DeleteInvestment")
export const deleteMyInvestmentOperation = getOperation("investments/DeleteMyInvestment")
export const disableAccountOperation = getOperation("investments/DisableAccount")
export const disableAccount2FAOperation = getOperation("investments/DisableAccount2FA")
export const disableMyAccount2FAOperation = getOperation("investments/DisableMyAccount2FA")
export const enableAccountOperation = getOperation("investments/EnableAccount")
export const enableAccount2FAOperation = getOperation("investments/EnableAccount2FA")
export const enableMyAccount2FAOperation = getOperation("investments/EnableMyAccount2FA")
export const importAccountsOperation = getOperation("investments/ImportAccounts")
export const importInvestmentsOperation = getOperation("investments/ImportInvestments")
export const indexAccountInvestmentsOperation = getOperation("investments/IndexAccountInvestments")
export const indexAccountsOperation = getOperation("investments/IndexAccounts")
export const indexCustodiansOperation = getOperation("investments/IndexCustodians")
export const indexInvestmentsOperation = getOperation("investments/IndexInvestments")
export const indexMyInvestmentsOperation = getOperation("investments/IndexMyInvestments")
export const indexProjectInvestmentsOperation = getOperation("investments/IndexProjectInvestments")
export const listCustodiansOperation = getOperation("investments/ListCustodians")
export const postponeCommitmentOperation = getOperation("investments/PostponeCommitment")
export const readAccountOperation = getOperation("investments/ReadAccount")
export const readCustodianOperation = getOperation("investments/ReadCustodian")
export const readInvestmentOperation = getOperation("investments/ReadInvestment")
export const readMyAccountOperation = getOperation("investments/ReadMyAccount")
export const readMyInvestmentOperation = getOperation("investments/ReadMyInvestment")
export const rejectInvestmentOperation = getOperation("investments/RejectInvestment")
export const resetAccountIdentityOperation = getOperation("investments/ResetAccountIdentity")
export const resetInvestmentSubscriptionOperation = getOperation("investments/ResetInvestmentSubscription")
export const updateAccountOperation = getOperation("investments/UpdateAccount")
export const updateAccountNoteOperation = getOperation("investments/UpdateAccountNote")
export const updateAccountTagsOperation = getOperation("investments/UpdateAccountTags")
export const updateCustodianOperation = getOperation("investments/UpdateCustodian")
export const updateCustodianSourcesOperation = getOperation("investments/UpdateCustodianSources")
export const updateInvestmentOperation = getOperation("investments/UpdateInvestment")
export const updateInvestmentNoteOperation = getOperation("investments/UpdateInvestmentNote")
export const updateInvestmentSignedOperation = getOperation("investments/UpdateInvestmentSigned")
export const updateInvestmentSourcesOperation = getOperation("investments/UpdateInvestmentSources")
export const updateMyAccountOperation = getOperation("investments/UpdateMyAccount")
export const updateMyImmutableAccountInvestorOperation = getOperation("investments/UpdateMyImmutableAccountInvestor")
export const updateMyInvestmentOperation = getOperation("investments/UpdateMyInvestment")
export const updateMyInvestmentFundingStatusOperation = getOperation("investments/UpdateMyInvestmentFundingStatus")
export const updateMyInvestmentSignUrlOperation = getOperation("investments/UpdateMyInvestmentSignUrl")
export const updateMyInvestmentSourcesOperation = getOperation("investments/UpdateMyInvestmentSources")
export const updateMyInvestmentSubscriptionOperation = getOperation("investments/UpdateMyInvestmentSubscription")
