import React from "react"
import { useOutletContext } from "react-router-dom"
import { Form } from "antd"

import {
  TYPE_NONE,
  TYPE_RADIO,
  TYPE_HIDDEN,
  TYPE_DATETIME,
  TYPE_CHECKBOX,
} from "@components/Form"
import { W0, W1, W2 } from "@components"
import { OptionsDropdown } from "@components/Button"
import { SUBSCRIPTION_TYPE } from "@components/Store/BackstageStore/useOptionsStore"

import CampaignToInput from "@modules/backstage/campaigns/CampaignUpdatePage/CampaignSettingsUpdateForm/CampaignToInput"
import CampaignFromInput from "@modules/backstage/campaigns/CampaignUpdatePage/CampaignSettingsUpdateForm/CampaignFromInput"
import CampaignPublishButton from "@modules/backstage/campaigns/CampaignUpdatePage/CampaignSettingsUpdateForm/CampaignPublishButton"
import CampaignPreviewButton from "@modules/backstage/campaigns/CampaignUpdatePage/CampaignSettingsUpdateForm/CampaignPreviewButton"

import { KEY_SETTINGS } from "./keys"
import validateScheduledAt from "./validateScheduledAt"
import isDisabledScheduledAt from "./isDisabledScheduledAt"

const LABEL_SEND = "Send"
const LABEL_NAME = "Internal Name"
const LABEL_SUBJECT = "Subject"
const LABEL_SCHEDULED = "Scheduled"
const PUBLISHED_STATUS = "PUBLISHED"
const LABEL_IMMEDIATELY = "Immediately"
const LABEL_SUBSCRIPTION_TYPE = "Subscription Type"
const LABEL_INVESTORS_TIMEZONE = "Send on investors time zone if available"
const ERROR_INVALID_SCHEDULED_AT = "Value to be no less then 1 hour from now"
const LABEL_SUBSCRIPTION_TYPE_PLACEHOLDER = "Select subscription type"


const useInputs = (form, tabId, campaign, saveCampaign, uploadAssets, onActionSuccess, openPreviewModal) => {
  const { subscriptionTypeOptions } = useOutletContext()

  const { name: defaultSubject, status } = campaign

  const shouldSendImmediatelyValue = Form.useWatch("shouldSendImmediately", form)

  const isSettings = tabId === KEY_SETTINGS
  const isPublished = status === PUBLISHED_STATUS

  const isDisabled = isPublished

  const shouldHideScheduledAt = !isSettings || (isSettings && shouldSendImmediatelyValue)

  const commonInputProps = {
    size: "large",
    variant: "filled",
    disabled: isDisabled,
  }

  const segmentIdsProps = {
    component: CampaignToInput,
    componentProps: { isDisabled },
  }

  const nameProps = {
    label: LABEL_NAME,
    inputProps: {
      ...commonInputProps,
    }
  }

  const shouldSendImmediatelyProps = {
    type: TYPE_RADIO,
    options: [
      { value: true, label: LABEL_IMMEDIATELY },
      { value: false, label: LABEL_SCHEDULED }
    ],
    itemProps: {
      style: { marginBottom: shouldHideScheduledAt ? W1 : W0 },
    },
    inputProps: {
      direction: "horizontal"
    },
    required: false,
    initialValue: true,
  }

  const scheduledAtProps = {
    type: TYPE_DATETIME,
    label: "",
    itemProps: {
      rules: [
        {
          message: ERROR_INVALID_SCHEDULED_AT,
          validator: async () => {
            const { scheduledAt } = form.getFieldsValue()

            if (!scheduledAt) {
              return
            }

            const isValid = validateScheduledAt(scheduledAt)

            if (!isValid) {
              throw new Error("Invalid scheduledAt")
            }
          }
        }
      ],
      style: { marginBottom: W0 }
    },
    inputProps: {
      minuteStep: 15,
      showNow: false,
      disabledDate: date => isDisabledScheduledAt(date, form),
      disabledTime: date => isDisabledScheduledAt(date, form, true),
      ...commonInputProps,
    }
  }

  const shouldUseRecipientTimeZoneProps = {
    type: TYPE_CHECKBOX,
    label: "",
    inputProps: {
      label: LABEL_INVESTORS_TIMEZONE,
      disabled: isDisabled,
    }
  }

  const subjectProps = {
    label: LABEL_SUBJECT,
    initialValue: defaultSubject,
    inputProps: {
      ...commonInputProps,
    }
  }

  const subscriptionTypeIdProps = {
    label: LABEL_SUBSCRIPTION_TYPE,
    options: subscriptionTypeOptions,
    placeholder: LABEL_SUBSCRIPTION_TYPE_PLACEHOLDER,
    inputProps: {
      ...commonInputProps,
      dropdownRender: (menu) =>
        <OptionsDropdown
          menu={menu}
          group={SUBSCRIPTION_TYPE}
        />,
    }
  }

  const inputs = [
    {
      name: "tagIds",
      type: TYPE_NONE,
    },
    {
      name: "applicationId",
      type: TYPE_NONE,
    },
    {
      name: "segmentId",
      type: TYPE_NONE,
    },
    {
      name: "pinpointCampaignId",
      type: TYPE_NONE,
    },
    {
      name: "status",
      type: TYPE_NONE,
    },
    {
      name: "source",
      type: TYPE_NONE,
    },
    {
      name: "html",
      type: TYPE_HIDDEN,
    },
    {
      name: "assets",
      type: TYPE_NONE,
    },
    {
      name: "subject",
      ...subjectProps,
    },
    {
      name: "subscriptionTypeId",
      ...subscriptionTypeIdProps,
    },
    {
      name: "fromAddress",
      type: TYPE_HIDDEN,
    },
    {
      name: "fromName",
      type: TYPE_HIDDEN,
    },
    {
      name: "replyToAddress",
      component: CampaignFromInput,
      componentProps: { isDisabled, saveCampaign },
    },
    {
      name: "segmentIds",
      ...segmentIdsProps,
    },
    {
      name: "recipientAccountIds",
      type: TYPE_HIDDEN,
      required: false,
    },
    {
      name: "shouldSendImmediately",
      label: LABEL_SEND,
      ...shouldSendImmediatelyProps,
    },
    {
      name: "scheduledAt",
      ...scheduledAtProps,
    },
    {
      name: "shouldUseRecipientTimeZone",
      ...shouldUseRecipientTimeZoneProps,
    },
    {
      name: "name",
      ...nameProps,
    },
  ]

  inputs.push({
    name: "_",
    component: () =>
      <div style={{ marginTop: W2 }}>
        <CampaignPreviewButton
          saveCampaign={saveCampaign}
          openPreviewModal={openPreviewModal}
        />
      </div>
  })

  inputs.push({
    name: "_publish",
    component: () =>
      <div style={{ marginTop: W1 }}>
        <CampaignPublishButton
          form={form}
          campaign={campaign}
          onSuccess={onActionSuccess}
          saveCampaign={saveCampaign}
        />
      </div>
  })


  return inputs
}

export default useInputs
