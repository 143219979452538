import PropTypes from "prop-types"

import AddressShape from "./shapes/AddressShape"
import { BeneficialOwnerVerificationStatusEnumKeys } from "./shapes/BeneficialOwnerVerificationStatusEnum"

const properties = {
  address: AddressShape.isRequired,
  beneficialOwnerUrl: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  dateOfBirth: PropTypes.string,
  firstName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  identityVerificationFailedReasons: PropTypes.arrayOf(PropTypes.string),
  lastName: PropTypes.string.isRequired,
  passportNumber: PropTypes.string,
  ssn: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string,
  verificationStatus: PropTypes.oneOf(BeneficialOwnerVerificationStatusEnumKeys)
}

const ListMyCustomerBeneficialOwnersOutputShapeShape = PropTypes.exact(properties)

export default ListMyCustomerBeneficialOwnersOutputShapeShape
export { properties }
