import React from "react"
import { useOutletContext } from "react-router-dom"
import { App, Input, Space, Typography } from "antd"

import { confirmAction } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import {
  enableOrganizationOperation,
  disableOrganizationOperation
} from "@api/services/organizations"

const { Text } = Typography
const { TextArea } = Input

const ENABLE = "ENABLE"
const DISABLE = "DISABLE"

const LABEL_ENTITY = "Organization"

let disableReason = ""
const onChange = ({ currentTarget }) => {
  disableReason = currentTarget.value
}


const useActions = (organization, table) => {
  const { modal } = App.useApp()
  const { updateUser } = useOutletContext()
  const { authenticatedRequest, showSuccessMessage, authorizeOrganizationIdentity } = useAppContext()

  const { id } = organization

  const ACTIONS_MAP = {
    [DISABLE]: {
      operation: disableOrganizationOperation,
      type: "danger",
      title: `Disable ${LABEL_ENTITY}`,
      action: "Disable",
      success: `${LABEL_ENTITY} is disabled`,
      content: (
        <Space
          direction="vertical"
          style={{ width: "100%"}}
        >
          <Text>{`${LABEL_ENTITY} becomes disabled. Its users won't be able to access the portal.`}</Text>
          <TextArea
            rows={4}
            onChange={onChange}
            placeholder="Specify the reason..."
            maxLength={128}
          />
        </Space>
      ),
    },
    [ENABLE]: {
      operation: enableOrganizationOperation,
      type: "default",
      title: `Enable ${LABEL_ENTITY}`,
      action: "Enable",
      success: `${LABEL_ENTITY} is enabled`,
      content: `${LABEL_ENTITY} becomes enabled. It's users could access the portal again.`,
    },
  }

  const action = actionName =>
    confirmAction(
      modal,
      ACTIONS_MAP[actionName].action,
      ACTIONS_MAP[actionName].title,
      ACTIONS_MAP[actionName].content,
      async () => {
        const { operation } = ACTIONS_MAP[actionName]
        const parameters = { id }

        const isDisable = actionName === DISABLE

        if (isDisable) {
          parameters.mutation = { disableReason }
        }

        return authenticatedRequest(operation, parameters)
          .then(({ data }) => updateUser(data))
          .then(() => disableReason = "")
          .then(() => {
            showSuccessMessage(ACTIONS_MAP[actionName].success)
          })
          .then(() => authorizeOrganizationIdentity())
          .then(() => table.current.indexItems())
      },
      ACTIONS_MAP[actionName].type,
    )

  const enableOrganization = () => action(ENABLE)
  const disableOrganization = () => action(DISABLE)

  return {
    enableOrganization,
    disableOrganization,
  }
}

export default useActions
