import React from "react"
import keyBy from "lodash.keyby"

import { Disclaimer } from "@components/Text"
import { US_STATE_OPTIONS } from "@components/Address"
import {
  INVESTMENT_PROFILE_FIELD_MAP,
  INVESTMENT_PROFILE_TYPE_SDIRA
} from "@components/Domain"
import {
  TYPE_EIN,
  TYPE_ZIP,
  TYPE_HIDDEN,
  TYPE_STRING,
  TYPE_BOOLEAN,
} from "@components/Form"
import { listCustodiansOperation } from "@api/services/investments"

import computeProfileInput from "./computeProfileInput"
import computeLiabilityInputs from "./computeLiabilityInputs"
import getProfileSdiraInputsMap from "./getProfileSdiraInputsMap"

const LABEL_INVALID_PROFILE_TYPE = 'Invalid investment profile type selected'

const MESSAGE_ALERT = `
  If your SDIRA is a disregarded entity (such as a single‐member LLC), please
  return to the top and select "Entity or Trust" as your title of membership
  interest, list your disregarded entity, and add your SDIRA as the beneficial
  owner.
`

const TOOLTIP_ACCOUNT_NAME = `
  Information entered in this field will be used as reference when your
  distribution is sent electronically via ACH to your custodian. Please state
  your custodian's name, your name, and your account number in this format:
  Custodian Name FBO Your Name Account Number (e.g., "IRA Financial FBO John
  Doe 198204")
`

const ERROR_VALIDATION_ACCOUNT_NAME_MESSAGE = "Should not exceed 80 characters"

const CUSTODIAN_OTHER_ID = "OTHER"
const CUSTODIAN_OTHER_LABEL = "Other"

const CUSTODIANS_EXTRA_OPTIONS = [
  {
    id: CUSTODIAN_OTHER_ID,
    name: CUSTODIAN_OTHER_LABEL,
  }
]


const getProfileSdiraSchema = (organization, updateSchema, isEdit, profile = {}, existingProfiles = []) => {
  const {
    custodianId: profileCustodianId,
    profileName,
    accountName,
    shouldCustodianSign,
    isLiabilityAccepted,
    hasPurchasedExemptSecurities,
  } = profile

  const inputPropsMap = getProfileSdiraInputsMap()

  const isCustodianOtherSelected = profileCustodianId === CUSTODIAN_OTHER_ID

  const liabilityInputs = computeLiabilityInputs(updateSchema, organization)
  const profileSelectInput = computeProfileInput(INVESTMENT_PROFILE_TYPE_SDIRA, updateSchema, existingProfiles, () => {})

  const hasProfiles = existingProfiles
    .filter(({ disabled }) => !disabled).length > 0

  const shouldHideAnotherFields = hasProfiles && !profileName && !isEdit

  if (shouldHideAnotherFields) {
    return [
      ...profileSelectInput,
      inputPropsMap.isLiabilityAccepted,
      inputPropsMap.hasPurchasedExemptSecurities,
      inputPropsMap.membershipInterestsTitle,
      inputPropsMap.shouldCustodianSign,
      inputPropsMap.custodianId,
      inputPropsMap.accountName,
      inputPropsMap.custodianName,
      inputPropsMap.custodianAddress,
      inputPropsMap.custodianEinNumber,
    ]
  }

  const isDisabledFields = !isCustodianOtherSelected

  const inputs = [
    ...profileSelectInput,
    ...liabilityInputs,
    {
      name: "shouldCustodianSign",
      type: TYPE_BOOLEAN,
      label: "Is your SDIRA custodian required to review your investment or sign?",
      required: true,
      itemProps: {
        rules: [
          {
            message: LABEL_INVALID_PROFILE_TYPE,
            type: "enum",
            enum: [true]
          }
        ]
      },
      inputProps: {
        vertical: true,
        onChange: (event, form) => {
          const { profileType } = form.getFieldsValue()
          const path = [ INVESTMENT_PROFILE_FIELD_MAP[profileType], "shouldCustodianSign" ]

          form.setFields([
            {
              name: path,
              errors: [],
            },
          ])

          const { value: isInvestedViaDisregardedEntity } = event.target

          if (!isInvestedViaDisregardedEntity) {
            return updateSchema(MESSAGE_ALERT, "shouldCustodianSign")
          }

          return updateSchema()
        }
      }
    },
    {
      name: "custodianId",
      label: "Custodian",
      operation: listCustodiansOperation,
      extraOptions: CUSTODIANS_EXTRA_OPTIONS,
      required: true,
      placeholder: "Select custodian",
      inputProps: {
        onChange: (id, form, options) => {
          if (!id) {
            form.resetFields([ 'profileSdira' ])
            form.setFieldsValue({ profileSdira: {
                profileName,
                accountName,
                shouldCustodianSign,
                isLiabilityAccepted,
                hasPurchasedExemptSecurities,
              } })

            return updateSchema()
          }

          const custodianMaps = keyBy([
            ...options, { id: CUSTODIAN_OTHER_ID, name: CUSTODIAN_OTHER_LABEL }
          ], "id")

          const custodian = custodianMaps[id]

          const {
            id: custodianId,
            name,
            address,
            einNumber,
          } = custodian

          const isOtherSelected = custodianId === CUSTODIAN_OTHER_ID

          if (isOtherSelected) {
            form.resetFields([ 'profileSdira' ])
            form.setFieldsValue({ profileSdira: {
              custodianId,
              profileName,
              shouldCustodianSign,
              isLiabilityAccepted,
              hasPurchasedExemptSecurities,
            } })

            return updateSchema()
          }

          const profileSdira = {
            custodianId,
            custodianName: name,
            custodianEinNumber: einNumber,
            custodianAddress: address
          }

          form.setFieldsValue({ profileSdira })

          return updateSchema()
        }
      }
    },
    {
      name: "custodianName",
      type: isCustodianOtherSelected ? TYPE_STRING : TYPE_HIDDEN,
      placeholder: "IRA Financial",
    },
    {
      name: "accountName",
      label: "SDIRA Account Name and Account Number",
      required: true,
      placeholder: "IRA Financial FBO John Doe 123456",
      itemProps: {
        extra: <Disclaimer text={TOOLTIP_ACCOUNT_NAME} />,
        rules: [
          {
            max: 80,
            message: ERROR_VALIDATION_ACCOUNT_NAME_MESSAGE
          }
        ]
      },
      inputProps: {
        count: {
          show: true,
          max: 80,
        }
      }
    },
    {
      name: "custodianAddress",
      label: "",
      schema: [
        {
          name: "addressCountry",
          type: TYPE_HIDDEN,
        },
        {
          name: "streetAddress",
          label: "Street Address",
          placeholder: "Street address, apt, suite, unit, building, floor, etc",
          required: true,
          inputProps: {
            disabled: isDisabledFields
          },
        },
        {
          name: "addressLocality",
          label: "City",
          required: true,
          inputProps: {
            disabled: isDisabledFields
          },
          placeholder: "",
        },
        {
          name: "addressRegion",
          label: "State",
          required: true,
          options: US_STATE_OPTIONS,
          inputProps: {
            disabled: isDisabledFields,
            style: { width: 170 }
          },
          placeholder: "",
        },
        {
          name: "postalCode",
          label: "ZIP Code",
          type: TYPE_ZIP,
          required: true,
          inputProps: {
            disabled: isDisabledFields,
            style: { width: 170 }
          },
          placeholder: "",
        },
      ]
    },
    {
      name: "custodianEinNumber",
      type: TYPE_EIN,
      label: "Custodian's EIN",
      required: true,
      inputProps: {
        disabled: isDisabledFields
      },
      placeholder: "",
    }
  ]

  return inputs
}

export default getProfileSdiraSchema
