import React, { useState } from "react"
import PropTypes from "prop-types"
import { Tag } from "antd"

import { Drawer } from "@components/Page"


const OrganizationDrawer = ({ drawer }) => {
  const [ organization, setOrganization ] = useState()

  const onClose = () =>
    setOrganization()

  const onOpen = (targetOrganization) =>
    setOrganization(targetOrganization)

  const extra = (
    <Tag strong bordered={false} style={{ fontWeight: 600 }}>
      {organization?.id}
    </Tag>
  )

  const drawerProps = {
    title: organization?.name,
    extra,
    drawer,
    onOpen,
    onClose,
  }

  return (
    <Drawer {...drawerProps}>
      {JSON.stringify(organization)}
    </Drawer>
  )
}

OrganizationDrawer.propTypes = {
  drawer: PropTypes.shape({}).isRequired,
}

export default OrganizationDrawer
