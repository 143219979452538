import React from "react"
import { Table, Typography } from "antd"
import { useOutletContext } from "react-router-dom"

import { Value } from "@components/Text"
import { getFormattedDecimalValue } from "@components/Amount"
import {
  TRANSACTION_STATUS,
  TRANSACTION_TYPES,
  TRANSACTIONS_INVESTOR_INCOMING_TYPES
} from "@components/Domain"

const { Text } = Typography
const { Summary } = Table
const { Row, Cell } = Summary

const SKIP_STATUSES = [
  TRANSACTION_STATUS.REJECTED,
  TRANSACTION_STATUS.ERROR,
  TRANSACTION_STATUS.FAILED,
]


const getTotalByTypes = (types, transactions) => transactions
  .filter(({ status }) => !SKIP_STATUSES.includes(status))
  .filter(({ type }) => types.includes(type))
  .map(({ amount }) => amount)
  .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)


const useTableSummary = () => {
  const { hasMultipleSponsors } = useOutletContext()

  const summary = transactions => {

    const totalIncoming = getTotalByTypes([TRANSACTION_TYPES.FUNDING], transactions)

    const outgoingTypes = [
      ...TRANSACTIONS_INVESTOR_INCOMING_TYPES,
      TRANSACTION_TYPES.SPONSOR_PROMOTE
    ]

    const totalOutgoing = getTotalByTypes(outgoingTypes, transactions)

    return (
      <Summary fixed="bottom">
        <Row>
          <Cell
            align="right"
            colSpan={hasMultipleSponsors ? 6 : 5}
          >
            <Text strong>Total:</Text>
          </Cell>

          <Cell
            align="right"
          >
            <Value value={totalIncoming} />
          </Cell>
          <Cell
            align="right"
          >
            <Value value={totalOutgoing} />
          </Cell>
          <Cell>
            {' '}
          </Cell>
        </Row>
      </Summary>
    )
  }

  return summary
}

export default useTableSummary
