import React, { useState } from "react"
import PropTypes from "prop-types"
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons"
import { Button, Upload, Spin } from "antd"
import { useParams } from "react-router-dom"

import { W2 } from "@components"
import { FormShape } from "@components/Form"
import { ExportAction } from "@components/Table"
import { computeInvestor } from "@modules/backstage/investments"
import { useRequest as useEffect } from "@components/AppContext"
import { indexProjectInvestmentsOperation as indexOperation } from "@api/services/investments"
import {
  INVESTMENT_STATUS,
  TRANSACTION_TYPES,
  TRANSACTION_TYPE_LABEL_MAP,
} from "@components/Domain"

import DistributionTemplates from "./DistributionTemplates"
import useImportDistributions from "./helpers/useImportDistributions"
import DistributionImportForm from "./DistributionImportForm"

const LABEL_SELECT = "Select CSV Import"
const LABEL_INVESTORS_TEMPLATE = "CSV Investors"


const SelectDocumentButton = ({ onSuccess, form, setErrors }) => {
  const { id: projectId } = useParams()

  const [ isLoading, setIsLoading ] = useState(false)
  const [ investments, setInvestments ] = useState()
  const [ uploadedDistribution, setUploadedDistribution ] = useState()

  useEffect(request => {
    setIsLoading(true)

    request(indexOperation, { projectId })
      .then(({ data }) => {
        const activeInvestments = data.filter(({ status }) => status === INVESTMENT_STATUS.ACTIVE)
        setInvestments(activeInvestments)
      })
      .then(() => setIsLoading(false))
  }, [ projectId ])

  const onUploaded = file => setUploadedDistribution(file)

  const { customRequest } = useImportDistributions(investments, onSuccess, setErrors, onUploaded)

  const investmentsDataForExport = () => {
    const result = []

    if (investments) {
      const activeInvestments = investments
        .filter(({ status, isFunded }) => status === INVESTMENT_STATUS.ACTIVE && isFunded)

      for (const investment of activeInvestments) {
        const { receiverSourceId } = investment
        const profileName = computeInvestor(investment)

        const Email = investment.investor.email
        const Profile = `${profileName}${ !receiverSourceId ? " (no bank account)" : "" }`

        result.push({
          Profile,
          Email,
          [TRANSACTION_TYPE_LABEL_MAP[TRANSACTION_TYPES.INVESTMENT_RETURN]]: 0,
          [TRANSACTION_TYPE_LABEL_MAP[TRANSACTION_TYPES.SALE_PROFIT]]: 0,
          [TRANSACTION_TYPE_LABEL_MAP[TRANSACTION_TYPES.CAPITAL_RETURN]]: 0,
          [TRANSACTION_TYPE_LABEL_MAP[TRANSACTION_TYPES.SPONSOR_PROMOTE]]: 0,
        })
      }
    }

    return result
  }

  return (
    <div style={{ display: "flex", gap: W2 }}>
      <Spin spinning={isLoading}>
        <ExportAction
          icon={<DownloadOutlined />}
          type="link"
          title={LABEL_INVESTORS_TEMPLATE}
          fileName={`${projectId}-investors`}
          getItems={investmentsDataForExport}
        />
      </Spin>

      <Upload
        accept=".csv"
        multiple={false}
        customRequest={customRequest}
        showUploadList={false}
        onChange={() => setErrors()}
      >
        <Button
          type="dashed"
          icon={<UploadOutlined />}
        >
          {LABEL_SELECT}
        </Button>
      </Upload>

      <DistributionImportForm
        form={form}
      />

      <DistributionTemplates
        setErrors={setErrors}
        onSelected={onSuccess}
        investments={investments}
        uploadedDistribution={uploadedDistribution}
      />
    </div>
  )
}

SelectDocumentButton.propTypes = {
  form: FormShape.isRequired,
  onSuccess: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
}

export default SelectDocumentButton
