import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Descriptions } from "antd"

import { JsonMarkdown } from "@components/Text"
import { IndexActivitiesOutputShape } from "@api/services/activities"


const ActivitySourceDescriptions = ({
  activity = undefined,
  renderActivity,
}) => {
  const items = useMemo(() =>
    !activity
      ? []
      : [
        {
          key: 'summary',
          label: 'Summary',
          children: renderActivity(activity),
        },
        {
          key: 'parameters',
          label: 'Parameters',
          children: <JsonMarkdown json={(activity.parametersJson || "{}")} />,
        },
        {
          key: 'output',
          label: 'Output',
          children: <JsonMarkdown json={(activity.resultJson || "{}")} />,
        },
      ]
  , [ activity, renderActivity ])

  return (
    <Descriptions
      size="small"
      style={{ overflow: "hidden" }}
      items={items}
      layout="vertical"
      column={1}
      bordered={true}
    />
  )
}

ActivitySourceDescriptions.propTypes = {
  activity: IndexActivitiesOutputShape,
  renderActivity: PropTypes.func.isRequired,
}

export default ActivitySourceDescriptions
