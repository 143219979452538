import React from "react"
import { Table, Typography } from "antd"

import { hasAccess } from "@components/Authorization"
import { Value } from "@components/Text"
import { getFormattedDecimalValue } from "@components/Amount"
import { TRANSACTION_TYPES, TRANSACTION_STATUS } from "@components/Domain"

const { Text } = Typography
const { Row, Cell } = Table.Summary

const SKIP_STATUSES = [
  TRANSACTION_STATUS.REJECTED,
  TRANSACTION_STATUS.ERROR,
  TRANSACTION_STATUS.FAILED,
]


const getTotalByTransactionType = (distributions, type) =>
  distributions
    .filter(({ status }) => !SKIP_STATUSES.includes(status))
    .map(distribution => distribution[type])
    .filter(notNone => notNone)
    .map(transaction => transaction.amount)
    .reduce((_, a) => getFormattedDecimalValue(_, a, "plus"), 0)


const getTableSummary = () => {
  const canApproveOrReject = hasAccess(['transactions-approve'])

  const summary = distributions => {
    const totalSaleProfit = getTotalByTransactionType(distributions, TRANSACTION_TYPES.SALE_PROFIT)
    const totalCapitalReturn = getTotalByTransactionType(distributions, TRANSACTION_TYPES.CAPITAL_RETURN)
    const totalSponsorPromote = getTotalByTransactionType(distributions, TRANSACTION_TYPES.SPONSOR_PROMOTE)
    const totalInvestmentReturn = getTotalByTransactionType(distributions, TRANSACTION_TYPES.INVESTMENT_RETURN)

    const total = totalInvestmentReturn + totalSaleProfit + totalCapitalReturn + totalSponsorPromote

    return (
      <Row>
        <Cell
          align="right"
          index={1}
          colSpan={canApproveOrReject ? 3 : 2}
        >
          <Text strong>Total:</Text>
        </Cell>

        <Cell
          index={1}
          align="right"
        >
          <Value value={totalInvestmentReturn} />
        </Cell>

        <Cell
          align="right"
          index={1}
        >
          <Value value={totalSaleProfit} />
        </Cell>

        <Cell
          align="right"
          index={1}
        >
          <Value value={totalCapitalReturn} />
        </Cell>

        <Cell
          align="right"
          index={1}
        >
          <Value value={totalSponsorPromote} />
        </Cell>

        <Cell
          align="right"
          index={1}
        >
          <Value value={total} />
        </Cell>
        <Cell
          colSpan={1}
        >
          {' '}
        </Cell>
      </Row>
    )
  }

  return summary
}

export default getTableSummary
