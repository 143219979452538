
export const getSourceType = (context, createdBy, parameters) => {
  const { mutation } = parameters

  if (mutation.senderSourceId) {
    return "funding"
  }

  if (mutation.receiverSourceId) {
    return "distribution"
  }

  return 'Undefined'
}
