import {
  red,
  cyan,
  gold,
  green,
  orange,
  purple,
  magenta,
} from "@ant-design/colors"
import RoleEnum from "@api/services/backstage/shapes/RoleEnum"
import {
  CORPORATION,
  LLC,
  PARTNERSHIP,
} from "@api/services/transactions/shapes/BusinessTypeEnum"

export const SPONSOR_TYPE_OPTIONS = [
  {
    label: "Co-Sponsor",
    value: "CO_SPONSOR",
  },
  {
    label: "Broker Dealer",
    value: "BROKER_DEALER",
  }
]

export const INVESTOR_STATUSES = {
  HOLD: "HOLD",
  ACTIVE: "ACTIVE",
  PROSPECT: "PROSPECT",
}

export const INVESTOR_STATUS_LABELS_MAP = {
  [INVESTOR_STATUSES.HOLD]: "Inactive",
  [INVESTOR_STATUSES.ACTIVE]: "Active",
  [INVESTOR_STATUSES.PROSPECT]: "Prospect",
}

export const INVESTOR_STATUS_COLORS_MAP = {
  [INVESTOR_STATUSES.HOLD]: "blue",
  [INVESTOR_STATUSES.ACTIVE]: "green",
  [INVESTOR_STATUSES.PROSPECT]: "",
}

export const ROLES = {
  PARTNER: "PARTNER",
  INVESTOR: "INVESTOR",
  ...RoleEnum
}

export const CONSOLE_ROLES = [
  ROLES.ADMIN,
  ROLES.MANAGER,
]

export const BACKSTAGE_ROLES = [
  ROLES.ADMIN,
  ROLES.MANAGER,
  ROLES.ACCOUNTANT,
  ROLES.APPROVER,
  ROLES.SUPPORT,
]

export const BACKSTAGE_FUND_ROLES = [
  ROLES.FUND_ADMIN,
  ROLES.FUND_MANAGER,
  ROLES.ACCOUNTANT,
  ROLES.APPROVER,
  ROLES.SUPPORT,
]

export const CONSOLE_ROLES_MAP = {
  [ROLES.ADMIN]: {
    title: "Administrator",
    summary: "Has access to console and all organizations",
    color: purple,
    tagColor: "purple",
  },
  [ROLES.MANAGER]: {
    title: "Manager",
    summary: "No console access, organizations access defined by assigned goups",
    color: cyan,
    tagColor: "cyan",
  },
}

export const ROLES_MAP = {
  [ROLES.ADMIN]: {
    title: "Administrator",
    summary: "Full access",
    color: purple,
    tagColor: "purple",
  },
  [ROLES.MANAGER]: {
    title: "Relations Manager",
    summary: "Full access, but can't delete projects, add users and approve transactions",
    color: cyan,
    tagColor: "cyan",
  },
  [ROLES.FUND_ADMIN]: {
    title: "Fund Administrator",
    summary: "Full access",
    color: purple,
    tagColor: "purple",
  },
  [ROLES.FUND_MANAGER]: {
    title: "Relations Manager",
    summary: "Full access, but can't add users and approve transactions",
    color: cyan,
    tagColor: "cyan",
  },
  [ROLES.SUPPORT]: {
    title: "Customer Support",
    summary: "View only access",
    color: gold,
    tagColor: "gold",
  },
  [ROLES.APPROVER]: {
    title: "Transactions Approver",
    summary: "View only access, approves transactions",
    color: red,
    tagColor: "red",
  },
  [ROLES.ACCOUNTANT]: {
    title: "Accountant",
    summary: "View only access, creates transactions",
    color: magenta,
    tagColor: "magenta",
  },
  [ROLES.BROKER]: {
    title: "Liaison",
    summary: "Has view only access to projects and investors he promotes",
    color: orange,
    tagColor: "orange",
  },
  [ROLES.INVESTOR]: {
    title: "Investor",
    summary: "",
    color: green,
    tagColor: "green",
  },
}

export const PROJECT_TYPES = {
  FUND: "FUND",
  PORTFOLIO: "PORTFOLIO",
  SYNDICATION: "SYNDICATION"
}

export const INVESTMENT_STATUS = {
  ACTIVE: "ACTIVE",
  BACKUP: "BACKUP",
  REJECTED: "REJECTED",
}

export const INVESTMENT_STATUS_LABEL_MAP = {
  [INVESTMENT_STATUS.ACTIVE]: "Active",
  [INVESTMENT_STATUS.BACKUP]: "Backup",
  [INVESTMENT_STATUS.REJECTED]: "Dropout"
}

export const ACTIVE_INVESTMENT_STATUSES = [
  INVESTMENT_STATUS.ACTIVE,
  INVESTMENT_STATUS.BACKUP
]

export const PROJECT_STATE_OPEN = "OPEN"

export const PROJECT_STATES = {
  HOLD: "HOLD",
  OPEN: "OPEN",
  CLOSED: "CLOSED",
  ARCHIVED: "ARCHIVED",
}

export const PROJECT_STATE = PROJECT_STATES

export const STATE_LABELS_MAP = {
  [PROJECT_STATES.HOLD]: "Hold",
  [PROJECT_STATES.OPEN]: "Open",
  [PROJECT_STATES.CLOSED]: "Active",
  [PROJECT_STATES.ARCHIVED]: "Closed"
}

export const PROJECT_STATUSES = {
  DRAFT: "DRAFT",
  PUBLISHED: "PUBLISHED",
}

export const COMMITMENT_PROFILES_MAX_COUNT = 10

export const ACCREDITATION_REFERENCE_URL = "https://bit.ly/2NwpyTF"


export const TRANSACTION_STATUS_FAILED = "FAILED"
export const TRANSACTION_STATUS_RETRIED = "FAILED"
export const TRANSACTION_STATUS_PENDING = "PENDING"
export const TRANSACTION_STATUS_REJECTED = "REJECTED"
export const TRANSACTION_STATUS_PROCESSED = "PROCESSED"
export const TRANSACTION_STATUS_PENDING_ERROR = "ERROR"
export const TRANSACTION_STATUS_PENDING_APPROVAL = "PENDING_APPROVAL"

export const TRANSACTION_STATUS = {
  ERROR: "ERROR",
  FAILED: "FAILED",
  RETRIED: "RETRIED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  PROCESSED: "PROCESSED",
  CANCELLED: "CANCELLED",
  PENDING_APPROVAL: "PENDING_APPROVAL",
}

export const TRANSACTION_STATUS_LABEL_MAP = {
  [TRANSACTION_STATUS.ERROR]: "Error",
  [TRANSACTION_STATUS.FAILED]: "Failed",
  [TRANSACTION_STATUS.RETRIED]: "Retried",
  [TRANSACTION_STATUS.PENDING]: "Pending",
  [TRANSACTION_STATUS.REJECTED]: "Rejected",
  [TRANSACTION_STATUS.PROCESSED]: "Processed",
  [TRANSACTION_STATUS.CANCELLED]: "Cancelled",
  [TRANSACTION_STATUS.PENDING_APPROVAL]: "Pending Approval",
}

export const TRANSACTION_TYPES = {
  FUNDING: "FUNDING",
  SALE_PROFIT: "SALE_PROFIT",
  CAPITAL_RETURN: "CAPITAL_RETURN",
  SPONSOR_PROMOTE: "SPONSOR_PROMOTE",
  INVESTMENT_RETURN: "INVESTMENT_RETURN",
}

export const TRANSACTION_TYPE_LABEL_MAP = {
  [TRANSACTION_TYPES.FUNDING]: "Capital Contribution",
  [TRANSACTION_TYPES.SALE_PROFIT]: "Profit on Sale",
  [TRANSACTION_TYPES.CAPITAL_RETURN]: "Return of Capital",
  [TRANSACTION_TYPES.SPONSOR_PROMOTE]: "Sponsor Promote",
  [TRANSACTION_TYPES.INVESTMENT_RETURN]: "Return on Investment",
}

export const TRANSACTION_PROBLEM_STATUSES = [
  TRANSACTION_STATUS.ERROR,
  TRANSACTION_STATUS.FAILED
]

export const TRANSACTION_TYPE_ABBREVIATIONS_MAP = {
  [TRANSACTION_TYPES.FUNDING]: {
    title: "FND",
    description: "Soft Commit",
  },
  [TRANSACTION_TYPES.SALE_PROFIT]: {
    title: "POS",
    description: "Profit on Sale",
  },
  [TRANSACTION_TYPES.CAPITAL_RETURN]: {
    title: "ROC",
    description: "Return of Capital",
  },
  [TRANSACTION_TYPES.SPONSOR_PROMOTE]: {
    title: "SP",
    description: "Sponsor Promote",
  },
  [TRANSACTION_TYPES.INVESTMENT_RETURN]: {
    title: "ROI",
    description: "Return on Investment",
  },
}

export const getTransactionTypeLabel = type => TRANSACTION_TYPE_LABEL_MAP[type]

export const TRANSACTIONS_INVESTOR_INCOMING_TYPES = [
  TRANSACTION_TYPES.SALE_PROFIT,
  TRANSACTION_TYPES.CAPITAL_RETURN,
  TRANSACTION_TYPES.INVESTMENT_RETURN
]

export const TRANSACTION_TYPE_OPTIONS = [
  {
    value: TRANSACTION_TYPES.SALE_PROFIT,
    label: TRANSACTION_TYPE_LABEL_MAP[TRANSACTION_TYPES.SALE_PROFIT],
  },
  {
    value: TRANSACTION_TYPES.CAPITAL_RETURN,
    label: TRANSACTION_TYPE_LABEL_MAP[TRANSACTION_TYPES.CAPITAL_RETURN],
  },
  {
    value: TRANSACTION_TYPES.INVESTMENT_RETURN,
    label: TRANSACTION_TYPE_LABEL_MAP[TRANSACTION_TYPES.INVESTMENT_RETURN],
  },
  {
    value: TRANSACTION_TYPES.SPONSOR_PROMOTE,
    label: TRANSACTION_TYPE_LABEL_MAP[TRANSACTION_TYPES.SPONSOR_PROMOTE],
  },
]

export const INVESTMENT_PROFILE_TYPE_JOINT = "JOINT"
export const INVESTMENT_PROFILE_TYPE_SDIRA = "SDIRA"
export const INVESTMENT_PROFILE_TYPE_ENTITY = "ENTITY"
export const INVESTMENT_PROFILE_TYPE_INDIVIDUAL = "INDIVIDUAL"

export const INVESTMENT_PROFILE_FIELD_MAP = {
  [INVESTMENT_PROFILE_TYPE_JOINT]: "profileJoint",
  [INVESTMENT_PROFILE_TYPE_SDIRA]: "profileSdira",
  [INVESTMENT_PROFILE_TYPE_ENTITY]: "profileEntity",
  [INVESTMENT_PROFILE_TYPE_INDIVIDUAL]: "profileIndividual",
}

const JOINT_MEMBERSHIP_INTEREST_SPOUSES = "SPOUSES"
const JOINT_MEMBERSHIP_INTEREST_TENANTS = "TENANTS"
const JOINT_MEMBERSHIP_INTEREST_JOINT_TENANTS = "JOINT_TENANTS"
const JOINT_MEMBERSHIP_INTEREST_SPOUSES_SURVIVORSHIP = "SPOUSES_SURVIVORSHIP"

export const JOINT_MEMBERSHIP_INTERESTS_TITLES_MAP = {
  [JOINT_MEMBERSHIP_INTEREST_SPOUSES]: "Spouses, as community property",
  [JOINT_MEMBERSHIP_INTEREST_TENANTS]: "Tenants in common",
  [JOINT_MEMBERSHIP_INTEREST_JOINT_TENANTS]: "Joint tenants with rights of survivorship",
  [JOINT_MEMBERSHIP_INTEREST_SPOUSES_SURVIVORSHIP]: "Spouses, as community property with rights of survivorship",
}

export const ENTITY_MEMBERSHIP_INTEREST_TRUSTEE = "TRUSTEE"
export const ENTITY_MEMBERSHIP_INTEREST_OTHER = "OTHER"

export const ENTITY_MEMBERSHIP_INTERESTS_TITLES_MAP = {
  [ENTITY_MEMBERSHIP_INTEREST_TRUSTEE]: "As Trustee of a trust",
  [ENTITY_MEMBERSHIP_INTEREST_OTHER]: "Other, such as a corporation, LLC, partnership, employee benefit plan, Keogh plan, or other entity",
}

export const ENTITY_TYPE_LLC = "LLC"
export const ENTITY_TYPE_PARTNERSHIP = "PARTNERSHIP"
export const ENTITY_TYPE_S_CORP = "S_CORP"
export const ENTITY_TYPE_C_CORP = "C_CORP"
export const ENTITY_TYPE_CORPORATION = "CORPORATION"
export const ENTITY_TYPE_EXEMPT_ORGANIZATION = "EXEMPT_ORGANIZATION"
export const ENTITY_TYPE_OTHER_RETIREMENT_PLAN = "OTHER_RETIREMENT_PLAN"

export const ENTITY_TYPE_TITLES_MAP = {
  [ENTITY_TYPE_LLC]: "LLC",
  [ENTITY_TYPE_PARTNERSHIP]: "Partnership (GP, LP, LLP, LLLP)",
  [ENTITY_TYPE_S_CORP]: "S Corporation",
  [ENTITY_TYPE_C_CORP]: "C Corporation",
  [ENTITY_TYPE_EXEMPT_ORGANIZATION]: "Exempt Organization",
  [ENTITY_TYPE_OTHER_RETIREMENT_PLAN]: "Other Retirement Plan",
}

export const SHORT_ENTITY_TYPE_TITLES_MAP = {
  [LLC]: "LLC",
  [PARTNERSHIP]: "Partnership (GP, LP, LLP, LLLP)",
  [CORPORATION]: "Corporation",
}

export const ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_LLC = "LLC"
export const ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_PARTNERSHIP = "PARTNERSHIP"
export const ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_S_CORP = "S_CORP"
export const ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_C_CORP = "C_CORP"
export const ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_TRUST = "TRUST"
export const ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_EXEMPT_ORGANIZATION = "EXEMPT_ORGANIZATION"
export const ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_IRA = "IRA"
export const ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_OTHER_RETIREMENT_PLAN = "OTHER_RETIREMENT_PLAN"
export const ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_INDIVIDUAL = "INDIVIDUAL"

export const ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_TITLES_MAP = {
  [ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_INDIVIDUAL]: "Individual",
  [ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_LLC]: "LLC",
  [ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_PARTNERSHIP]: "Partnership (GP, LP, LLP, LLLP)",
  [ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_TRUST]: "Trust",
  [ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_IRA]: "IRA",
  [ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_S_CORP]: "S Corporation",
  [ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_C_CORP]: "C Corporation",
  [ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_OTHER_RETIREMENT_PLAN]: "Other Retirement Plan",
  [ENTITY_BENEFICIAL_OWNER_ENTITY_TYPE_EXEMPT_ORGANIZATION]: "Exempt Organization",
}

const BENEFICIAL_OWNER_TAX_FILING_STATUS_PARTNERSHIP = "PARTNERSHIP"
const BENEFICIAL_OWNER_TAX_FILING_STATUS_S_CORP = "S_CORP"
const BENEFICIAL_OWNER_TAX_FILING_STATUS_C_CORP = "C_CORP"

export const BENEFICIAL_OWNER_TAX_FILING_STATUS_TITLES_MAP = {
  [BENEFICIAL_OWNER_TAX_FILING_STATUS_PARTNERSHIP]: "Filing as a Partnership",
  [BENEFICIAL_OWNER_TAX_FILING_STATUS_S_CORP]: "Filing as an S Corporation",
  [BENEFICIAL_OWNER_TAX_FILING_STATUS_C_CORP]: "Filing as a C Corporation",
}

export const ENTITY_TAX_FILING_STATUS_TITLES_MAP = BENEFICIAL_OWNER_TAX_FILING_STATUS_TITLES_MAP

export const PROFILE_TYPES_LABELS = {
  [INVESTMENT_PROFILE_TYPE_INDIVIDUAL]: "Individual",
  [INVESTMENT_PROFILE_TYPE_JOINT]: "Joint",
  [INVESTMENT_PROFILE_TYPE_ENTITY]: "Entity",
  [INVESTMENT_PROFILE_TYPE_SDIRA]: "SDIRA",
}

export const INVESTMENT_STATUS_REJECTED = "REJECTED"
export const INVESTMENT_STATUS_BACKUP = "BACKUP"
export const INVESTMENT_STATUS_ACTIVE = "ACTIVE"

export const ORGANIZATION_DOMAIN_STATUS = {
  CERTIFICATE_PENDING: "CERTIFICATE_PENDING",
  CERTIFICATE_RECORD: "CERTIFICATE_RECORD",
  CERTIFICATE_VERIFICATION: "CERTIFICATE_VERIFICATION",
  DOMAIN_RECORD: "DOMAIN_RECORD",
  COMPLETE: "COMPLETE",
}

export const TRANSACTION_AMOUNT_MAX = 500000

export const SENDER_BUSINESS_STATUS = {
  PENDING: "PENDING"
}

export const LATEST_TERMS_OF_SERVICE_VERSION = 1

export const PROJECT_DATES_MAP = {
  openDate: "Deal available to investors",
  signDeadlineDate: "Deadline to execute documents",
  fundDeadlineDate: "Funding deadline",
  targetCloseDate: "Target close of escrow",
}

export const TOOLTIP_BENEFICIAL_OWNER_NAME = "A Beneficial owner is a natural person(s) who ultimately owns or controls an interest in a legal entity \n" +
  "or arrangement, such as a company, a trust, or a foundation."

export const TOOLTIP_PROFILE_DRE = "A disregarded entity for US federal tax purposes is an entity that is disregarded as an entity separate from its owner. Common examples of disregarded entities include most single-member LLCs and grantor trusts. If the entity reports all its income or loss directly on your personal return (or another entity), or in other words, it does not file its own/separate tax return, it is likely a disregarded entity. Please consult your tax advisor if you are unsure."

export const TERMS_URLS = {
  DWOLLA_TERMS: "https://www.dwolla.com/legal/tos/",
  DWOLLA_PRIVACY: "https://www.dwolla.com/legal/privacy/",
  PLAID_PRIVACY: "https://plaid.com/legal/#end-user-privacy-policy",
  FUNDFLOW_TERMS: "/static/terms.html",
}

export const PROPERTY_STATUS = {
  ACTIVE: "ACTIVE",
  CLOSED: "CLOSED",
  SOLD: "SOLD",
}

export const PROPERTY_TAG_PURCHASE_PRICE = "Purchase Price"
export const PROPERTY_TAG_EQUITY_INVESTED = "Equity Invested"
export const PROPERTY_TAG_ENTITY_NAME = "Entity Name"
export const PROPERTY_TAG_FORMERLY_KNOWN_US = "Formerly Known As"
export const PROPERTY_TAG_NUMBER_OF_UNITS = "Number of Units"
export const PROPERTY_TAG_UNIT_MIX = "Unit Mix"
export const PROPERTY_TAG_LOAN_TYPE = "Loan Type"
export const PROPERTY_TAG_OFFERING_STRUCTURE = "Offering Structure"
export const PROPERTY_TAG_BEGIN_MONTHLY_FINANCIAL_REPORTING = "Begin Monthly Financial Reporting"
export const PROPERTY_TAG_BEGIN_MONTHLY_DISTRIBUTIONS = "Begin Monthly Distributions"
export const PROPERTY_TAG_YEAR_BUILT = "Year Built"

export const PROPERTY_TAG_LABELS = [
  PROPERTY_TAG_PURCHASE_PRICE,
  PROPERTY_TAG_EQUITY_INVESTED,
  PROPERTY_TAG_ENTITY_NAME,
  PROPERTY_TAG_FORMERLY_KNOWN_US,
  PROPERTY_TAG_NUMBER_OF_UNITS,
  PROPERTY_TAG_UNIT_MIX,
  PROPERTY_TAG_LOAN_TYPE,
  PROPERTY_TAG_OFFERING_STRUCTURE,
  PROPERTY_TAG_BEGIN_MONTHLY_FINANCIAL_REPORTING,
  PROPERTY_TAG_BEGIN_MONTHLY_DISTRIBUTIONS,
  PROPERTY_TAG_YEAR_BUILT,
]

export const PROJECT_TAG_ACQUISITION_DATE = "Acquisition Date"
export const PROJECT_TAG_PURCHASE_PRICE = "Purchase Price"
export const PROJECT_TAG_PROJECTED_HOLD = "Projected Hold"
export const PROJECT_TAG_PROJECTED_EQUITY_MULTIPLE = "Projected Equity Multiple"
export const PROJECT_TAG_PROJECTED_INTERNAL_RATE_OF_RETURN = "Projected Internal Rate of Return (IRR)"
export const PROJECT_TAG_PROJECTED_AVERAGE_ANNUAL_RETURN = "Projected Average Annual Return"
export const PROJECT_TAG_DISPOSITION_DATE = "Disposition Date"
export const PROJECT_TAG_DISPOSITION_SALES_PRICE = "Disposition Sales Price"
export const PROJECT_TAG_AVERAGE_ANNUAL_CASH_FLOW = "Average Annual Cash Flow"

export const PROJECT_TAG_LABELS = [
  PROJECT_TAG_ACQUISITION_DATE,
  PROJECT_TAG_PURCHASE_PRICE,
  PROJECT_TAG_PROJECTED_HOLD,
  PROJECT_TAG_PROJECTED_EQUITY_MULTIPLE,
  PROJECT_TAG_PROJECTED_INTERNAL_RATE_OF_RETURN,
  PROJECT_TAG_PROJECTED_AVERAGE_ANNUAL_RETURN,
  PROJECT_TAG_DISPOSITION_DATE,
  PROJECT_TAG_DISPOSITION_SALES_PRICE,
  PROJECT_TAG_AVERAGE_ANNUAL_CASH_FLOW
]

// NOTE: Should be pulled from spec:
export const TIER_NAMES = [ 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J' ]
