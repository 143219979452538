import { getInvestor } from "./getInvestor"


export const getInvestorEmail = (context, createdBy, parameters, result) => {
  const investor = getInvestor(context, createdBy, parameters, result)

  if (!investor) {
    return `Undefined`
  }

  const { email } = investor

  return email
}
