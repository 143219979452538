import React from "react"
import PropTypes from "prop-types"

import { DRAWER_WIDTH, W2 } from "@components"

import Markdown from "./Markdown"

const drawerInnerWidth = DRAWER_WIDTH - W2 * 2


const JsonMarkdown = ({ json }) => {
  const object = JSON.parse(json)
  const formattedJson = JSON.stringify(object, null, 2)

  const text = "```\nJSON\n```".replace("JSON", formattedJson)

  return (
    <div style={{ width: drawerInnerWidth, overflowX: "auto", whiteSpace: "nowrap" }}>
      <Markdown text={text} />
    </div>
  )
}

JsonMarkdown.propTypes = {
  json: PropTypes.string.isRequired,
}

export default JsonMarkdown
