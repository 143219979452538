import React from "react"
import { NavLink } from "react-router-dom"
import {EditOutlined, StopOutlined, UndoOutlined} from "@ant-design/icons"

const LABEL_EDIT = "Edit"
const LABEL_ENABLE = "Enable"
const LABEL_DISABLE = "Disable"


const useRowMenu = (organization, { openUpdateModal, enableOrganization, disableOrganization }) => {
  const { isDisabled } = organization

  const items = [
    {
      key: "update",
      icon: <EditOutlined />,
      label: (
        <NavLink onClick={() => openUpdateModal(organization)}>
          {LABEL_EDIT}
        </NavLink>
      )
    },
    {
      type: "divider"
    },
  ]

  if (isDisabled) {
    items.push({
      key: "enable",
      icon: <UndoOutlined />,
      label: (
        <NavLink onClick={() => enableOrganization()}>
          {LABEL_ENABLE}
        </NavLink>
      )
    })

    return items
  }

  items.push({
    key: "disable",
    icon: <StopOutlined />,
    danger: true,
    label: (
      <NavLink onClick={() => disableOrganization()}>
        {LABEL_DISABLE}
      </NavLink>
    )
  })

  return items
}

export default useRowMenu
