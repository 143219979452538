import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button } from "antd"
import { SendOutlined } from "@ant-design/icons"

import { useAppContext } from "@components/AppContext"
import { sendNotificationOperation as operation } from "@api/services/notifications"

const LABEL_SUCCESS = "Test email has been sent to [EMAIL]"


const SendTestEmailButton = ({ campaign }) => {
  const { request, identity, showSuccessMessage } = useAppContext()
  const [ isLoading, setIsLoading ] = useState(false)

  const { email: toAddress } = identity

  const {
    html,
    subject,
    fromName,
    fromAddress,
    replyToAddress
  } = campaign

  const onClick = () => {
    setIsLoading(true)

    const mutation = {
      emailMessage: {
        html,
        subject,
        fromName,
        toAddress,
        fromAddress,
      }
    }

    if (replyToAddress) {
      mutation.emailMessage.replyToAddress = replyToAddress
    }

    return request(operation, { mutation })
      .then(() => setIsLoading(false))
      .then(() => showSuccessMessage(LABEL_SUCCESS.replace('[EMAIL]', toAddress)))
  }

  const isDisabled = !fromName

  return (
    <Button
      icon={<SendOutlined />}
      type="primary"
      size="large"
      shape="round"
      style={{ width: "100%" }}
      loading={isLoading}
      onClick={onClick}
      disabled={isDisabled}
    >
      Send Test Email
    </Button>
  )
}

SendTestEmailButton.propTypes = {
  campaign: PropTypes.shape().isRequired,
}

export default SendTestEmailButton
