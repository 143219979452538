
export const getMyMemberName = (context, createdBy) => {
  const { membersMap } = context
  const member = membersMap[createdBy]

  if (!member) {
    return 'Undefined'
  }

  const { givenName, familyName } = member
  const fullName = `${givenName} ${familyName}`

  return fullName
}
