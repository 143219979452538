import parseJwt from "./parseJwt"

const ORGANIZATION_KEY = "organizationId"
const INVESTOR_AUTHENTICATION_KEY = "investorAuthentication"
const BACKSTAGE_AUTHENTICATION_KEY = "backstageAuthentication"
const IMPERSONATED_AUTHENTICATION_KEY = "impersonatedAuthentication"


class Storage {
  static get investorAuthentication() {
    return window.sessionStorage.getItem(INVESTOR_AUTHENTICATION_KEY)
  }

  static setInvestorAuthentication(authenticationJwt) {
    window.sessionStorage.setItem(INVESTOR_AUTHENTICATION_KEY, authenticationJwt)
  }

  static get backstageAuthentication() {
    return window.sessionStorage.getItem(BACKSTAGE_AUTHENTICATION_KEY)
  }

  static setBackstageAuthentication(authenticationJwt) {
    window.sessionStorage.setItem(BACKSTAGE_AUTHENTICATION_KEY, authenticationJwt)
  }

  static removeBackstageAuthentication() {
    window.sessionStorage.removeItem(BACKSTAGE_AUTHENTICATION_KEY)
  }

  static popImpersonatedAuthentication() {
    const authentication = window.localStorage.getItem(IMPERSONATED_AUTHENTICATION_KEY)
    window.localStorage.removeItem(IMPERSONATED_AUTHENTICATION_KEY)

    return authentication
  }

  static setImpersonatedAuthentication(authenticationJwt) {
    localStorage.setItem(IMPERSONATED_AUTHENTICATION_KEY, authenticationJwt)
  }

  static getAuthentication = isBackstage => {
    const authentication = isBackstage
      ? this.backstageAuthentication
      : this.investorAuthentication

    return authentication
  }

  static get customOrganizationId() {
    return sessionStorage.getItem(ORGANIZATION_KEY)
  }

  static setCustomOrganizationId(organizationId) {
    return sessionStorage.setItem(ORGANIZATION_KEY, organizationId)
  }

  static reset() {
    sessionStorage.removeItem(ORGANIZATION_KEY)
    sessionStorage.removeItem(INVESTOR_AUTHENTICATION_KEY)
    sessionStorage.removeItem(BACKSTAGE_AUTHENTICATION_KEY)

    localStorage.removeItem(IMPERSONATED_AUTHENTICATION_KEY)
  }

  static setAuthorization(authorizationJwt) {
    window.authorization = authorizationJwt
    window.authorizationClaims = parseJwt(authorizationJwt)
  }

  static get authorization() {
    return window.authorization
  }

  static hasAccess(requiredPermissions) {
    const { permissions } = window.authorizationClaims

    const missingPermissions =
      requiredPermissions.filter(permission => !permissions.includes(permission))

    return missingPermissions.length === 0
  }

  static get isPendingAuthentication() {
    return window.isPendingAuthentication || false
  }

  static startAuthentication = () => {
    window.isPendingAuthentication = true
  }

  static stopAuthentication = () => {
    window.isPendingAuthentication = false
  }

  static get isPendingAuthorization() {
    return window.isPendingAuthorization || false
  }

  static startAuthorization = () => {
    window.isPendingAuthorization = true
  }

  static stopAuthorization = () => {
    window.isPendingAuthorization = false
  }

  static getIdentityEmail(isBackstage) {
    const authenticationJwt = this.getAuthentication(isBackstage)

    const authenticationPayload = parseJwt(authenticationJwt)
    const { email } = authenticationPayload

    return email
  }
}

export default Storage
