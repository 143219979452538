import { DomainStatusEnumKeys } from "./shapes/DomainStatusEnum"

const IndexOrganizationsItemSchema = {
  domainStatus: { enum: DomainStatusEnumKeys },
  email: { format: 'email' },
  isEmailVerified: { type: 'boolean' },
  isDisabled: { type: 'boolean' },
  disabledAt: { format: 'date-time' },
  createdAt: { format: 'date-time' },
  updatedAt: { format: 'date-time' }
}

export default IndexOrganizationsItemSchema
