import React, { useRef } from "react"
import { Layout, theme, Divider, Menu } from "antd"
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom"
import {
  SwapOutlined,
  HomeOutlined,
  TeamOutlined,
  FolderOutlined,
  LogoutOutlined,
  WalletOutlined,
  HistoryOutlined,
  FastForwardOutlined
} from "@ant-design/icons"

import { W0, SIDEBAR_WIDTH } from "@components"
import { OrganizationsModal } from "@components/Organization"
import { GoToInvestmentModal } from "@components/Investment"

import { SidebarDropdown } from "../../../backstage"

const { Sider } = Layout

const LABEL_USERS = "Users"
const LABEL_GROUPS = "Groups"
const LABEL_SIGNOUT = "Sign Out"
const LABEL_SWITCH_TO = "Switch To"
const LABEL_CUSTOMERS = "ACH Accounts"
const LABEL_ACTIVITIES = "Activities"
const LABEL_ORGANIZATIONS = "Organizations"
const LABEL_GO_TO_INVESTMENT = "Go To Investment"


const Sidebar = () => {
  const organizationsModal = useRef({})
  const goToInvestmentModal = useRef({})

  const { token } = theme.useToken()

  const openOrganizationsModal = () =>
    organizationsModal.current.open()

  const openGoToInvestmentModal = () =>
    goToInvestmentModal.current.open()

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const dropdownItems = [
    {
      key: "investment",
      icon: <FastForwardOutlined />,
      label: LABEL_GO_TO_INVESTMENT,
      onClick: openGoToInvestmentModal,
    },
    {
      type: 'divider',
    },
    {
      key: "switch",
      icon: <SwapOutlined />,
      label: LABEL_SWITCH_TO,
      onClick: openOrganizationsModal,
    },
    {
      key: "logout",
      icon: <LogoutOutlined />,
      label: (
        <NavLink to="/logout/backstage">
          {LABEL_SIGNOUT}
        </NavLink>
      )
    }
  ]

  const items = [
    {
      key: "/backstage/organizations",
      icon: <HomeOutlined />,
      label: <Link to="/backstage/organizations">{LABEL_ORGANIZATIONS}</Link>,
    },
    {
      key: "/backstage/customers",
      icon: <WalletOutlined />,
      label: <Link to="/backstage/customers">{LABEL_CUSTOMERS}</Link>,
    },
    {
      key: "/backstage/groups",
      icon: <FolderOutlined />,
      label: <Link to="/backstage/groups">{LABEL_GROUPS}</Link>,
    },
    {
      key: "/backstage/users",
      icon: <TeamOutlined />,
      label: <Link to="/backstage/users">{LABEL_USERS}</Link>,
    },
    {
      key: "/backstage/activities",
      icon: <HistoryOutlined />,
      label: <Link to="/backstage/activities">{LABEL_ACTIVITIES}</Link>,
    },
  ]

  const selectedKeys = items
    .map(item => item.key)
    .filter(key => pathname.startsWith(`${key}`))

  const { colorBorderSecondary, colorBgContainer } = token

  const style = {
    top: 0,
    left: 0,
    bottom: 0,
    overflow: 'auto',
    position: 'fixed',
    background: colorBgContainer,
    borderRight: `1px solid ${colorBorderSecondary}`,
  }

  return (
    <Sider
      width={SIDEBAR_WIDTH}
      style={style}
    >
      <SidebarDropdown
        items={dropdownItems}
      />

      <Divider style={{ margin: 0 }} />

      <Menu
        style={{ border: "none" }}
        items={items}
        onClick={({ key }) => navigate(key)}
        selectedKeys={selectedKeys}
        inlineIndent={W0 + 4}
      />

      <OrganizationsModal
        modal={organizationsModal}
      />

      <GoToInvestmentModal
        modal={goToInvestmentModal}
      />
    </Sider>
  )
}

export default Sidebar
