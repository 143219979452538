import { useState } from "react"
import dayjs from "dayjs"

import { getUsDateFromIsoDate } from "@components/Date"

const today = new Date().toISOString()

const DEFAULT_DATE_RANGE = [
  dayjs(getUsDateFromIsoDate(today)).startOf('month'),
  dayjs(getUsDateFromIsoDate(today)).endOf('month')
]


const getIndexOperationParameters = ({ dateRange, projectId }) => {
  if (!dateRange) {
    dateRange = DEFAULT_DATE_RANGE
  }

  const firstDateRange = dateRange[0]
  const secondDateRange = dateRange[1]

  const endDate = secondDateRange.add(1, 'day').format('YYYY-MM-DD')
  const startDate = firstDateRange.subtract(1, 'day').format('YYYY-MM-DD')

  let items = {
    endDate,
    startDate,
  }

  if (projectId) {
    items = {
      ...items,
      projectId
    }
  }

  return items
}


const useIndexOperationParameters = projectId => {
  const DEFAULT_OPERATION_PARAMETERS = getIndexOperationParameters({ projectId })

  const [ dateRange, setDateRange ] = useState(DEFAULT_DATE_RANGE)
  const [ operationParameters, setOperationParameters ] = useState(DEFAULT_OPERATION_PARAMETERS)

  const updateDateRange = newDateRange => {
    const hasChanged = JSON.stringify(dateRange) !== JSON.stringify(newDateRange)

    if (!hasChanged) {
      return
    }

    const parameters = getIndexOperationParameters({ dateRange: newDateRange, projectId })

    setDateRange(newDateRange)
    setOperationParameters(parameters)
  }

  return {
    updateDateRange,
    operationParameters,
  }
}

export default useIndexOperationParameters
