import React from 'react'
import { Descriptions } from "antd"

import useItems from "./helpers/useItems"
import { AccountShape } from "../../shapes"


const AccountDescriptions = ({ account }) => {
  const items = useItems(account)

  return (
    <Descriptions
      bordered
      size="small"
      column={1}
      className="compact black"
      labelStyle={{ width: "40%" }}
    >
      {
        items.map(({ label, value }, key) => (
          <Descriptions.Item
            key={key}
            label={label}
          >
            {value}
          </Descriptions.Item>
        ))
      }
    </Descriptions>
  )
}

AccountDescriptions.propTypes = {
  account: AccountShape.isRequired,
}

export default AccountDescriptions
