import React from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"
import { Collapse, Button, Space } from "antd"
import { EditOutlined } from "@ant-design/icons"

import Note from "@components/Note"
import { hasAccess } from "@components/Authorization"
import { W1, W3 } from "@components"
import { EditTagsDropdown } from "@components/Tags"
import { updateAccountNoteOperation } from "@api/services/investments"

import AccountStatistics from "./AccountStatistics"
import AccountDescriptions from "./AccountDescriptions"
import AccountInvestmentsTable from "./AccountInvestmentsTable"
import { AccountShape, InvestmentShape, TransactionShape } from "../shapes"

const LABEL_NOTE = "Note"
const LABEL_EDIT = "Edit"
const LABEL_INVESTOR = "Investor"
const LABEL_INVESTMENTS_PENDING = "Investments"


const Account = ({
  onEdit,
  account,
  investments,
  transactions,
  updateAccount,
  setAccountTags,
  indexInvestments
}) => {
  const { accountTags } = useOutletContext()

  const onAfterSubmit = updatedAccount =>
    updateAccount({ ...account, ...updatedAccount })

  const isNoteEditable = hasAccess(['accounts-note-write'])

  const canUpdateAccount = hasAccess(['accounts-write'])

  const onClick = e => {
    e.stopPropagation()
    onEdit()
  }

  const getExtra = () => !canUpdateAccount
    ? null
    : (
      <Button
        icon={<EditOutlined />}
        size="small"
        shape="round"
        onClick={onClick}
      >
        {LABEL_EDIT}
      </Button>
    )

  const renderLabel = value =>
    <span style={{ fontWeight: 600 }}>{value}</span>

  const {
    id: accountId,
    note,
    tagIds,
    noteUpdatedAt,
    noteUpdatedBy,
  } = account

  const items = [
    {
      key: 'descriptions',
      label: renderLabel(LABEL_INVESTOR),
      extra: getExtra(),
      children: <AccountDescriptions account={account} />,
    },
    {
      key: "note",
      label: renderLabel(LABEL_NOTE),
      children: (
        <Note
          id={accountId}
          note={note}
          updatedAt={noteUpdatedAt}
          updatedBy={noteUpdatedBy}
          operation={updateAccountNoteOperation}
          isEditable={isNoteEditable}
          onAfterSubmit={onAfterSubmit}
        />
      )
    },
    {
      key: 'investments',
      label: renderLabel(LABEL_INVESTMENTS_PENDING),
      children: (
        <AccountInvestmentsTable
          items={investments}
          indexItems={indexInvestments}
          transactions={transactions}
        />
      ),
    },
  ]

  const style = {
    marginBottom: W3
  }

  const defaultActiveKey = [
    'descriptions',
    'note',
    'investments',
  ]

  const collapseProps = {
    size: "small",
    style: { background: "transparent" },
    bordered: false,
    items,
    defaultActiveKey,
  }

  const hasTransactions = !!transactions

  const statisticsStyle = hasTransactions
    ? {}
    : { opacity: 0.75 }

  const onSave = newTagIds => setAccountTags(newTagIds)

  const editTagsDropdownProps = {
    size: "small",
    tags: accountTags,
    isAlwaysShown: true,
    onSave,
    tagIds,
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div style={statisticsStyle}>
        <AccountStatistics transactions={transactions || []} />
      </div>

      <div style={{ marginBottom: W1 * 1.5 }}>
        <EditTagsDropdown {...editTagsDropdownProps} />
      </div>

      <div className="descriptions" style={style}>
        <Collapse {...collapseProps} />
      </div>
    </Space>
  )
}

Account.propTypes = {
  onEdit: PropTypes.func.isRequired,
  account: AccountShape.isRequired,
  investments: PropTypes.arrayOf(InvestmentShape).isRequired,
  transactions: PropTypes.arrayOf(TransactionShape).isRequired,
  setAccountTags: PropTypes.func.isRequired,
  indexInvestments: PropTypes.func.isRequired,
  updateAccount: PropTypes.func.isRequired,
}

export default Account
