import React from "react"
import { useOutletContext } from "react-router-dom"
import { Typography } from "antd"

import Identity from "@components/Identity"
import { Value } from "@components/Text"

const LABEL_USER = "User"
const LABEL_ACTIVITY = "Activity"

const isInvestor = id => id.startsWith("ACC_")

const isBackstageUser = id => id.startsWith("USR_")


const useColumnsConfig = (table, openActivityDrawer) => {
  const {
    computeUserFullname,
    computeAccountFullname
  } = useOutletContext()

  const computeFullname = (activity) => {
    const { userId: id, resultJson } = activity

    if (isInvestor(id)) {
      return computeAccountFullname(id)
    }

    if (isBackstageUser(id)) {
      return computeUserFullname(id)
    }

    if (!resultJson) {
      return ""
    }

    const result = JSON.parse(resultJson)
    const { controllerEmail } = result

    if (controllerEmail) {
      return controllerEmail
    }

    return ""
  }

  const userColumnProps = {
    key: "userId",
    title: LABEL_USER,
    compute: activity => computeFullname(activity),
    render: (fullname, { userId }) => {
      const hasIdentity = isInvestor(userId) || isBackstageUser(userId)

      if (hasIdentity) {
        return <Identity id={userId}>{fullname}</Identity>
      }

      return fullname
    },
    isSearchable: true,
  }

  const activityColumnProps = {
    key: "operationId",
    title: LABEL_ACTIVITY,
    compute: activity => [ activity.operationId, table.current.renderActivity(activity) ],
    render: ([ operationId, summary ], { id }) => (
      <>
        <Typography.Text>{summary}</Typography.Text>
        <br/>
        <Typography.Link strong onClick={() => openActivityDrawer(id)}>
          {operationId}
        </Typography.Link>
      </>
    ),
    isSearchable: true,
  }

  const createdAtColumnProps = {
    key: "createdAt",
    render: value =>
      <Value value={value} />
  }

  return [
    { ...userColumnProps, width: "auto", widthAutoPadding: 40 },
    { ...activityColumnProps },
    { ...createdAtColumnProps, width: 160 }
  ]
}

export default useColumnsConfig
