import {
  TRANSACTION_STATUS_PENDING,
  TRANSACTION_STATUS_LABEL_MAP,
  TRANSACTION_STATUS_PENDING_APPROVAL
} from "@components/Domain"


const computeState = (status, isApproved) => {
  const isPendingApproval = status === TRANSACTION_STATUS_PENDING && !isApproved

  if (isPendingApproval) {
    status = TRANSACTION_STATUS_PENDING_APPROVAL
  }

  return TRANSACTION_STATUS_LABEL_MAP[status]
}

export default computeState
