import { TRANSACTION_TYPE_LABEL_MAP, TRANSACTION_TYPES } from "@components/Domain"

const PROFILE_COLUMN = "Profile"
const EMAIL_COLUMN = "Email"
const INVESTMENT_RETURN_COLUMN = TRANSACTION_TYPE_LABEL_MAP[TRANSACTION_TYPES.INVESTMENT_RETURN]
const SALE_PROFIT_COLUMN = TRANSACTION_TYPE_LABEL_MAP[TRANSACTION_TYPES.SALE_PROFIT]
const CAPITAL_RETURN_COLUMN = TRANSACTION_TYPE_LABEL_MAP[TRANSACTION_TYPES.CAPITAL_RETURN]
const SPONSOR_PROMOTE_COLUMN = TRANSACTION_TYPE_LABEL_MAP[TRANSACTION_TYPES.SPONSOR_PROMOTE]

const ROW_COLUMN_MAP = {
  investmentName: PROFILE_COLUMN,
  email: EMAIL_COLUMN,
  returnOnInvestment: INVESTMENT_RETURN_COLUMN,
  profitOnSale: SALE_PROFIT_COLUMN,
  returnOfCapital: CAPITAL_RETURN_COLUMN,
  sponsorPromote: SPONSOR_PROMOTE_COLUMN
}


const getParseInvestorTransactions = setErrors => {
  return (row, rowIndex) => {
    const {
      [PROFILE_COLUMN]: investmentName,
      [EMAIL_COLUMN]: email,
      [INVESTMENT_RETURN_COLUMN]: returnOnInvestment,
      [SALE_PROFIT_COLUMN]: profitOnSale,
      [CAPITAL_RETURN_COLUMN]: returnOfCapital,
      [SPONSOR_PROMOTE_COLUMN]: sponsorPromote,
    } = row

    const data = {
      investmentName,
      email,
      returnOnInvestment,
      profitOnSale,
      returnOfCapital,
      sponsorPromote
    }

    // eslint-disable-next-line guard-for-in
    for (const key in data) {
      const value = data[key]

      if (!value) {
        const columnName = ROW_COLUMN_MAP[key]

        setErrors((prevItems = []) => ([
          ...prevItems,
          {
            index: rowIndex,
            reason: `No value for "${columnName}" column`
          }
        ]))

        return
      }
    }

    const [ profileName ] = investmentName.split(' (')

    return {
      ...data,
      profileName,
      rowIndex
    }
  }
}

export default getParseInvestorTransactions
