import {
  FUND_NAME,
  SOURCE_TYPE,
  MEMBER_NAME,
  PROJECT_TYPE,
  PROJECT_NAME,
  INVESTOR_NAME,
  INVESTOR_EMAIL,
  MY_MEMBER_NAME,
  COMMITMENT_NAME,
  MY_INVESTOR_NAME,
  COMMITMENT_AMOUNT,
  INVESTMENT_AMOUNT,
  PROJECT_OPEN_DATE,
  MY_INVESTOR_MOBILE,
  PARTNER_TARGET_AMOUNT,
  INVESTMENT_PROFILE_TYPE,
  PARTNER_ORGANIZATION_NAME,
} from "./helpers/variables"


const TEMPLATES = {
  AuthenticateAs: `${MY_MEMBER_NAME} logged in as ${INVESTOR_NAME} to the investor portal`,
  CreateAccount:
    `${MY_MEMBER_NAME} registered the investor ${INVESTOR_NAME} with the email ${INVESTOR_EMAIL}`,
  CreateAccountInvestment:
    `${MY_MEMBER_NAME} established a ${INVESTMENT_PROFILE_TYPE} investment of ${INVESTMENT_AMOUNT} in "${PROJECT_NAME}" project for investor ${INVESTOR_NAME}`,
  CreateInvestment:
    `Investor ${MY_INVESTOR_NAME} established ${INVESTMENT_PROFILE_TYPE} investment of ${INVESTMENT_AMOUNT} in "${PROJECT_NAME}" project`,
  UpdateInvestment:
    `${MY_MEMBER_NAME} revised the details of ${INVESTOR_NAME}'s ${INVESTMENT_PROFILE_TYPE} investment in the "${PROJECT_NAME}" project`,
  UpdateMember:
    `${MY_MEMBER_NAME} updated member information for ${MEMBER_NAME}`,
  UpdateAccount:
    `${MY_MEMBER_NAME} updated investor account information for ${INVESTOR_NAME}`,
  CreateProject:
    `${MY_MEMBER_NAME} established the "${PROJECT_NAME}" ${PROJECT_TYPE} project, scheduled to go live on ${PROJECT_OPEN_DATE}`,
  UpdateProject:
    `${MY_MEMBER_NAME} updated the information for the "${PROJECT_NAME}" project`,
  UpdateProjectBankAccount:
    `${MY_MEMBER_NAME} updated the bank account information for the "${PROJECT_NAME}" project`,
  DeleteProjectBankAccount:
    `${MY_MEMBER_NAME} disconnected the bank account from the "${PROJECT_NAME}" project`,
  CreatePartnerOrganization:
    `${MY_MEMBER_NAME} registered the partner organization "${PARTNER_ORGANIZATION_NAME}"`,
  UpdateMyInvestment:
    `Investor ${MY_INVESTOR_NAME} updated the details of ${INVESTMENT_PROFILE_TYPE} investment in the "${PROJECT_NAME}" project`,
  UpdateMyAccount:
    `Investor ${MY_INVESTOR_NAME} updated account information`,
  UpdateMyImmutableAccountInvestor:
    `Investor ${MY_INVESTOR_NAME} updated account information`,
  CreateCommitment:
    `${COMMITMENT_NAME} made a commitment to invest ${COMMITMENT_AMOUNT} in the "${PROJECT_NAME}" project`,
  PublishProject:
    `${MY_MEMBER_NAME} opened project "${PROJECT_NAME}" for investments`,
  UpdateMyInvestmentFundingStatus:
    `Investor ${MY_INVESTOR_NAME} indicated that the ${INVESTMENT_PROFILE_TYPE} investment in the "${PROJECT_NAME}" project has been funded`,
  RejectInvestment:
    `${MY_MEMBER_NAME} marked ${INVESTOR_NAME}'s ${INVESTMENT_PROFILE_TYPE} investment in the "${PROJECT_NAME}" project as a dropout`,
  UpdateMyInvestmentSources:
    `Investor ${MY_INVESTOR_NAME} revised ${SOURCE_TYPE} bank account for the ${INVESTMENT_PROFILE_TYPE} investment in the "${PROJECT_NAME}" project`,
  UpdateMyInvestmentSubscription:
    `Investor ${MY_INVESTOR_NAME} asked for a new subscription link to sign the ${INVESTMENT_PROFILE_TYPE} investment in the "${PROJECT_NAME}" project`,
  ConnectFundPartner:
    `${MY_MEMBER_NAME} linked ${FUND_NAME} as a partner with a target amount of ${PARTNER_TARGET_AMOUNT} in the "${PROJECT_NAME}" project`,
  EnableMyAccount2FA:
    `Investor ${MY_INVESTOR_NAME} activated 2FA for his account using the number ${MY_INVESTOR_MOBILE}`,
  DisableMyAccount2FA:
    `Investor ${MY_INVESTOR_NAME} turned off 2FA for his account`,
  UpdateMyOrganizationEmailVerificationStatus:
    `The email verification status for the organization has been updated`,
  // TO BE UPDATED:
  DeleteUser: "Staff deleted",
  EnableUser: "Staff enabled",
  DisableUser: "Staff disabled",
  CreateMember: "Member added",
  CreateFundMember: "Fund member added",
  UpdateFundMember: "Fund member updated",
  CreateBroker: "Liaison added",
  UpdateBroker: "Liaison updated",
  UpdateMyBrokerSources: "Liaison funding sources updated",
  CreateProperty: "Property added",
  UpdateProperty: "Property updated",
  DeleteProperty: "Property deleted",
  CreateBatch: "Documents batch added",
  UpdateBatch: "Documents batch updated",
  DeleteBatch: "Documents batch deleted",
  CreateFile: "Document uploaded",
  UpdateFile: "Document updated",
  DeleteFile: "Document deleted",
  UpdateProjectSources: "Project funding sources updated",
  UpdatePartnerProject: "Partner project updated",
  UnpublishProject: "Project unpublished",
  CloseProject: "Project closed for investments",
  ArchiveProject: "Project archived",
  DeleteProject: "Project deleted",
  CreateSponsor: "Sponsor added",
  UpdateSponsor: "Sponsor updated",
  DeleteSponsor: "Sponsor deleted",
  ConnectSponsorPartner: "Partner sponsor connected",
  UpdateFundPartner: "Partner fund updated",
  UpdateSponsorPartner: "Partner sponsor updated",
  DisconnectPartner: "Partner disconnected",
  AcceptTermsOfService: "Terms of services accepted",
  DisableAccount: "Account disabled",
  EnableAccount: "Account enabled",
  PostponeCommitment: "Commitment postponed",
  DisableCommitmentReminder: "Commitment reminder disabled",
  UpdateInvestmentNote: "Investment note updated",
  DeleteMyInvestment: "Investment deleted",
  UpdateMyInvestmentSignUrl: "Investment sign URL updated",
  BackupInvestment: "Investment backed up",
  AcceptInvestment: "Investment accepted",
  CreateCustodian: "Custodian created",
  UpdateCustodian: "Custodian updated",
  DeleteCustodian: "Custodian deleted",
  UpdateCustodianSources: "Custodian sources updated",
  CreateUnverifiedPersonalCustomer: "Unverified personal customer created",
  UpgradeMyUnverifiedPersonalCustomer: "Unverified personal customer upgraded",
  CreateUnverifiedBusinessCustomer: "Unverified business customer created",
  UpgradeMyUnverifiedBusinessCustomer: "Unverified business customer upgraded",
  CreateReceiveOnlyBusinessCustomer: "Receive only business customer created",
  UpdateMyCustomerVerificationStatus: "Customer verification status updated",
  ConnectSource: "Funding source connected",
  ConnectCustomSource: "Custom funding source connected",
  DeleteSource: "Funding source deleted",
  VerifyMyCustomSource: "Custom funding source verified",
  UpdateMySourceVerificationStatus: "Funding source verification status updated",
  RegisterExternalFundingTransaction: "External funding transaction registered",
  DeleteExternalFundingTransaction: "External funding transaction deleted",
  CreateDistributionTransaction: "Distribution transaction created",
  CreateSponsorPromoteTransaction: "Sponsor promote transaction created",
  DeleteTransaction: "Transaction deleted",
  DeleteExternalDistributionTransaction: "Backfill transaction deleted",
  RejectTransaction: "Transaction rejected",
  UpdateRejectedTransactionAmount: "Updated amount for rejected transaction",
  ApproveTransaction: "Transaction approved",
  RetryDistributionTransaction: "Distribution transaction retried",
  CreateOrganization: "Organization created",
  UpdateMyOrganization: "Organization updated",
  UpdateMyOrganizationSources: "Organization funding sources updated",
  UpdateMyOrganizationDomainStatus: "Organization domain status updated",
  EnableAccount2FA: "Account 2FA enabled",
  DisableAccount2FA: "Account 2FA disabled",
  ConnectFundProjectProperty: "ConnectFundProjectProperty",
  UpdateMyOrganizationLegalName: "Organizations legal name updated",
  CreateBankAccount: "Bank account connected",
  RegisterExternalDistributionTransaction: "External distribution transaction registered",
  EnableOrganization: "Organization enabled",
  DisableOrganization: "Organization disabled",
  UpdateOrganization: "Organization updated",
}

export default TEMPLATES
