import React from "react"
import Highlighter from "react-highlight-words"

const HIGHLIGHT_STYLE = { backgroundColor: "#ffc069", padding: 0 }


const computeGetGlobalSearchProps = searchText => {
  const searchWords = searchText.split(' ')

  const highlightText = text =>
    <Highlighter
      className="highlighter-container"
      autoEscape
      searchWords={searchWords}
      highlightStyle={HIGHLIGHT_STYLE}
      textToHighlight={text}
    />

  const getProps = (key, compute, customRender = value => value) => {
    const highlightedRender = (_, item) => {
      const computedValue = compute(item)

      if (!computedValue) {
        return customRender(computedValue, item)
      }

      const isValues = Array.isArray(computedValue)

      if (!isValues) {
        const highlightedValue = highlightText(computedValue)
        return customRender(highlightedValue, item)
      }

      const computedValues = computedValue
      const highlightedValues = computedValues.map(value => highlightText(value))

      return customRender(highlightedValues, item)
    }

    return {
      render: highlightedRender,
    }
  }

  return getProps
}

export default computeGetGlobalSearchProps
