import Page from "./Page"
import Logo from "./Logo"
import Slider from "./Slider"
import Footer from "./Footer"
import PageMenu from "./PageMenu"
import ContactUs from "./ContactUs"
import ScrollToTop from "./ScrollToTop"
import ReleaseAlert from "./ReleaseAlert"
import IndexRedirect from "./IndexRedirect"
import PlatformFooter from "./PlatformFooter"
import InvestmentHeader from "./InvestmentHeader"
import UnderstandConfirmModal from "./UnderstandConfirmModal"

import Modal from "./Modal/Modal"
import ImportModal from "./Modal/ImportModal"
import UpdateModal from "./Modal/UpdateModal"
import CreateModal from "./Modal/CreateModal"
import SearchInput from "./SearchInput"
import BeforeUnload from "./Modal/BeforeUnload"
import BlockingModal from "./Modal/BlockingModal"

import Drawer from "./Drawer/Drawer"
import DrawerTabs from "./Drawer/DrawerTabs"
import CreateDrawer from "./Drawer/CreateDrawer"
import UpdateDrawer from "./Drawer/UpdateDrawer"

import confirmAction from "./helpers/confirmAction"
import useLayoutTopOffset from "./helpers/useLayoutTopOffset"

import ResourceNotFoundRedirect from "./ResourceNotFoundRedirect"

export {
  Page,
  Logo,
  Slider,
  Footer,
  PageMenu,
  ContactUs,
  ScrollToTop,
  ReleaseAlert,
  IndexRedirect,
  PlatformFooter,
  InvestmentHeader,

  Modal,
  ImportModal,
  UpdateModal,
  CreateModal,
  SearchInput,
  BeforeUnload,
  BlockingModal,
  UnderstandConfirmModal,

  Drawer,
  DrawerTabs,
  CreateDrawer,
  UpdateDrawer,

  confirmAction,
  useLayoutTopOffset,

  ResourceNotFoundRedirect
}

export default Page
