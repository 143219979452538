import React, { useRef } from "react"
import PropTypes from "prop-types"
import { Collapse, Typography } from "antd"

import { W0, W1 } from "@components"
import { getConfig } from "@components/Config"
import {
  DWOLLA,
  FUNDING,
  BACKSTAGE,
  INVESTMENT,
  PROJECT_DROPOUTS,
  PROJECT_DOCUMENTS,
} from "@api/services/notifications/shapes/TemplateCategoryEnum"

import TemplatesList from "./TemplatesList"
import TemplateDrawer from "./TemplateDrawer"
import TemplatesStoreShape from "./shapes/TemplatesStoreShape"

const isCustomDwollaTemplatesEnabled = getConfig('isCustomDwollaTemplatesEnabled')

const LABEL_TITLE = "Notification Preferences"
const LABEL_TITLES_MAP = {
  [DWOLLA]: "Distributions",
  [FUNDING]: "Funding",
  [BACKSTAGE]: "Backstage",
  [INVESTMENT]: "Investments",
  [PROJECT_DROPOUTS]: "Project Dropouts",
  [PROJECT_DOCUMENTS]: "Asset Management",
}

const ORGANIZATION_TEMPLATES = isCustomDwollaTemplatesEnabled
  ? [ DWOLLA, BACKSTAGE ]
  : [ BACKSTAGE ]

const PROJECT_TEMPLATES = [
  INVESTMENT,
  FUNDING,
  PROJECT_DROPOUTS,
  PROJECT_DOCUMENTS,
]


const Templates = ({
  projectId = undefined,
  templatesStore,
}) => {
  const templateDrawer = useRef({})

  const {
    getTemplates,
    resetTemplate,
    toggleTemplate,
    getTemplateByKey,
    updatedTemplateByKey
  } = templatesStore

  const openTemplateDrawer = template =>
    templateDrawer.current.open(template)

  const templates = projectId
    ? PROJECT_TEMPLATES
    : ORGANIZATION_TEMPLATES

  const items = templates.map(categoryId => ({
    key: categoryId,
    label: LABEL_TITLES_MAP[categoryId],
    children: (
      <TemplatesList
        templates={getTemplates(categoryId)}
        toggleTemplate={toggleTemplate}
        openUpdateDrawer={openTemplateDrawer}
      />
    )
  }))

  return (
    <>
      <Typography.Title level={5} style={{ marginTop: W0, marginBottom: W1 }}>
        {LABEL_TITLE}
      </Typography.Title>

      <Collapse
        size="small"
        items={items}
        bordered={false}
        defaultActiveKey={[]}
      />

      <TemplateDrawer
        drawer={templateDrawer}
        projectId={projectId}
        resetTemplate={resetTemplate}
        getTemplateByKey={getTemplateByKey}
        updatedTemplateByKey={updatedTemplateByKey}
      />
    </>
  )
}

Templates.propTypes = {
  projectId: PropTypes.string,
  templatesStore: TemplatesStoreShape.isRequired,
}

export default Templates
