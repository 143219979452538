import { TYPE_RADIO } from "@components/Form"

import {
  INVESTMENT_PROFILE_TYPE_ENTITY,
  ENTITY_MEMBERSHIP_INTEREST_TRUSTEE,
  ENTITY_MEMBERSHIP_INTEREST_OTHER,
  ENTITY_MEMBERSHIP_INTERESTS_TITLES_MAP,
} from "@components/Domain"

import computeProfileInput from "./computeProfileInput"
import computeLiabilityInputs from "./computeLiabilityInputs"
import getProfileEntityTrusteeSchema from "./getProfileEntityTrusteeSchema"
import getProfileEntityInputsMap from "./getProfileEntityInputsMap"
import getProfileEntityOtherSchema from "./getProfileEntityOtherSchema"


const getProfileEntitySchema = (organization, updateSchema, isEdit, profile = {}, existingProfiles = []) => {
  const {
    membershipInterestsTitle,
    profileName
  } = profile

  const inputPropsMap = getProfileEntityInputsMap()

  const isTrustee = membershipInterestsTitle === ENTITY_MEMBERSHIP_INTEREST_TRUSTEE
  const isEntity = membershipInterestsTitle === ENTITY_MEMBERSHIP_INTEREST_OTHER

  const profileExtra = (selectedProfile = {}) => {
    let shouldProvideEin

    if (selectedProfile.einNumber) {
      shouldProvideEin = true
    }

    if (selectedProfile.ssnNumber) {
      shouldProvideEin = false
    }

    return { _shouldProvideEin: shouldProvideEin }
  }

  const liabilityInputs = computeLiabilityInputs(updateSchema, organization)
  const profileSelectInput = computeProfileInput(INVESTMENT_PROFILE_TYPE_ENTITY, updateSchema, existingProfiles, profileExtra)
  const hasProfiles = existingProfiles.filter(({ disabled }) => !disabled).length > 0

  const hiddenAllFields = Object.values(inputPropsMap)
  const shouldHideAnotherFields = hasProfiles && !profileName && !isEdit

  if (shouldHideAnotherFields) {
    return [
      ...profileSelectInput,
      ...hiddenAllFields
    ]
  }

  const commonInputs = [
    ...profileSelectInput,
    ...liabilityInputs,
    {
      name: "membershipInterestsTitle",
      type: TYPE_RADIO,
      label: "Title of membership interests",
      required: true,
      inputProps: {
        vertical: "true",
        onChange: () => updateSchema()
      },
      options: Object.keys(ENTITY_MEMBERSHIP_INTERESTS_TITLES_MAP)
        .map(value => ({ value, label: ENTITY_MEMBERSHIP_INTERESTS_TITLES_MAP[value] }))
    },
  ]

  let inputs = [
    ...commonInputs,
    ...[
      inputPropsMap.name,
      inputPropsMap.entityType,
      inputPropsMap.title,
      inputPropsMap.address,
      inputPropsMap.isDisregardedForTaxPurposes,
      inputPropsMap.taxFilingStatus,
      inputPropsMap.hasEinNumber,
      inputPropsMap.einNumber,
      inputPropsMap.ssnNumber,
      inputPropsMap.isRetirementPlan,
      inputPropsMap.beneficialOwnerName,
      inputPropsMap.beneficialOwnerAddress,
      inputPropsMap.beneficialOwnerEinNumber,
      inputPropsMap.beneficialOwnerSsnNumber,
      inputPropsMap.beneficialOwnerEntityType,
      inputPropsMap.beneficialOwnerTaxFilingStatus,
      inputPropsMap.beneficialOwnerCustodianEinNumber,
      inputPropsMap.isUsPerson,
      inputPropsMap.isUsCitizen,
      inputPropsMap.hasOtherUsResidenceState,
      inputPropsMap.residenceState,
      inputPropsMap.hasSsn,
    ]
  ]

  if (isTrustee) {
    const trusteeInputs = getProfileEntityTrusteeSchema(organization, updateSchema, profile)

    inputs = [
      ...commonInputs,
      ...trusteeInputs,
      ...[
        inputPropsMap.entityType,
        inputPropsMap.title,
        inputPropsMap.taxFilingStatus,
        inputPropsMap.ssnNumber,
        inputPropsMap.isRetirementPlan,
      ]
    ]
  }

  if (isEntity) {
    const entityInputs = getProfileEntityOtherSchema(organization, updateSchema, profile)

    inputs = [
      ...commonInputs,
      ...entityInputs,
      ...[
        inputPropsMap.ssnNumber,
      ]
    ]
  }

  return inputs
}

export default getProfileEntitySchema
