import { getCurrencyValue } from "@components/Amount"


export const getInvestmentAmount = (context, createdBy, parameters) => {
  const { mutation } = parameters
  const { investments = [] } = mutation

  let total = 0
  total += investments.map(({ amount }) => amount)

  return getCurrencyValue(total)
}
