import React, { useMemo } from "react"
import { Descriptions } from "antd"

import Identity from "@components/Identity"
import { Value } from "@components/Text"
import { IndexActivitiesOutputShape } from "@api/services/activities"


const ActivitySummaryDescriptions = ({
  activity = undefined
}) => {
  const items = useMemo(() =>
    !activity
      ? []
      : [
          {
            key: 'operationId',
            label: 'Operation',
            children: activity.operationId,
          },
          ...(activity.investorAccountId
            ? [
                {
                  key: 'investorAccountId',
                  label: 'Investor',
                  children: <Identity id={activity.investorAccountId} />,
                }
              ]
            : []
          ),
          {
            key: 'createdAt',
            label: 'Created At',
            children: <Value value={activity.createdAt} />,
          },
          {
            key: 'userId',
            label: 'Created By',
            children: <Identity id={activity.userId} />
          },
        ]
  , [ activity ])

  return (
    <Descriptions
      size="small"
      items={items}
      column={1}
      bordered={true}
    />
  )
}

ActivitySummaryDescriptions.propTypes = {
  activity: IndexActivitiesOutputShape,
}

export default ActivitySummaryDescriptions
