import React, { useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Space, Row, Col, Tag, Typography } from "antd"

import { W1 } from "@components"
import { Drawer } from "@components/Page"

import ActivitySourceDescriptions from "./ActivitySourceDescriptions"
import ActivitySummaryDescriptions from "./ActivitySummaryDescriptions"

const LABEL_ACTIVITY = "Activity"


const ActivityDrawer = ({ drawer, renderActivity }) => {
  const [ activity, setActivity ] = useState()

  const title = useMemo(() => (
    <Row>
      <Col flex="auto">
        <Typography.Title level={5} style={{ margin: 0 }}>
          {LABEL_ACTIVITY}
        </Typography.Title>
      </Col>
      <Col>
        <Tag bordered={false}>
          {activity?.id}
        </Tag>
      </Col>
    </Row>
  ), [ activity ])

  return (
    <Drawer
      title={title}
      drawer={drawer}
      onOpen={setActivity}
      destroyOnClose={true}
    >
      <Space
        size={W1}
        style={{ width: "100%" }}
        direction="vertical"
      >
        <ActivitySummaryDescriptions
          activity={activity}
        />

        <ActivitySourceDescriptions
          activity={activity}
          renderActivity={renderActivity}
        />
      </Space>
    </Drawer>
  )
}

ActivityDrawer.propTypes = {
  drawer: PropTypes.shape().isRequired,
  renderActivity: PropTypes.func.isRequired,
}

export default ActivityDrawer
