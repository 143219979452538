import { isLocal } from "@components"
import { getConfig } from "@components/Config"

const ENVIRONMENT_LABEL = getConfig("ENVIRONMENT_LABEL")


const setTitle = title => {
  let prefix = ""

  if (ENVIRONMENT_LABEL) {
    prefix = isLocal
      ? `(local) `
      : `(${ENVIRONMENT_LABEL}) `
  }

  document.title = `${prefix}${title}`
}

export default setTitle
