import { PROJECT_TYPES } from "@components/Domain"
import { TYPE_STRING, TYPE_RADIO, TYPE_NONE, TYPE_DATE } from "@components/Form"


const getCreateSchema = projectType => {
  const isTypeFund = projectType === PROJECT_TYPES.FUND

  const inputs = [
    {
      name: "name",
      label: "Project Full Name",
      type: TYPE_STRING,
      placeholder: "",
    },
    {
      name: "shortName",
      type: TYPE_STRING,
      inputProps: {
        style: { width: 320 },
        count: {
          show: true,
          max: 32,
        }
      }
    },
    {
      name: "type",
      type: TYPE_RADIO,
    },
    {
      name: "openDate",
      label: "Go Live Date",
      required: true,
      type: isTypeFund ? TYPE_NONE : TYPE_DATE,
    },
    {
      name: "closeDate",
      label: "Funding Date",
      type: isTypeFund ? TYPE_NONE : TYPE_DATE,
    },
  ]

  return inputs
}

export default getCreateSchema
