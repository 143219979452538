import React from "react"

import { W1 } from "@components"
import {
  TYPE_NONE,
  TYPE_ARRAY,
  TYPE_RADIO,
  TYPE_HIDDEN
} from "@components/Form"
import { MarkdownAlert } from "@components/Text"
import {
  INVESTMENT_PROFILE_TYPE_JOINT,
  INVESTMENT_PROFILE_TYPE_SDIRA,
  INVESTMENT_PROFILE_TYPE_ENTITY,
  INVESTMENT_PROFILE_TYPE_INDIVIDUAL
} from "@components/Domain"
import { updateMyInvestmentOperation } from "@api/services/investments"

import InvestmentsInput from "../InvestmentsInput"
import getProfileJointSchema from "./getProfileJointSchema"
import getProfileSdiraSchema from "./getProfileSdiraSchema"
import getProfileEntitySchema from "./getProfileEntitySchema"
import renderProfileTypeOptions from "./renderProfileTypeOptions"
import getProfileIndividualSchema from "./getProfileIndividualSchema"


const getInvestmentSchema = ({
  investment = {},
  otherInvestments = [],
  getInvestorSsnNumber = () => {},
  tiers,
  sponsorId,
  profilesMap,
  organization,
  updateSchema,
  investorAccountId,
  isTypeSelectDisabled,
  profileTypeExtraWarning,
}) => {
  const isIndividualDisabled = otherInvestments
    .filter(({ profileType }) => profileType === INVESTMENT_PROFILE_TYPE_INDIVIDUAL)
    .length > 0

  const inputs = [
    {
      name: "profileType",
      type: TYPE_RADIO,
      label: "How do you like to invest?",
      options: renderProfileTypeOptions(isIndividualDisabled),
      inputProps: {
        disabled: isTypeSelectDisabled,
        onChange: () => {
          updateSchema()
        }
      },
      itemProps: {
        extra: (
          profileTypeExtraWarning
            ? (
                <MarkdownAlert
                  text={profileTypeExtraWarning}
                  type="warning"
                  style={{ marginTop: W1, marginBottom: 0 }}
                />
              )
            : null
        )
      }
    },
    {
      name: "profileJoint",
      type: TYPE_NONE
    },
    {
      name: "profileSdira",
      type: TYPE_NONE
    },
    {
      name: "profileEntity",
      type: TYPE_NONE
    },
    {
      name: "profileIndividual",
      type: TYPE_NONE,
    },
    {
      name: "investments",
      type: TYPE_ARRAY,
      component: InvestmentsInput,
      componentProps: {
        tiers,
        itemProps: { style: { marginBottom: 0 } }
      }
    },
    {
      name: "receiverSourceId",
      type: TYPE_NONE,
    },
    {
      name: "receiverSourceName",
      type: TYPE_NONE,
    },
    {
      name: "isProfileValid",
      type: TYPE_NONE,
    },
    {
      name: "isImmutable",
      type: TYPE_NONE,
    }
  ]

  if (sponsorId) {
    inputs.push({
      name: "sponsorId",
      type: TYPE_HIDDEN,
      initialValue: sponsorId,
    })
  }

  if (investorAccountId) {
    inputs.push({
      name: "investorAccountId",
      type: TYPE_HIDDEN,
      initialValue: investorAccountId,
    })
  }

  const { profileType } = investment

  const isJoint = profileType === INVESTMENT_PROFILE_TYPE_JOINT
  const isSdira = profileType === INVESTMENT_PROFILE_TYPE_SDIRA
  const isEntity = profileType === INVESTMENT_PROFILE_TYPE_ENTITY
  const isIndividual = profileType === INVESTMENT_PROFILE_TYPE_INDIVIDUAL

  if (isJoint) {
    const { id, profileJoint = {}, investor } = investment
    const existingJointProfiles = profilesMap[INVESTMENT_PROFILE_TYPE_JOINT]
    const isEdit = !!id

    inputs[1].label = ""
    inputs[1].schema = getProfileJointSchema(organization, updateSchema, getInvestorSsnNumber, isEdit, profileJoint, investor, existingJointProfiles)
    delete inputs[1].type
  }

  if (isSdira) {
    const { profileSdira = {}, id } = investment
    const existingSdiraProfiles = profilesMap[INVESTMENT_PROFILE_TYPE_SDIRA]
    const isEdit = !!id

    inputs[2].label = ""
    inputs[2].schema = getProfileSdiraSchema(organization, updateSchema, isEdit, profileSdira, existingSdiraProfiles)
    delete inputs[2].type
  }

  if (isEntity) {
    const { id, profileEntity = {} } = investment
    const existingEntityProfiles = profilesMap[INVESTMENT_PROFILE_TYPE_ENTITY]
    const isEdit = !!id

    inputs[3].label = ""
    inputs[3].schema = getProfileEntitySchema(organization, updateSchema, isEdit, profileEntity, existingEntityProfiles, )
    delete inputs[3].type
  }

  if (isIndividual) {
    let { profileIndividual = {} } = investment
    const existingEntityProfiles = profilesMap[INVESTMENT_PROFILE_TYPE_INDIVIDUAL]
    const hasProfileIndividual = Object.values(profileIndividual).length > 0
    const hasExistingEntityProfiles = existingEntityProfiles.length > 0

    if (!hasProfileIndividual && hasExistingEntityProfiles) {
      profileIndividual = existingEntityProfiles[0].profileIndividual
    }

    inputs[4].label = ""
    inputs[4].schema = getProfileIndividualSchema(organization, updateSchema, getInvestorSsnNumber, profileIndividual)

    delete inputs[4].type
  }

  if (!profileType) {
    inputs[5].type = TYPE_NONE
  }

  const schema = updateMyInvestmentOperation.getSchema(inputs)

  return schema
}

export default getInvestmentSchema
