import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { Space, Spin } from "antd"

import { Drawer } from "@components/Page"
import { useAppContext } from "@components/AppContext"
import { readTransactionOperation } from "@api/services/transactions"

import Transaction from "./Transaction"
import TransactionRetry from "./TransactionRetry"
import TransactionDelete from "./TransactionDelete"
import TransactionUpdateDrawer from "./TransactionUpdateDrawer"

const LABEL_TITLE = "Transaction"
const TRANSACTION_REJECTED_STATUS = "REJECTED"


const TransactionDrawer = ({
  drawer,
  indexTransactions
}) => {
  const transactionUpdateDrawer = useRef({})

  const [ transaction, setTransaction ] = useState()

  const { request } = useAppContext()

  const onClose = () =>
    setTransaction()

  const onOpen = ({ id }) =>
    request(readTransactionOperation, { id })
      .then(({ data }) => setTransaction(data))

  const isEditable = transaction?.status === TRANSACTION_REJECTED_STATUS

  const onEdit = isEditable
    ? () => transactionUpdateDrawer.current.open()
    : undefined

  const onAfterUpdate = async () => {
    await indexTransactions(false)
    drawer.current.close()
  }

  const onAfterDelete = () =>
    indexTransactions(false)

  const onAfterRetry = updatedTransaction => {
    setTransaction(updatedTransaction)
    return indexTransactions(false)
  }

  const extra = transaction && (
    <Space>
      <TransactionRetry
        transaction={transaction}
        onAfterRetry={onAfterRetry}
      />

      <TransactionDelete
        drawer={drawer}
        transaction={transaction}
        onAfterDelete={onAfterDelete}
      />
    </Space>
  )

  const drawerProps = {
    title: LABEL_TITLE,
    extra,
    drawer,
    onOpen,
    onClose,
  }

  const children = !transaction
    ? <center><Spin /></center>
    : (
        <>
          <Transaction
            onEdit={onEdit}
            transaction={transaction}
          />

          <TransactionUpdateDrawer
            drawer={transactionUpdateDrawer}
            onSuccess={onAfterUpdate}
            transaction={transaction}
          />
        </>
      )

  return (
    <Drawer {...drawerProps}>
      {children}
    </Drawer>
  )
}

TransactionDrawer.propTypes = {
  drawer: PropTypes.shape().isRequired,
  indexTransactions: PropTypes.func.isRequired,
}

export default TransactionDrawer
