import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { useOutletContext } from "react-router-dom"

import { hasAccess } from "@components/Authorization"
import { useForm } from "@components/Form"
import { UpdateModal } from "@components/Page"
import { ProjectShape } from "@components/Project"
import { PROJECT_STATE_OPEN } from "@components/Domain"
import { onProjectSubmit, extendProjectTags } from "@components/Project/ProjectTags"
import {
  updateProjectOperation,
  updatePartnerProjectOperation
} from "@api/services/backstage"

import getUpdateInputs from "./helpers/getUpdateInputs"

const LABEL_SUBMIT = "Save"
const LABEL_ENTITY = "Project"
const LABEL_CREATE = `Edit ${LABEL_ENTITY}`
const LABEL_TEMPLATE_MISSING_MESSAGE_ALERT = `
 You have selected the PPM template for the project. Once you click "Confirm"
 below, an email notification will automatically be sent to all investors,
 informing them that the subscription documents are ready to be signed.
`

const ProjectUpdateModal = ({
  modal,
  project
}) => {
  const updateModal = useRef({})

  const form = useForm()

  const [ width, setWidth ] = useState()
  const [ schema, setSchema ] = useState([])

  const {
    projects,
    bankAccounts,
    updateProject,
    indexInvestments,
    hasMultipleSponsors,
    isVerifiedBankAccount,
  } = useOutletContext()

  const {
    state: projectState,
    ppmTemplateId: projectPpmTemplateId
  } = project

  const isOpenProject = projectState === PROJECT_STATE_OPEN

  const onSubmit = parameters =>
    onProjectSubmit(parameters, project, hasMultipleSponsors)

  const onSuccess = updatedProject => {
    updateProject({
      bankAccountId: "",
      distributionBankAccountId: "",
      ...updatedProject
    })

    return indexInvestments()
  }

  const updateSchema = (onlyFieldNames) => {
    const { ppmTemplateId: formPpmTemplateId } = form.getFieldsValue()
    const { bankAccountId } = form.getFieldsValue()

    const isPpmChanged = formPpmTemplateId !== projectPpmTemplateId

    const hasFundingBankAccount = !!bankAccountId

    const isAchFundingInputDisabled =
      !hasFundingBankAccount ||
      !isVerifiedBankAccount(bankAccountId)

    const isPreviousTemplateNotSet =
      !projectPpmTemplateId ||
      projectPpmTemplateId.length === 0

    const hasTemplateChangedAlert =
      isOpenProject &&
      isPpmChanged &&
      isPreviousTemplateNotSet

    const hasResetSignedInvestmentsInput =
      isOpenProject &&
      isPpmChanged &&
      !isPreviousTemplateNotSet

    const updateInputs = getUpdateInputs({
      templateAlertMessage: LABEL_TEMPLATE_MISSING_MESSAGE_ALERT,
      projects,
      bankAccounts,
      updateSchema,
      onlyFieldNames,
      hasFundingBankAccount,
      hasTemplateChangedAlert,
      isAchFundingInputDisabled,
      hasResetSignedInvestmentsInput,
    })

    const newSchema = updateProjectOperation.getSchema(updateInputs)

    setSchema(newSchema)
  }

  modal.current.open = (onlyFieldNames, modalWidth = undefined) => {
    const { bankAccountId } = project

    const hasFundingBankAccount = !!bankAccountId

    const isAchFundingInputDisabled =
      !hasFundingBankAccount ||
      !isVerifiedBankAccount(bankAccountId)

    const updateInputs = getUpdateInputs({
      projects,
      bankAccounts,
      updateSchema,
      onlyFieldNames,
      hasFundingBankAccount,
      isAchFundingInputDisabled
    })

    const newSchema = updateProjectOperation.getSchema(updateInputs)

    setSchema(newSchema)
    setWidth(modalWidth)

    const extendedProject = extendProjectTags(project)
    updateModal.current.openItem(extendedProject)
  }

  const isPartnerProject = hasAccess(['fund-projects-write'])

  const operation = isPartnerProject
    ? updatePartnerProjectOperation
    : updateProjectOperation

  const updateModalProps = {
    modal: updateModal,
    title: LABEL_CREATE,
    entity: LABEL_ENTITY,
    submitLabel: LABEL_SUBMIT,
    form,
    width,
    schema,
    onSubmit,
    onSuccess,
    operation,
  }

  return (
    <UpdateModal {...updateModalProps} />
  )
}

ProjectUpdateModal.propTypes = {
  modal: PropTypes.shape().isRequired,
  project: ProjectShape.isRequired,
}

export default ProjectUpdateModal
