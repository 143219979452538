import React from "react"
import { Button, Typography, Space, Badge } from "antd"
import { Link } from "react-router-dom"
import {
  TeamOutlined,
  PlusOutlined,
  FolderOpenOutlined,
  DollarOutlined,
  HistoryOutlined,
  NotificationOutlined
} from "@ant-design/icons"

import { hasAccess } from "@components/Authorization"

import getActiveProjects from "./getActiveProjects"
import renderProjectLabel from "./renderProjectLabel"
import getProjectPagePath from "./getProjectPagePath"

const { Text } = Typography

const LABEL_LOADING = "Loading..."
const LABEL_ACTIVITY = "Activity"
const LABEL_ACCOUNTS = "Investors"
const LABEL_SUBJECTS = "Properties"
const LABEL_PROJECTS = "Projects"
const LABEL_CAMPAIGNS = "Campaigns"
const LABEL_TRANSACTIONS = "Transactions"


const getMainMenu = (projects, openProjectCreateModal, failedTransactionsCount, hasCampaigns) => {
  const isLoadingProjects = projects === null

  const activeProjects = getActiveProjects(projects)

  const projectsItems = [
    ...activeProjects.map(({ id, name, shortName, state, type, status }) =>
      ({
        key: `/backstage/project/${id}/${getProjectPagePath(status)}`,
        label: (
          <Link to={`/backstage/project/${id}/${getProjectPagePath(status)}`}>
            {renderProjectLabel(name, shortName, state, type)}
          </Link>
        ),
      })
    )
  ]

  const canCreateProject = hasAccess(['projects-write'])
  const canShowActivities = hasAccess(['activities-read'])
  const canReadProperties = hasAccess(['properties-read'])

  const hasFailedTransactions = failedTransactionsCount > 0

  const items = [
    {
      key: "/backstage/investors",
      icon: <TeamOutlined />,
      label: <Link to="/backstage/investors">{LABEL_ACCOUNTS}</Link>,
    },
    ...(
      hasCampaigns ? [{
        key: "/backstage/campaigns",
        icon: <NotificationOutlined />,
        label: <Link to="/backstage/campaigns">{LABEL_CAMPAIGNS}</Link>,
      }] : []
    ),
    ...(
      canReadProperties ? [{
        key: "/backstage/properties",
        icon: <FolderOpenOutlined />,
        label: <Link to="/backstage/properties">{LABEL_SUBJECTS}</Link>
      }] : []
    ),
    {
      key: "/backstage/transactions",
      icon: <DollarOutlined />,
      label: (
        <Space>
          <Link to="/backstage/transactions">{LABEL_TRANSACTIONS}</Link>
          {
            hasFailedTransactions && (
              <Badge
                count={failedTransactionsCount}
              />
            )
          }
        </Space>
      ),
    },
    ...(
      canShowActivities ? [{
        key: "/backstage/activities",
        icon: <HistoryOutlined />,
        label: <Link to="/backstage/activities">{LABEL_ACTIVITY}</Link>,
      }] : []
    ),
    {
      type: 'divider',
    },
    {
      key: "projects",
      type: "group",
      label: isLoadingProjects ? LABEL_LOADING : (
        <div>
          {
            canCreateProject && (
              <Button
                size="small"
                type="text"
                icon={<PlusOutlined />}
                style={{ float: "right" }}
                onClick={openProjectCreateModal}
              />
            )
          }
          <Text strong style={{ marginLeft: 6 }}>{LABEL_PROJECTS}</Text>
        </div>
      ),
      children: projectsItems
    },
  ]

  const selectedKeys = [...items, ...projectsItems]
    .map(item => item.key)
    .filter(key => window.location.pathname.startsWith(`${key}`.replace('/details', '/')))

  return [ items, selectedKeys ]
}

export default getMainMenu
