import dayjs from "dayjs"

import { getUsDateFromIsoDate } from "@components/Date"


const getProjectDocumentLinks = ({ project, tagsMap }) => {
  const { documents = [] } = project

  const hasDocuments = documents.length > 0

  if (!hasDocuments) {
    return tagsMap.PROJECT_DOCUMENTS
  }

  const documentLinks = documents.map(({ url, description }) =>
      `<a href="${url}" target="_blank">${description}</a>`).join('\n')

  return `<ul style="padding-left: 20px; margin-top: 0px; margin-bottom: 16px;">${documentLinks}</ul>`
}

const getLastDayOfMonth = () => {
  const lastDayOfMonth = dayjs().endOf('month')

  return getUsDateFromIsoDate(lastDayOfMonth)
}

const getFirstDistributionDate = () => {
  const firstDistributionDate = dayjs()
    .startOf('month')
    .add(2, 'month')
    .add(4, 'day')

  return getUsDateFromIsoDate(firstDistributionDate)
}

const commonVariablesMap = {
  ORGANIZATION_NAME: ({ organization }) => organization.name,
  ORGANIZATION_EMAIL: ({ organization }) => organization.email,
  MONTH_LAST_DAY: getLastDayOfMonth,
  DISTRIBUTION_START_DATE: getFirstDistributionDate,
}

const projectVariablesMap = {
  SIGN_URL: ({ organization: { domain }, project: { id } }) => `https://${domain}/investments/${id}/sign`,
  SIGNUP_URL: ({ organization: { domain }, project: { id } }) => `https://${domain}/investments/${id}/`,
  PROJECT_NAME: ({ project: { name } }) => name,
  SIGN_DEADLINE: ({ project: { signDeadlineDate } }) => signDeadlineDate ? getUsDateFromIsoDate(signDeadlineDate) : '%SIGN_DEADLINE%',
  FUND_DEADLINE: ({ project: { fundDeadlineDate } }) => fundDeadlineDate ? getUsDateFromIsoDate(fundDeadlineDate) : '%FUND_DEADLINE%',
  PROJECT_DOCUMENTS: getProjectDocumentLinks,
  PROJECT_CLOSE_DATE: ({ project: { closeDate } }) => closeDate ? getUsDateFromIsoDate(closeDate) : '%PROJECT_CLOSE_DATE%',
  PROJECT_DOCUMENTS_URL: ({ organization: { domain }, project: { id } }) => `https://${domain}/projects/${id}/documents`,
}

const renderContent = (template = "", context = {}, isSubject = false, variables=[]) => {
  if (!template) {
    return
  }

  let result = template

  let variablesMap = {
    ...commonVariablesMap,
  }

  const { project, tagsMap } = context

  if (project) {
    variablesMap = { ...variablesMap, ...projectVariablesMap }
  }


  for (const variable of variables) {
    const [variableName, replacement] = variable.split(':')

    let value = `<span data-block="mergetag">${replacement}</span>`

    const valueResolver = variablesMap[variableName]

    if (valueResolver) {
      value = valueResolver(context)
    }

    const mergeTagRegex = new RegExp(`<span data-block="mergetag">${replacement}</span>`, 'g')
    result = result.replace(mergeTagRegex, value)
  }

  const tagKeys = Object.keys(tagsMap)

  for (const key of tagKeys) {
    const tag = tagsMap[key]
    const regex = new RegExp(`\\%${key}\\%`, 'g')

    result = isSubject
      ? result.replace(regex, `<span data-block="mergetag">${tag}</span>`)
      : result.replace(regex, tag)
  }

  return result
}

export default renderContent
